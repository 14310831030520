import { useEffect, useState } from "react";
import axios from "../../../services/ApiWithoutToken";
import { Box, Grid, IconButton } from "@mui/material";
import loader from "../../../assets/loader.gif";
import { useParams } from "react-router-dom";
import PrintIcon from "@mui/icons-material/Print";
import QualifiedContractNCT from "./QualifiedContractNCT";
import { GenerateNCTContractQualifiedPdf } from "../../../utils/generateNCTContractQualified";

function CandidateContractQualified() {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const { applicationNo } = useParams();

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setIsLoading(true)
        await axios
            .get(`/api/student/candidateResultStatus?application_no_npf=${applicationNo.replaceAll("-", "/")}`)
            .then((res) => {
                if (res.data.data?.length > 0) setData(res.data.data[0])
                setIsLoading(false)
            })
            .catch((err) => {
                console.error(err);
                setIsLoading(false)
            })
    }

    const openPDF = async () => {
        setIsLoading(true);
        try {
            if(data.result_status === "QUALIFIED"){
                GenerateNCTContractQualifiedPdf(data)
                updateContractDownloadStatus()
            }
        } catch (error) {
            console.error("PDF generation error:", error);
        } finally {
            setIsLoading(false);
        }
    }

    const updateContractDownloadStatus = () => {
        axios.put("/api/student/updateContractDownloadStatus", {
            "application_no_npf": applicationNo.replaceAll("-", "/"),
            "contract_download_status":true
            })
        .then(res => {})
        .catch(err => {})
    }

    if (isLoading)
        return <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "4px",
            }}
        >
            <img src={loader} alt="loading" />
            <p>Generating PDF...</p>
        </Box>

    if (Object.keys(data).length > 0 && !isLoading && data?.result_status === "QUALIFIED")
        return (
            <Box>
                <Grid container>
                    <Grid item xs={12} align="right">
                        <IconButton className="no-print" onClick={openPDF}>
                            <PrintIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} id="wrapper">
                        <QualifiedContractNCT data={data} />
                    </Grid>
                </Grid>
            </Box>
        )

    return <Box
        sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "4px",
            marginTop: "30px"
        }}
    >
        <h2>No Data Found!!!</h2>
    </Box>
}

export default CandidateContractQualified