import jsPDF from "jspdf";
import '../fonts/Roboto-Bold.js'
import '../fonts/Roboto-Light.js'
import '../fonts/Roboto-Regular.js'
import '../fonts/Roboto-Italic.js'
import moment from "moment";
import stamp from "../assets/stamp.png";
import sign from "../assets/sign.png";
import { Chapter1DataQualified, Chapter2_2_1Qualified, Chapter2_2_2Qualified, Chapter2_2_3Qualified, Chapter2_2_4Qualified, Chapter3DataQualified, Chapter4DataQualified, Chapter5DataQualified, Chapter6DataQualified, Chapter7DataQualified } from "./QualifiedContractData.js";

const LINE_SPACING = 13
const TEXT_WIDTH = 275
const EN_X_POS = 15
const UZ_X_POS = 305
const PAGE_WRAP_INITIAL_Y_POS = 30
const FONT_SIZE = 10

const isTextFitAtPageEnd = (doc, uzTextLines, enTextLines, YPos, lineSpacing) => {
    const pageHeight = doc.internal.pageSize.height - 30;
    let uzYPos = YPos
    let enYPos = YPos
    uzTextLines.forEach(line => uzYPos += lineSpacing)
    enTextLines.forEach(line => enYPos += lineSpacing)

    if ((uzYPos > pageHeight) || (enYPos > pageHeight)) return true
    return false
}

const getMaxYPosition = (num1, num2) => {
    if (num1 > num2) return num1
    return num2
}

const printJustifyiedText = ({ uzText, enText, textWidth, doc, fontSize = 10, fontType = 'Roboto-Regular', YPosition = 40, strike = false, textColor = "#000", uzXPosition = UZ_X_POS, enXPosition = EN_X_POS }) => {
    doc.setFont(fontType)
    doc.setFontSize(10)
    doc.setTextColor(textColor)

    let cursorYuz = YPosition
    let cursorXuz = uzXPosition
    let cursorYen = YPosition
    let cursorXen = enXPosition

    let uzTextLines = doc.splitTextToSize(uzText, textWidth);
    let enTextLines = doc.splitTextToSize(enText, textWidth);

    const isEnd = isTextFitAtPageEnd(doc, uzTextLines, enTextLines, YPosition, LINE_SPACING)
    if (isEnd) {
        doc.addPage();
        cursorYuz = PAGE_WRAP_INITIAL_Y_POS
        cursorYen = PAGE_WRAP_INITIAL_Y_POS
    }

    let uzTextObject = getTextRows(doc, uzText, textWidth);
    let enTextObject = getTextRows(doc, enText, textWidth);

    uzTextObject.forEach((row, rowPosition) => {
        let tempX = cursorXuz
        Object.entries(row.chars).map(([key, value]) => {
            doc.text(value.char, cursorXuz, cursorYuz);

            if (value.char === ' ' && rowPosition < uzTextObject.length - 1) {
                cursorXuz += row.blankSpacing;
            } else {
                cursorXuz += doc.getStringUnitWidth(value.char) * fontSize;
            }
        });
        if (strike) {
            doc.line(uzXPosition, (cursorYuz - 3), (uzXPosition + (cursorXuz - tempX)), (cursorYuz - 3))
        }
        cursorXuz = uzXPosition;
        cursorYuz += LINE_SPACING;
    });

    enTextObject.forEach((row, rowPosition) => {
        let tempX = cursorXen
        Object.entries(row.chars).map(([key, value]) => {
            doc.text(value.char, cursorXen, cursorYen);

            if (value.char === ' ' && rowPosition < enTextObject.length - 1) {
                cursorXen += row.blankSpacing;
            } else {
                cursorXen += doc.getStringUnitWidth(value.char) * fontSize;
            }
        });
        if (strike) {
            doc.line(enXPosition, (cursorYen - 3), (enXPosition + (cursorXen - tempX)), (cursorYen - 3))
        }
        cursorXen = enXPosition;
        cursorYen += LINE_SPACING;
    });

    return getMaxYPosition(cursorYuz, cursorYen) + 8
}

const printJustifyiedTextSingle = ({ text, textWidth, doc, fontSize = 10, fontType = 'Roboto-Regular', YPosition = 40, xPosition = 15, strike = false, textColor = "#000"}) => {
    doc.setFont(fontType)
    doc.setFontSize(10)
    doc.setTextColor(textColor)

    let cursorY = YPosition
    let cursorX = xPosition

    let textLines = doc.splitTextToSize(text, textWidth);

    const isEnd = isTextFitAtPageEnd(doc, textLines, [], YPosition, LINE_SPACING)
    if (isEnd) {
        doc.addPage();
        cursorY = PAGE_WRAP_INITIAL_Y_POS
    }

    let uzTextObject = getTextRows(doc, text, textWidth);

    uzTextObject.forEach((row, rowPosition) => {
        let tempX = cursorX
        Object.entries(row.chars).map(([key, value]) => {
            doc.text(value.char, cursorX, cursorY);

            if (value.char === ' ' && rowPosition < uzTextObject.length - 1) {
                cursorX += row.blankSpacing;
            } else {
                cursorX += doc.getStringUnitWidth(value.char) * fontSize;
            }
        });
        if (strike) {
            doc.line(xPosition, (cursorY - 3), (xPosition + (cursorX - tempX)), (cursorY - 3))
        }
        cursorX = xPosition;
        cursorY += LINE_SPACING;
    });

    return cursorY + 8
}

const getTextRows = (doc, inputValue, width) => {
    const regex = /(\*{2})+/g; // all "**" words
    const textWithoutBoldMarks = inputValue.replace(regex, '');
    const fontSize = doc.getFontSize();

    let splitTextWithoutBoldMarks = doc.splitTextToSize(
        textWithoutBoldMarks,
        width
    );

    let charsMapLength = 0;
    let position = 0;
    let isBold = false;

    // <><>><><>><>><><><><><>>><><<><><><><>
    // power algorithm to determine which char is bold
    let textRows = splitTextWithoutBoldMarks.map((row, i) => {
        const charsMap = row.split('');

        const chars = charsMap.map((char, j) => {
            position = charsMapLength + j + i;

            let currentChar = inputValue.charAt(position);

            if (currentChar === "*") {
                const spyNextChar = inputValue.charAt(position + 1);
                if (spyNextChar === "*") {
                    // double asterix marker exist on these position's so we toggle the bold state
                    isBold = !isBold;
                    currentChar = inputValue.charAt(position + 2);

                    // now we remove the markers, so loop jumps to the next real printable char
                    let removeMarks = inputValue.split('');
                    removeMarks.splice(position, 2);
                    inputValue = removeMarks.join('');
                }
            }

            return { char: currentChar, bold: isBold };
        });
        charsMapLength += charsMap.length;

        // Calculate the size of the white space to justify the text
        let charsWihoutsSpacing = Object.entries(chars).filter(([key, value]) => value.char !== ' ');
        let widthRow = 0;

        charsWihoutsSpacing.forEach(([key, value]) => {
            // Keep in mind that the calculations are affected if the letter is in bold or normal
            // doc.setFont(undefined, value.bold ? boldStr : normalStr);
            widthRow += doc.getStringUnitWidth(value.char) * fontSize;
        });

        let totalBlankSpaces = charsMap.length - charsWihoutsSpacing.length;
        let blankSpacing = (width - widthRow) / totalBlankSpaces;

        return { blankSpacing: blankSpacing, chars: { ...chars } };
    });

    return textRows;
}

const HeaderQualified = (doc, data) => {
    let maxYPos = 0
    const contract_code = data.application_no_npf
    const contract_date = moment().format("Do MMMM YYYY")
    const candidate_name = data.candidate_name
    const date_of_birth = data.date_of_birth ? moment(data.date_of_birth).format("DD, MM, YYYY").replaceAll(", ", "-") : ""

    doc.setFont('Roboto-Bold');
    doc.setFontSize(12)
    doc.text(`Contract No. ${contract_code}`, 150, 30, { align: "center" })
    doc.text(`Shartnoma № ${contract_code}`, 446, 30, { align: "center" })
    doc.text(`on the provision educational services`, 150, 45, { align: "center" })
    doc.text(`Ta’lim xizmatlarini ko’rsatish borasida`, 446, 45, { align: "center" })

    doc.setFont('Roboto-Italic', 'italic')
    doc.setFontSize(7)
    doc.text(`This Contract was concluded in ${contract_date} in Bukhara, Republic of`, EN_X_POS, 90)
    doc.text(`Uzbekistan.`, EN_X_POS, 90 + LINE_SPACING - 3)
    doc.text(`Mazkur shartnoma ${contract_date} sanasida O’zbekiston Respublikasi,`, UZ_X_POS, 90)
    doc.text(`Buxoro viloyatida tuzildi.`, UZ_X_POS, 90 + LINE_SPACING - 3)

    doc.setFont('Roboto-Regular', 'noraml')
    doc.setFontSize(11)
    const enText = `**FE “Acharya University LLC”,** a legal entity, registered on December 22, 2022, and acting in accordance with the legislation of the Republic of Uzbekistan (TIN: 310106691), located in Bukhara region, Karakul district, Khuzhalar MFY, Bukhara street, 60, represented by Bukhara region, Karakul district, Khujalar MFY, Bukhoro street., General Director Chandrashekar  Vishesh, acting on the basis of the Charter as the first party hereinafter referred to as University and first year student`
    const uzText = `22.12.2022 sanasida ro’yxatga olingan, Buxoro viloyati, Qorako’l tumani, Xo’jalar MFY, Buxoro ko’chasi 60-uyda joylashgan bo’lib, Ustav asosida faoliyat yurituvchi hamda O’zbekiston Respublikasi qonunchiligiga muvofiq faoliyat olib boradigan (INN:310106691) «Acharya University» MCHJ (keyingi o’rinlarda “Universitet”)  nomidan Bosh ijrochi direktor Vishesh Chandrashekar va 1-bosqich talabasi`

    maxYPos = printJustifyiedText({
        uzText: uzText,
        enText: enText,
        textWidth: TEXT_WIDTH,
        doc,
        fontSize: FONT_SIZE,
        fontType: 'Roboto-Regular',
        YPosition: 125
    })

    doc.text(`${candidate_name}`, 90, maxYPos + 20, { align: "center" })
    doc.text("(Student’s Name)", 90, maxYPos + 20 + LINE_SPACING, { align: "center" })
    doc.text(`${date_of_birth}`, 230, maxYPos + 20, { align: "center" })
    doc.text("(Date of birth)", 230, maxYPos + 20 + LINE_SPACING, { align: "center" })

    doc.text(`${candidate_name}`, 385, maxYPos + 20, { align: "center" })
    doc.text("(Talabaning F.I.SH)", 385, maxYPos + 20 + LINE_SPACING, { align: "center" })
    doc.text(`${date_of_birth}`, 522, maxYPos + 20, { align: "center" })
    doc.text("(Tug’ilgan sanasi)", 522, maxYPos + 20 + LINE_SPACING, { align: "center" })

    maxYPos += 60
    maxYPos = printJustifyiedText({
        uzText: "Keying o’rinlarda Talaba deb nomlanuvchi ikkinchi tomon birgalikda quyidagi mazmunda shartnomaga imzo chekishdi:",
        enText: "hereinafter referred to as the Student,  jointly referred to as the Parties, have concluded this Contract as follows:",
        textWidth: TEXT_WIDTH,
        doc,
        fontSize: FONT_SIZE,
        fontType: "Roboto-Regular",
        YPosition: maxYPos + 14,
    })

    return maxYPos
}

const Chapter1Qualified = (doc, yPos) => {
    doc.setFont('Roboto-Bold');
    doc.setFontSize(11)
    doc.text("1. SUBJECT OF THE CONTRACT", 150, yPos, { align: "center" })
    doc.text("1. SHARTNOMA MAVZUSI", 446, yPos, { align: "center" })

    let maxYPos = yPos + 20
    Chapter1DataQualified.forEach(obj => {
        maxYPos = printJustifyiedText({
            uzText: obj.uz,
            enText: obj.en,
            textWidth: TEXT_WIDTH,
            doc,
            fontSize: FONT_SIZE,
            fontType: "Roboto-Regular",
            YPosition: maxYPos,
        })
    })

    return maxYPos
}

const Chapter2Qualified = (doc, yPos) => {
    doc.setFont('Roboto-Bold');
    doc.setFontSize(11)
    doc.text("2. Rights and obligations of the Parties", 150, yPos, { align: "center" })
    doc.text("2. Tomonlarning Huquq va Majburiyatlari", 446, yPos, { align: "center" })

    let maxYPos = yPos + 20
    doc.setFontSize(9)
    doc.text("2.1 The University is committed:", EN_X_POS, maxYPos)
    doc.text("2.1 Universitet quyidagi majburiyatlarni o’z zimmasiga oladi:", UZ_X_POS, maxYPos)
    doc.setFontSize(11)
    maxYPos = maxYPos + LINE_SPACING
    Chapter2_2_1Qualified.forEach(obj => {
        maxYPos = printJustifyiedText({
            uzText: obj.uz,
            enText: obj.en,
            textWidth: TEXT_WIDTH,
            doc,
            fontSize: FONT_SIZE,
            fontType: "Roboto-Regular",
            YPosition: maxYPos,
        })
    })

    doc.setFont('Roboto-Bold');
    doc.setFontSize(11)
    maxYPos = maxYPos + LINE_SPACING
    doc.text("2.2. The University has the right:", EN_X_POS, maxYPos)
    doc.text("2.2. Universitetning huqulari:", UZ_X_POS, maxYPos)
    maxYPos = maxYPos + LINE_SPACING
    Chapter2_2_2Qualified.forEach(obj => {
        maxYPos = printJustifyiedText({
            uzText: obj.uz,
            enText: obj.en,
            textWidth: TEXT_WIDTH,
            doc,
            fontSize: FONT_SIZE,
            fontType: "Roboto-Regular",
            YPosition: maxYPos,
        })
    })

    doc.setFont('Roboto-Bold');
    doc.setFontSize(11)
    maxYPos = maxYPos + LINE_SPACING
    doc.text("2.3. The student is obliged:", EN_X_POS, maxYPos)
    doc.text("2.3. Talabaning majburiyatlari:", UZ_X_POS, maxYPos)
    maxYPos = maxYPos + LINE_SPACING
    Chapter2_2_3Qualified.forEach(obj => {
        maxYPos = printJustifyiedText({
            uzText: obj.uz,
            enText: obj.en,
            textWidth: TEXT_WIDTH,
            doc,
            fontSize: FONT_SIZE,
            fontType: "Roboto-Regular",
            YPosition: maxYPos,
        })
    })

    doc.setFont('Roboto-Bold');
    doc.setFontSize(11)
    maxYPos = maxYPos + LINE_SPACING
    doc.text("2.4. The Student has the right:", EN_X_POS, maxYPos)
    doc.text("2.4. Talaba quyidagi huquqlarga ega:", UZ_X_POS, maxYPos)
    maxYPos = maxYPos + LINE_SPACING
    Chapter2_2_4Qualified.forEach(obj => {
        maxYPos = printJustifyiedText({
            uzText: obj.uz,
            enText: obj.en,
            textWidth: TEXT_WIDTH,
            doc,
            fontSize: FONT_SIZE,
            fontType: "Roboto-Regular",
            YPosition: maxYPos,
        })
    })

    return maxYPos
}

const Chapter3Qualified = (doc, yPos) => {
    doc.setFont('Roboto-Bold');
    doc.setFontSize(11)
    doc.text("3. The cost of educational services,", 150, yPos, { align: "center" })
    doc.text("terms, and payment procedure.", 150, (yPos + 11), { align: "center" })
    doc.text("3. Ta’lim xizmatlari narxi, ularni", 446, yPos, { align: "center" })
    doc.text("to’lash shartlari va tartibi", 446, (yPos + 11), { align: "center" })

    let maxYPos = yPos + 40
    const enTxt = `3.1. The cost (education fee) of one academic year under this Contract is 27.900.000 (twenty seven million nine hundred thousand) UZS. The Student will be given a grant of 6 (six) million UZS in the first year. The grant will be continued to the 2nd, 3rd and 4th year conditioned to the students meeting the academic requirement of 85% attendance and 8.5 GPA in average from the 2nd semester. The university holds the right to cancel the scholarship band on the student’s academic performance or discipline.`
    const uzTxt = `3.1. Ushbu Shartnoma bo'yicha yillik o'qitish qiymati 27 900 000 (yigirma yetti million to'qqiz yuz ming) so'mni tashkil qiladi.  Talabaga birinchi o’quv yilida 6 (olti) million so’m miqdoridagi grant beriladi. Ushbu grant talabaga 2-3-4-kurslarda ham taqdim etilishi mumkin. Buning uchun talaba tomonidan 2-semester yakuniga ko’ra 85 foizlik davomat  va 8.5 ballik GPA ko’rsatkichlariga ega bo’lish talab qilinadi. Talaba tomonidan akademik o’zlashtirishi yoki intizomiy harakatlar sodir etilgan holatlarda universitet tomonidan mazkur grant to’xtatilishi mumkin.`

    maxYPos = printJustifyiedText({
        uzText: uzTxt,
        enText: enTxt,
        textWidth: TEXT_WIDTH,
        doc,
        fontSize: FONT_SIZE,
        fontType: "Roboto-Regular",
        YPosition: maxYPos,
    })
    
    Chapter3DataQualified.forEach(obj => {
        maxYPos = printJustifyiedText({
            uzText: obj.uz,
            enText: obj.en,
            textWidth: TEXT_WIDTH,
            doc,
            fontSize: FONT_SIZE,
            fontType: "Roboto-Regular",
            YPosition: maxYPos,
        })
    })

    return maxYPos
}

const Chapter4Qualified = (doc, yPos) => {
    doc.setFont('Roboto-Bold');
    doc.setFontSize(11)
    doc.text("4. Responsibility of the Parties", 150, yPos, { align: "center" })
    doc.text("4. Tomonlarning javobgarligi", 446, yPos, { align: "center" })

    let maxYPos = yPos + 20
    Chapter4DataQualified.forEach(obj => {
        maxYPos = printJustifyiedText({
            uzText: obj.uz,
            enText: obj.en,
            textWidth: TEXT_WIDTH,
            doc,
            fontSize: FONT_SIZE,
            fontType: "Roboto-Regular",
            YPosition: maxYPos,
        })
    })

    return maxYPos
}

const Chapter5Qualified = (doc, yPos) => {
    doc.setFont('Roboto-Bold');
    doc.setFontSize(11)
    doc.text("5. Force Majeure", 150, yPos, { align: "center" })
    doc.text("5. Fors-major", 446, yPos, { align: "center" })

    let maxYPos = yPos + 20
    Chapter5DataQualified.forEach(obj => {
        maxYPos = printJustifyiedText({
            uzText: obj.uz,
            enText: obj.en,
            textWidth: TEXT_WIDTH,
            doc,
            fontSize: FONT_SIZE,
            fontType: "Roboto-Regular",
            YPosition: maxYPos,
        })
    })

    return maxYPos
}

const Chapter6Qualified = (doc, yPos) => {
    doc.setFont('Roboto-Bold');
    doc.setFontSize(11)
    doc.text("6. Dispute resolution procedure", 150, yPos, { align: "center" })
    doc.text("6. Nizolarni hal qilish tartibi", 446, yPos, { align: "center" })

    let maxYPos = yPos + 20
    Chapter6DataQualified.forEach(obj => {
        maxYPos = printJustifyiedText({
            uzText: obj.uz,
            enText: obj.en,
            textWidth: TEXT_WIDTH,
            doc,
            fontSize: FONT_SIZE,
            fontType: "Roboto-Regular",
            YPosition: maxYPos,
        })
    })

    return maxYPos
}

const Chapter7Qualified = (doc, yPos) => {
    doc.setFont('Roboto-Bold');
    doc.setFontSize(11)
    doc.text("7. Final provisions", 150, yPos, { align: "center" })
    doc.text("7. Yakuniy qoidalar", 446, yPos, { align: "center" })

    let maxYPos = yPos + 20
    Chapter7DataQualified.forEach(obj => {
        maxYPos = printJustifyiedText({
            uzText: obj.uz,
            enText: obj.en,
            textWidth: TEXT_WIDTH,
            doc,
            fontSize: FONT_SIZE,
            fontType: "Roboto-Regular",
            YPosition: maxYPos,
        })
    })

    return maxYPos
}

const Chapter8Qualified = (doc, yPos) => {
    let maxYPos = yPos
    doc.setFont('Roboto-Bold');
    doc.setFontSize(10)
    doc.text("8. TOMONLARNING YURIDIK MANZILLARI / LEGAL ADRESSES OF THE PARTIES", 297, maxYPos, { align: "center" })
    maxYPos += 15
    doc.setLineWidth(1.0);
    // Horizontal line
    doc.line(15, maxYPos, 582, maxYPos);
    doc.line(15, maxYPos + LINE_SPACING + 5, 582, maxYPos + LINE_SPACING + 5);
    // Vertical line
    doc.line(15, maxYPos, 15, 350)
    doc.line(297, maxYPos, 297, 350)
    doc.line(582, maxYPos, 582, 350)

    doc.text("Universitet / UNIVERSITY", 156, maxYPos + LINE_SPACING, {align: "center"})
    doc.text("Talaba / STUDENT", 439, maxYPos + LINE_SPACING , {align: "center"})
    
    maxYPos = maxYPos + 5 + LINE_SPACING * 2
    const Text_Start_Pos = yPos + 20 + LINE_SPACING * 2
    doc.text("Acharya universiteti/", 156, maxYPos, {align: "center"})
    doc.text("Acharya University ", 156, maxYPos + LINE_SPACING, {align: "center"})

    maxYPos += LINE_SPACING
    maxYPos = printJustifyiedTextSingle({
        text: "Pochta manzili: / Legal Address:",
        textWidth: TEXT_WIDTH,
        doc,
        fontSize: 10,
        fontType: "Roboto-Bold",
        YPosition: maxYPos + (LINE_SPACING * 2),
        xPosition: 25
    })

    maxYPos = printJustifyiedTextSingle({
        text: "200800, Buxoro viloyati, Qorako’l tumani, Xo’jalar MFY, Buxoro ko’chasi 60-uy",
        textWidth: TEXT_WIDTH,
        doc,
        fontSize: 9,
        fontType: "Roboto-Regular",
        YPosition: maxYPos,
        xPosition: 25
    })

    maxYPos = printJustifyiedTextSingle({
        text: "To’lov rekvizitlari: / PaymentDetails:",
        textWidth: TEXT_WIDTH,
        doc,
        fontSize: 10,
        fontType: "Roboto-Bold",
        YPosition: maxYPos + 5,
        xPosition: 25
    })

    doc.setFont('Roboto-Regular');
    doc.setFontSize(10)
    doc.text("Р/с 20208000205598610001 ", 25, maxYPos)
    doc.text("В.с 2020884050559861 0001", 25, maxYPos + LINE_SPACING)
    doc.text("Aloqabank ATB Buxoro filiali", 25, maxYPos + (LINE_SPACING * 2))
    doc.text("MFO 00401", 25, maxYPos + (LINE_SPACING * 3))
    doc.text("INN 310106691", 25, maxYPos + (LINE_SPACING * 4))
    doc.text("IFUT 32407571", 25, maxYPos + (LINE_SPACING * 5))

    // 2nd Column
    maxYPos = Text_Start_Pos
    doc.text("F.I.Sh/ Name", 310, maxYPos)
    maxYPos = maxYPos + LINE_SPACING
    doc.text(``, 310, maxYPos)
    maxYPos = maxYPos + 5 + LINE_SPACING
    doc.text("Passport seriyasi:/ Passport details", 310, maxYPos)
    maxYPos = maxYPos + LINE_SPACING * 2
    doc.text(``, 310, maxYPos)
    maxYPos = maxYPos + 10 + LINE_SPACING
    doc.text("Qachon, kim tomonidan berilgan:/Issuedby:", 310, maxYPos)
    maxYPos = maxYPos + LINE_SPACING * 4
    doc.text("Amal qilish muddati: / Valid until: ", 310, maxYPos)
    maxYPos = maxYPos + LINE_SPACING
    doc.text(``, 310, maxYPos)
    maxYPos = maxYPos + 20 + LINE_SPACING
    doc.text("Manzili:/ Address:", 310, maxYPos)
    maxYPos = maxYPos + LINE_SPACING
    maxYPos = printJustifyiedTextSingle({
        text: ``,
        textWidth: TEXT_WIDTH,
        doc,
        fontSize: 10,
        fontType: "Roboto-Regular",
        YPosition: maxYPos,
        xPosition: 310
    })
    maxYPos = maxYPos + (LINE_SPACING * 2)
    doc.text("Tel:", 310, maxYPos)
    maxYPos = maxYPos + LINE_SPACING
    doc.text(``, 310, maxYPos)

    maxYPos += 8
    doc.line(15, maxYPos, 582, maxYPos);
    return maxYPos
}

const Signatures = (doc, yPos) => {
    let maxYPos = 0
    doc.setFont('Roboto-Bold');
    doc.setFontSize(10)
    maxYPos = yPos + 20
    doc.text("TOMONLARNING IMZOLARI / SIGNATURES", 297, maxYPos, { align: "center" })
    
    maxYPos = maxYPos + 15
    doc.setLineWidth(1.0);
    // Horizontal line
    doc.line(15, maxYPos, 582, maxYPos);
    doc.line(15, maxYPos + LINE_SPACING + 5, 582, maxYPos + LINE_SPACING + 5);
    // Vertical line
    doc.line(15, maxYPos, 15, maxYPos + 330)
    doc.line(297, maxYPos, 297, maxYPos + 330)
    doc.line(582, maxYPos, 582, maxYPos + 330)

    maxYPos = maxYPos + LINE_SPACING
    const startPos = maxYPos
    doc.text("Universitet / University", 118, maxYPos, {align: "center"})
    doc.text("TALABA / STUDENT", 468, maxYPos, {align: "center"})

    maxYPos = maxYPos + LINE_SPACING
    doc.text("Direktor / Director ", 20, maxYPos)
    doc.setFont('Roboto-Regular', "normal");
    doc.setFontSize(10)
    maxYPos = maxYPos + LINE_SPACING
    doc.text("Chandrashekar Vishesh / Chandrashekar  Vishesh", 20, maxYPos)

    maxYPos = maxYPos + LINE_SPACING
    doc.addImage(sign, 'png', 18, maxYPos, 170, 100)
    maxYPos = maxYPos + 100 + LINE_SPACING
    doc.text("Imzo /Signature ", 18, maxYPos)
    maxYPos = maxYPos + LINE_SPACING
    doc.addImage(stamp, 'png', 18, maxYPos, 170, 120)
    maxYPos = maxYPos + 120 + LINE_SPACING
    doc.text("М.O’./Stamp ", 18, maxYPos)

    doc.line(15, maxYPos + 20, 582, maxYPos + 20);
    
    // 2nd Column
    maxYPos = startPos + 40
    doc.text("_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _", 310, maxYPos)
    maxYPos += LINE_SPACING + 5
    doc.text("(F.I.Sh/ Name)", 439, maxYPos, {align: "center"})
    maxYPos += LINE_SPACING * 7
    doc.text("_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _", 310, maxYPos)
    maxYPos += LINE_SPACING + 5
    doc.text("Imzo/Signature ", 310, maxYPos)
    maxYPos += LINE_SPACING * 10
    doc.text("_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _", 310, maxYPos)
    maxYPos += LINE_SPACING + 5
    doc.text("М.O’./Stamp", 310, maxYPos)
}

export const GenerateNCTContractQualifiedPdf = (data) => {
    const doc = new jsPDF("p", "pt", "a4");
    // Width of a page is 447 ==> in px
    // Width of a page is 597 ==> in pt
    let maxYPos = 0

    maxYPos = HeaderQualified(doc, data)
    maxYPos = Chapter1Qualified(doc, maxYPos + 15)
    maxYPos = Chapter2Qualified(doc, maxYPos + 15)
    maxYPos = Chapter3Qualified(doc, maxYPos + 15)
    maxYPos = Chapter4Qualified(doc, maxYPos + 15)
    maxYPos = Chapter5Qualified(doc, maxYPos + 15)
    maxYPos = Chapter6Qualified(doc, maxYPos + 15)
    maxYPos = Chapter7Qualified(doc, maxYPos + 15)
    doc.addPage()
    maxYPos = Chapter8Qualified(doc, PAGE_WRAP_INITIAL_Y_POS + 15)
    Signatures(doc, maxYPos)

    doc.save(`${data.application_no_npf}.pdf`)
}