import { useState, useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import axios from "../../../services/Api";
import { useParams } from "react-router-dom";
import WMlogo from "../../../assets/wmLogo.jpg";
import moment from "moment";
import useBreadcrumbs from "../../../hooks/useBreadcrumbs";

const styles = StyleSheet.create({
  viewer: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  pageLayout: { padding: 25 },

  container: {
    width: "100%",
    height: "375px",
    border: "1px solid black",
    display: "flex",
    justifyContent: "center",
  },

  bodyContainer: {
    width: "100%",
    height: "230px",
    border: "0.5px solid #000",
    display: "flex",
    justifyContent: "center",
  },

  table: {
    width: "540px",
    height: "auto",
    justifyContent: "center",
    alignItems: "center",
  },

  title: {
    fontSize: 14,
    fontFamily: "Times-Roman",
    marginTop: "-20px",
  },

  paymentVoucher: {
    fontSize: 12,
    fontFamily: "Times-Roman",
    marginLeft: "225px",
    marginBottom: "14px",
  },

  address: {
    fontSize: 12,
    fontFamily: "Times-Roman",
    textAlign: "center",
  },

  tableRowStyle: {
    flexDirection: "row",
  },
  tableRowStyle1: {
    flexDirection: "row",
  },
  tableRowStyle2: {
    flexDirection: "column",
  },
  thStyle: {
    fontSize: "11px",
    fontWeight: "bold",
    width: "40%",
    fontFamily: "Times-Roman",
    color: "#000000",
  },
  thStyle1: {
    fontSize: "11px",
    fontWeight: "bold",
    // width: "37%",
    fontFamily: "Times-Roman",
    color: "#000000",
  },

  tdStyle: {
    fontSize: "11px",
    fontWeight: "bold",
    fontFamily: "Times-Roman",
    color: "#000000",
    width: "170px",
    textTransform: "capitalize",
  },

  fcYearText: {
    textAlign: "center",
    alignItems: "center",
    marginTop: "-10px",
    marginBottom: "6px",
    width: "540px",
  },

  footer: {
    textAlign: "center",
    alignItems: "center",
    marginTop: "-40px",
    width: "540px",
  },

  footerData: {
    textAlign: "center",
    alignItems: "center",
    marginTop: "5px",
    width: "540px",
  },
  border: {
    border: "1px solid black",
    marginLeft: "100px",
    marginRight: "100px",
  },

  timetableStyle: {
    display: "table",
    width: "540px",
  },

  timeTableThHeaderStyleParticulars: {
    width: "65%",
    borderTop: "1px solid black",
    borderBottom: "1px solid black",
    borderRight: "1px solid black",
    borderLeft: "1px solid black",
  },
  timeTableThHeaderStyle: {
    width: "20%",
    borderTop: "1px solid black",
    borderBottom: "1px solid black",
    borderRight: "1px solid black",
  },

  timeTableThStyle: {
    textAlign: "center",
    padding: "5px",
    fontFamily: "Times-Roman",
    fontSize: "12px",
  },

  timeTableTdHeaderStyle1Particulars: {
    width: "50%",
    borderRight: "0.4px solid #000",
  },
  thStyleLeft: {
    fontSize: "11px",
    fontWeight: "bold",
    fontFamily: "Times-Roman",
    color: "#000000",
    left: "10px",
    textAlign: "left",
    paddingTop: "20px",
  },
  thStyleRight: {
    fontSize: "11px",
    fontWeight: "bold",
    fontFamily: "Times-Roman",
    color: "#000000",
    left: "10px",
    textAlign: "right",
    marginTop: "-20px",
  },
  timeTableTdHeaderStyle1: {
    width: "25%",
  },
  timeTableTdStyle: {
    textAlign: "left",
    padding: "5px",
    fontFamily: "Times-Roman",
    fontSize: "10px",
  },
  timeTableTdStyle1: {
    textAlign: "center",
    padding: "5px",
    fontFamily: "Times-Roman",
    fontSize: "10px",
  },
  studentLogo: {
    position: "sticky",
    width: "100px",
    height: "100px",
    marginTop: "70px",
    marginLeft: "250px",
    borderRadius: 5,
  },
});

function JournalVoucherPdf() {
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);

  const { id, schoolId, fcyearId } = useParams();
  const setCrumbs = useBreadcrumbs();

  useEffect(() => {
    getJournalVoucherPdfData();
    setCrumbs([{ name: "Journal Index", link: "/JournalIndex" }]);
  }, []);

  const getJournalVoucherPdfData = async () => {
    await axios
      .get(
        `/api/finance/getDraftJournalVoucherData/${id}/${schoolId}/${fcyearId}`
      )
      .then((res) => {
        setData(res.data.data);
        setUserData(res.data.data[0]);
      })
      .catch((err) => console.error(err));
  };

  function numberToWords(number) {
    const units = [
      "Zero",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "Ten",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    function convertBelowThousand(num) {
      if (num === 0) {
        return "";
      } else if (num < 10) {
        return units[num];
      } else if (num < 20) {
        return teens[num - 10];
      } else if (num < 100) {
        const ten = Math.floor(num / 10);
        const remainder = num % 10;
        return tens[ten] + (remainder !== 0 ? " " + units[remainder] : "");
      } else if (num < 1000) {
        const hundred = Math.floor(num / 100);
        const remainder = num % 100;
        return (
          units[hundred] +
          " Hundred" +
          (remainder !== 0 ? " " + convertBelowThousand(remainder) : "")
        );
      } else {
        const thousand = Math.floor(num / 1000);
        const remainder = num % 1000;
        return (
          convertBelowThousand(thousand) +
          " Thousand" +
          (remainder !== 0 ? " " + convertBelowThousand(remainder) : "")
        );
      }
    }

    if (number === 0) {
      return units[number];
    } else {
      return convertBelowThousand(number);
    }
  }

  const timeTableTitle = () => {
    return (
      <>
        <View style={{ textAlign: "center", height: "20%" }}></View>
        <View>
          <Text style={{ textAlign: "center" }}>Acharya University</Text>
          <Text style={styles.address}>
            Khojalar neighborhood citizen council,bukhara street karakol
            district,Uzbekistan
          </Text>
        </View>
        <View>
          <Text style={styles.paymentVoucher}>Journal Voucher</Text>
        </View>
      </>
    );
  };

  const fcYearText = () => {
    return (
      <>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.thStyle}>Voucher No : {id}</Text>

          <Text style={styles.thStyle}>
            Fc Year :{" "}
            {userData?.financial_year ? userData.financial_year : "NA"}
          </Text>
          <Text style={styles.thStyle}>
            Date : {moment(userData?.created_date).format("DD-MM-YYYY")}
          </Text>
        </View>
      </>
    );
  };

  const footerData = () => {
    return (
      <>
        {console.log(userData, "userData")}
        <View
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <View style={styles.thStyleLeft}>
            <Text>Created by</Text>
            <Text style={styles.thStyle1}>
              {userData.created_username ? userData.created_username : "NA"}{" "}
            </Text>
          </View>
        </View>
      </>
    );
  };

  const timeTableHeader = () => {
    return (
      <>
        <View style={styles.tableRowStyle} fixed>
          <View style={styles.timeTableThHeaderStyleParticulars}>
            <Text style={styles.timeTableThStyle}>Particulars</Text>
          </View>

          <View style={styles.timeTableThHeaderStyle}>
            <Text style={styles.timeTableThStyle}>Debit</Text>
          </View>
          <View style={styles.timeTableThHeaderStyle}>
            <Text style={styles.timeTableThStyle}>Credit</Text>
          </View>
        </View>
      </>
    );
  };

  const timeTableBody = () => {
    return (
      <>
        <View style={{ flexDirection: "row" }}>
          <View
            style={{
              width: "40%",
              height: "225px",
            }}
          >
            <View
              style={{
                height: "100px",
              }}
            >
              {data.length > 0 ? (
                data.map((obj, i) => {
                  return (
                    <View style={{ flexDirection: "column" }} key={i}>
                      <Text
                        style={{
                          textAlign: "left",
                          padding: 3.5,
                          fontFamily: "Times-Roman",
                          fontSize: "12px",
                        }}
                      >
                        {obj.vendor_name}
                      </Text>
                    </View>
                  );
                })
              ) : (
                <></>
              )}
            </View>

            <View>
              <Text
                style={{
                  textAlign: "left",
                  padding: 4,
                  fontFamily: "Times-Roman",
                  fontSize: "10px",
                }}
              >
                Paid To : {userData?.pay_to ? userData.pay_to : "NA"}
              </Text>
              <Text
                style={{
                  textAlign: "left",

                  padding: 4,
                  fontFamily: "Times-Roman",
                  fontSize: "10px",
                }}
              >
                Department : {userData?.dept_name ? userData.dept_name : "NA"}
              </Text>
              <Text
                style={{
                  textAlign: "left",
                  padding: 4,
                  fontFamily: "Times-Roman",
                  fontSize: "10px",
                }}
              >
                Narration : {userData.remarks ? userData.remarks : "NA"}
              </Text>
              <Text
                style={{
                  textAlign: "left",
                  padding: 4,
                  fontFamily: "Times-Roman",
                  fontSize: "10px",
                }}
              >
                Contract Number :{" "}
                {userData.contract_number ? userData.contract_number : "NA"}
              </Text>
              <Text
                style={{
                  textAlign: "left",
                  padding: 4,
                  fontFamily: "Times-Roman",
                  fontSize: "10px",
                  marginTop: 12,
                }}
              >
                {"        "}
                <br />
              </Text>
              <Text
                style={{
                  textAlign: "left",

                  padding: 4,
                  fontFamily: "Times-Roman",
                  fontSize: "12px",
                }}
              >
                {userData?.debit_total &&
                  `${numberToWords(userData?.debit_total)}${" "}UZS`}
              </Text>
            </View>
          </View>
          <View
            style={{
              borderRight: "1px solid black",
              width: "25%",
              height: "225px",
            }}
          >
            <View
              style={{
                width: "120px",
                color: "grey",
                marginTop: "20px",
                height: "300px",
              }}
            >
              <Image src={WMlogo}></Image>
            </View>
          </View>
          <View
            style={{
              //   borderBottom: "1px solid black",
              borderRight: "1px solid black",
              width: "20%",
              height: "225px",
            }}
          >
            <View
              style={{
                height: "100px",
              }}
            >
              {data.length > 0 ? (
                data.map((obj, i) => {
                  return (
                    <View style={{ flexDirection: "column" }} key={i}>
                      <Text
                        style={{
                          textAlign: "right",
                          padding: 3.5,
                          fontFamily: "Times-Roman",
                          fontSize: "10px",
                        }}
                      >
                        {Number(obj.debit).toFixed(2)}
                      </Text>
                    </View>
                  );
                })
              ) : (
                <></>
              )}
            </View>

            <View style={{ marginTop: "40px" }}>
              <Text
                style={{
                  textAlign: "right",
                  padding: 4,
                  fontFamily: "Times-Roman",
                  fontSize: "10px",
                }}
              >
                {" "}
              </Text>
              <Text
                style={{
                  textAlign: "right",
                  padding: 4,
                  fontFamily: "Times-Roman",
                  fontSize: "10px",
                }}
              >
                {" "}
              </Text>
              <Text
                style={{
                  textAlign: "right",
                  padding: 4,
                  fontFamily: "Times-Roman",
                  fontSize: "10px",
                }}
              >
                {" "}
              </Text>
              <Text
                style={{
                  textAlign: "right",
                  padding: 4,
                  fontFamily: "Times-Roman",
                  fontSize: "10px",
                }}
              >
                {" "}
              </Text>
              <Text
                style={{
                  textAlign: "right",
                  padding: 4,
                  fontFamily: "Times-Roman",
                  fontSize: "10px",
                }}
              >
                {" "}
              </Text>
              <Text
                style={{
                  textAlign: "right",
                  padding: 4,
                  fontFamily: "Times-Roman",
                  fontSize: "10px",
                  borderTop: "1px solid black",
                }}
              >
                {Number(userData.debit_total).toFixed(2)}
              </Text>
            </View>
          </View>
          <View
            style={{
              //   borderBottom: "1px solid black",
              width: "20%",
              height: "225px",
            }}
          >
            <View
              style={{
                height: "100px",
              }}
            >
              {data.length > 0 ? (
                data.map((obj, i) => {
                  return (
                    <View style={{ flexDirection: "column" }} key={i}>
                      <Text
                        style={{
                          textAlign: "right",
                          padding: 3.5,
                          fontFamily: "Times-Roman",
                          fontSize: "10px",
                        }}
                      >
                        {Number(obj.credit).toFixed(2)}
                      </Text>
                    </View>
                  );
                })
              ) : (
                <></>
              )}
            </View>

            <View style={{ marginTop: "40px" }}>
              <Text
                style={{
                  textAlign: "right",
                  padding: 4,
                  fontFamily: "Times-Roman",
                  fontSize: "10px",
                }}
              >
                {" "}
              </Text>
              <Text
                style={{
                  textAlign: "right",
                  padding: 4,
                  fontFamily: "Times-Roman",
                  fontSize: "10px",
                }}
              >
                {" "}
              </Text>
              <Text
                style={{
                  textAlign: "right",
                  padding: 4,
                  fontFamily: "Times-Roman",
                  fontSize: "10px",
                }}
              >
                {" "}
              </Text>
              <Text
                style={{
                  textAlign: "right",
                  padding: 4,
                  fontFamily: "Times-Roman",
                  fontSize: "10px",
                }}
              >
                {" "}
              </Text>
              <Text
                style={{
                  textAlign: "right",
                  padding: 4,
                  fontFamily: "Times-Roman",
                  fontSize: "10px",
                }}
              >
                {" "}
              </Text>
              <Text
                style={{
                  textAlign: "right",
                  padding: 4,
                  fontFamily: "Times-Roman",
                  fontSize: "10px",
                  borderTop: "1px solid black",
                }}
              >
                {Number(userData.credit_total).toFixed(2)}
              </Text>
            </View>
          </View>
        </View>
      </>
    );
  };

  return (
    <>
      <PDFViewer style={styles.viewer}>
        <Document title="Journal Voucher">
          <Page size="A4">
            <View style={styles.pageLayout}>
              <View style={styles.container}>
                <View style={styles.title}>{timeTableTitle()}</View>
                <View style={styles.fcYearText}>{fcYearText()}</View>
                <View>{timeTableHeader()}</View>
                <View>{timeTableBody()}</View>
              </View>
              <View style={styles.footerData}>{footerData()}</View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </>
  );
}

export default JournalVoucherPdf;
