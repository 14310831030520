import { useState, useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
import axios from "../services/Api";
import useBreadcrumbs from "../hooks/useBreadcrumbs";
import { useParams } from "react-router-dom";
import WMlogo from "../assets/wmLogo.jpg";
import numberToWords from "number-to-words";

const styles = StyleSheet.create({
  viewer: {
    width: window.innerWidth,
    height: "100vh",
  },
  pageLayout: { padding: 25 },

  container: {
    width: "100%",
    height: "375px",
    border: "1px solid black",
    display: "flex",
    justifyContent: "center",
  },
  backgroundImage: {
    position: "absolute",
    width: "150px",
    marginLeft: "200px",
    height: "150px",
    color: "grey",
    marginTop: "40px",
  },
  bodyContainer: {
    width: "100%",
    height: "230px",
    border: "0.5px solid #000",
    display: "flex",
    justifyContent: "center",
  },

  title: {
    fontSize: 14,
    fontFamily: "Times-Roman",
    marginTop: "-20px",
  },

  address: {
    fontSize: 12,
    fontFamily: "Times-Roman",
    textAlign: "center",
  },

  border: {
    border: "1px solid black",
    marginLeft: "100px",
    marginRight: "100px",
  },

  thStyleLeft: {
    fontSize: "11px",
    fontWeight: "bold",
    fontFamily: "Times-Roman",
    color: "#000000",
    left: "25px",
    textAlign: "left",
    paddingTop: "20px",
  },
  thStyleRight: {
    fontSize: "11px",
    fontWeight: "bold",
    fontFamily: "Times-Roman",
    color: "#000000",
    right: "25px",
    textAlign: "right",
    paddingTop: "20px",
  },

  thStyleLeftfooter: {
    fontSize: "11px",
    fontWeight: "bold",
    fontFamily: "Times-Roman",
    color: "#000000",
    left: "25px",
    textAlign: "left",
  },
  thStyleRightfooter: {
    fontSize: "11px",
    fontWeight: "bold",
    fontFamily: "Times-Roman",
    color: "#000000",
    right: "25px",
    textAlign: "right",
  },

  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
  },
  row: {
    flexDirection: "row",
  },
  cell: {
    flex: 1,
    padding: 5,
    borderStyle: "solid",
    borderRight: 1,
    borderColor: "#000",
    textAlign: "left",
    fontFamily: "Times-Roman",
    fontSize: "10px",
    paddingBottom: 10,
  },
  cell1: {
    flex: 1,
    padding: 5,
    borderStyle: "solid",
    borderColor: "#000",
    textAlign: "left",
    fontFamily: "Times-Roman",
    fontSize: "10px",
    paddingBottom: 10,
  },
  amount: {
    flex: 1,
    padding: 5,
    borderStyle: "solid",
    borderRight: 1,
    borderColor: "#000",
    textAlign: "right",
    fontFamily: "Times-Roman",
    fontSize: "10px",
    paddingBottom: 10,
  },

  header: {
    flex: 1,
    padding: 3,
    borderStyle: "solid",
    borderColor: "#000",
    textAlign: "center",
    fontFamily: "Times-Roman",
    fontSize: "10px",
    paddingBottom: 20,
    borderLeft: 1,
    borderBottom: 1,
  },
  header1: {
    flex: 1,
    padding: 3,
    borderStyle: "solid",
    borderRight: 1,
    borderColor: "#000",
    borderLeft: 1,
    textAlign: "right",
    fontFamily: "Times-Roman",
    fontSize: "10px",
    paddingBottom: 20,
    borderBottom: 1,
  },
  Total: {
    width: "80%",
    padding: 3,
    borderStyle: "solid",
    borderColor: "#000",
    textAlign: "center",
    fontFamily: "Times-Roman",
    fontSize: "10px",
    paddingBottom: 20,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
  },
  Amount: {
    width: "20%",
    padding: 3,
    borderStyle: "solid",
    borderRight: 1,
    borderColor: "#000",
    borderLeft: 1,
    textAlign: "right",
    fontFamily: "Times-Roman",
    fontSize: "10px",
    paddingBottom: 20,
    borderBottom: 1,
  },
  Content: {
    flex: 1,
    padding: 3,
    borderStyle: "solid",
    borderColor: "#000",
    textAlign: "center",
    fontFamily: "Times-Roman",
    fontSize: "10px",
    paddingBottom: 20,
    borderLeft: 1,
    borderBottom: 1,
  },
  Content1: {
    flex: 1,
    padding: 3,
    borderStyle: "solid",
    borderRight: 1,
    borderColor: "#000",
    borderLeft: 1,
    textAlign: "right",
    fontFamily: "Times-Roman",
    fontSize: "10px",
    paddingBottom: 20,
    borderBottom: 1,
  },

  tableRowStyle: {
    flexDirection: "row",
  },

  timeTableTitle: {
    backgroundColor: "#182778",
    color: "white",
    padding: "5px",
    fontSize: 12,
    fontFamily: "Times-Roman",
    marginTop: "100px",
    marginBottom: "10px",
  },

  timetableStyle: {
    display: "table",
    width: "auto",
    marginBottom: "75px",
  },

  timeTableThHeaderStyle: {
    width: "20%",
    backgroundColor: "#182778",
    color: "white",
  },

  timeTableThStyle: {
    textAlign: "center",
    padding: "5px",
    fontFamily: "Times-Roman",
    fontSize: "10px",
    fontWeight: "bold",
  },

  timeTableTdHeaderStyle1: {
    width: "20%",
    borderWidth: 0.5,
    borderTopWidth: 0,
    height: "40px",
  },

  timeTableTdStyle: {
    textAlign: "center",
    padding: "5px",
    fontFamily: "Times-Roman",
    fontSize: "10px",
  },
});

function GrnPdf() {
  const { id } = useParams();
  console.log(id);

  const [data, setData] = useState([]);

  const setCrumbs = useBreadcrumbs();
  useEffect(() => {
    getPdfData();
    setCrumbs([{ name: "GRN Index", link: "/Itemmaster/GRN" }]);
  }, []);

  const getPdfData = async () => {
    await axios
      .get(
        `/api/purchase/getListofDirectGRNById?grnNo=${id?.replace(/_/g, "/")}`
      )
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => console.error(err));
  };

  console.log(data);
  const timeTableTitle = () => {
    return (
      <>
        <View style={{ textAlign: "center", height: "20%" }}></View>
        <View>
          <Text style={{ textAlign: "center", paddingTop: 10 }}>
            ACHARYA UNIVERSITY
          </Text>
          <Text style={styles.address}>
            Khojalar neighborhood citizen council,bukhara street karakol
            district,Uzbekistan
          </Text>
        </View>
        <Text style={{ textAlign: "center", paddingTop: 20 }}>
          Goods Receipt Note
        </Text>
      </>
    );
  };

  // const timeTableHeader = () => {
  //   return (
  //     <>
  //       <View style={styles.tableRowStyle} fixed>
  //         <View style={styles.timeTableThHeaderStyle}>
  //           <Text style={styles.timeTableThStyle}>Sl No.</Text>
  //         </View>
  //         <View style={styles.timeTableThHeaderStyle}>
  //           <Text style={styles.timeTableThStyle}>Description of Goods</Text>
  //         </View>
  //         <View style={styles.timeTableThHeaderStyle}>
  //           <Text style={styles.timeTableThStyle}>Quantity</Text>
  //         </View>
  //         <View style={styles.timeTableThHeaderStyle}>
  //           <Text style={styles.timeTableThStyle}>UOM</Text>
  //         </View>
  //         <View style={styles.timeTableThHeaderStyle}>
  //           <Text style={styles.timeTableThStyle}>Amount</Text>
  //         </View>
  //       </View>
  //     </>
  //   );
  // };

  // const timeTableBody = () => {
  //   return (
  //     <>
  //       {data?.map((obj, i) => {
  //         return (
  //           <>
  //             <View style={styles.tableRowStyle} key={i}>
  //               <View style={styles.timeTableTdHeaderStyle1}>
  //                 <Text style={styles.timeTableTdStyle}>{i + 1}</Text>
  //               </View>
  //               <View style={styles.timeTableTdHeaderStyle1}>
  //                 <Text style={styles.timeTableTdStyle}>{obj.itemName}</Text>
  //               </View>
  //               <View style={styles.timeTableTdHeaderStyle1}>
  //                 <Text style={styles.timeTableTdStyle}>{obj.quantity}</Text>
  //               </View>
  //               <View style={styles.timeTableTdHeaderStyle1}>
  //                 <Text style={styles.timeTableTdStyle}>{obj.uom}</Text>
  //               </View>
  //               <View style={styles.timeTableTdHeaderStyle1}>
  //                 <Text style={styles.timeTableTdStyle}>{obj.value}</Text>
  //               </View>
  //             </View>
  //           </>
  //         );
  //       })}
  //     </>
  //   );
  // };

  const timeTableHeader = () => {
    return (
      <>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <View style={styles.thStyleLeft}>
            <Text style={{ paddingBottom: "20px" }}>
              GRN : {data[0]?.grnNumber}
            </Text>
            <Text style={{ paddingBottom: "20px" }}>
              GRN Date:{" "}
              {moment(data[0]?.createdDate).isValid()
                ? moment(data[0]?.createdDate).format("DD-MM-YYYY")
                : null}
            </Text>
          </View>

          <View style={styles.thStyleRight}>
            <Text style={{ paddingBottom: "20px" }}>
              Invoice No : {data[0]?.invoiceNo}
            </Text>
            <Text style={{ paddingBottom: "20px" }}>
              Invoice Date:{" "}
              {moment(data[0]?.invoiceDate).isValid()
                ? moment(data[0]?.invoiceDate).format("DD-MM-YYYY")
                : null}
            </Text>
          </View>
        </View>
      </>
    );
  };

  const timeTableBody = () => {
    let grandTotal = 0;
    return (
      <>
        <View style={styles.section}>
          <View style={styles.table}>
            <View style={styles.row}>
              <View style={styles.cell}>
                <Text>Vendor Name : {data[0]?.vendorName}</Text>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.header}>
              <Text>Sl No.</Text>
            </View>
            <View style={styles.header}>
              <Text>Description of Goods</Text>
            </View>
            <View style={styles.header}>
              <Text>Quantity</Text>
            </View>
            <View style={styles.header}>
              <Text>UOM</Text>
            </View>
            <View style={styles.header1}>
              <Text>Amount</Text>
            </View>
          </View>

          {data?.map((_data, index) => {
            grandTotal += _data.value;
            return (
              <View style={styles.row}>
                <View style={styles.Content}>
                  <Text>{index + 1}</Text>
                </View>
                <View style={styles.Content}>
                  <Text>{_data.itemName}</Text>
                </View>
                <View style={styles.Content}>
                  <Text>{_data.quantity}</Text>
                </View>
                <View style={styles.Content}>
                  <Text>{_data.uom}</Text>
                </View>
                <View style={styles.Content1}>
                  <Text>{_data.value}</Text>
                </View>
              </View>
            );
          })}

          <View style={styles.row}>
            <View style={styles.Total}>
              <Text>Grand Total</Text>
            </View>
            <View style={styles.Amount}>
              <Text>{grandTotal}</Text>
            </View>
          </View>
        </View>

        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <View style={styles.thStyleLeftfooter}>
            <Text style={{ paddingBottom: "10px" }}>
              Amount (in words) :{" "}
              {grandTotal !== undefined && grandTotal !== null
                ? numberToWords.toWords(grandTotal)
                : ""}
            </Text>
            <Text style={{ paddingBottom: "10px" }}></Text>
            <Text style={{ paddingBottom: "10px" }}>
              Remarks : {data[0]?.remarks}
            </Text>
          </View>

          <View style={styles.thStyleRightfooter}>
            <Text style={{ paddingBottom: "10px" }}>E & O.E</Text>
            <Text style={{ paddingBottom: "10px" }}>
              Created By : {data[0]?.createdByUserName}
            </Text>
          </View>
        </View>
      </>
    );
  };

  return (
    <>
      <PDFViewer style={styles.viewer}>
        <Document title="GRN">
          <Page size="A4">
            <View style={styles.pageLayout}>
              <View style={styles.container}>
                <Image style={styles.backgroundImage} src={WMlogo} />
                <View style={styles.title}>{timeTableTitle()}</View>
                <View>{timeTableHeader()}</View>
                <View>{timeTableBody()}</View>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </>
  );
}

export default GrnPdf;
