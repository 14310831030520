import {
  Grid,
  Button,
  Typography,
  Box,
  Modal,
  IconButton,
} from "@mui/material";
import GridIndex from "./GridIndex";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import useBreadcrumbs from "../hooks/useBreadcrumbs";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useEffect } from "react";
import axios from "../services/Api";
import PrintIcon from "@mui/icons-material/Print";
import moment from "moment";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import FilePresentIcon from "@mui/icons-material/FilePresent";

const useModalStyles = makeStyles((theme) => ({
  box: {
    position: "fixed",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "90vh",
    border: "7px solid white",
    width: "100%",
    background: "white",
    boxShadow: 24,
    overflow: "auto",
    outline: "none",
  },
  header: {
    top: 0,
    padding: "7px 0",
    background: "white",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "1.3rem",
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  objectTag: {
    height: "150px",
    width: "100%",
    maxHeight: "100%",
    position: "relative",
    textAlign: "center",
    [theme.breakpoints.up(1024)]: {
      height: "600px",
    },
  },
}));

function GRNIndex() {
  const navigate = useNavigate();
  const setCrumbs = useBreadcrumbs();
  const [rows, setRows] = useState([]);
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [attachmentPath, setAttachmentPath] = useState("");
  const popupclass = useModalStyles();
  const [showImageModal, setShowImageModal] = useState(false);

  const columns = [
    { field: "grnNumber", headerName: "GRN No", flex: 1, hideable: false },
    {
      field: "createdDate",
      headerName: "GRN Date",
      flex: 1,
      hideable: false,
      valueGetter: (params) =>
        moment(params.row.createdDate).format("DD-MM-YYYY"),
    },
    {
      field: "value",
      headerName: "GRN Amount",
      flex: 1,
      hideable: false,
      headerAlign: "right",
      align: "right",
    },
    {
      field: "vendorName",
      headerName: "Vendor Name",
      flex: 1,
      hideable: false,
    },
    {
      field: "invoiceNo",
      headerName: "Invoice No",
      flex: 1,
      hideable: true,
      hide: true,
    },
    {
      field: "invoiceDate",
      headerName: "Invoice Date",
      flex: 1,
      hideable: true,
      hide: true,
      valueGetter: (params) =>
        moment(params.row.createdDate).format("DD-MM-YYYY"),
    },
    {
      field: "createdByUserName",
      headerName: "Created By",
      flex: 1,
      hideable: false,
    },

    {
      field: "storeName",
      headerName: "Store Name",
      flex: 1,
      hideable: false,
    },

    {
      field: "print",
      headerName: "Actions",
      flex: 1,
      hideable: false,
      renderCell: (params) => [
        <IconButton onClick={() => handleClick(params.row)} color="primary">
          <PrintIcon fontSize="small" />
        </IconButton>,
      ],
    },
    {
      field: "attachment",
      headerName: "Attachment",
      flex: 1,
      hideable: false,
      renderCell: (params) => (
        <>
          {" "}
          <IconButton
            color="primary"
            onClick={() => handleViewAttachment(params.row.attachment)}
          >
            <RemoveRedEyeIcon />
          </IconButton>
        </>
      ),
    },
    {
      field: "remarks",
      headerName: "remarks",
      flex: 1,
      hideable: false,
    },
  ];

  const handleViewAttachment = (fileName) => {
    axios
      .get(`/api/purchase/grnFileDownload?fileName=${fileName}`, {
        responseType: "blob",
      })
      .then((res) => {
        let contentType = res.headers["content-type"];
        const fileExtension = fileName.split(".").pop().toLowerCase();

        if (["png", "jpeg", "jpg", "gif", "bmp"].includes(fileExtension)) {
          contentType = `image/${fileExtension}`;
        }

        if (contentType && contentType.toLowerCase().startsWith("image")) {
          const path = URL.createObjectURL(res.data);
          setAttachmentPath(path);
          setShowAttachmentModal(true);
          setShowImageModal(true);
        } else {
          setShowImageModal(false);
          window.open(URL.createObjectURL(res.data));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleClick = (rowdata) => {
    navigate(`/GRNPdfNew/${rowdata.grnNumber.replace(/\//g, "_")}`);
  };

  useEffect(() => {
    setCrumbs([{ name: "GRN Index" }]);

    getData();
  }, []);

  const getData = async () => {
    const requestData = {
      pageNo: 0,
      pageSize: 100000,
    };

    try {
      const res = await axios.post(
        "/api/purchase/getListofDirectGRN",
        requestData
      );

      const rowsWithId = res.data.data.content.map((row, index) => ({
        ...row,
        id: index + 1,
      }));

      setRows(rowsWithId);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Box sx={{ position: "relative", mt: 4 }}>
        <Button
          onClick={() => navigate("/GRNIndex/new")}
          variant="contained"
          disableElevation
          sx={{ position: "absolute", right: 0, top: -57, borderRadius: 2 }}
          startIcon={<AddIcon />}
        >
          Create
        </Button>
        <GridIndex rows={rows} columns={columns} />
      </Box>

      <Modal
        open={showAttachmentModal}
        setOpen={setShowAttachmentModal}
        style={{ height: "100%" }}
      >
        <Box className={popupclass.box} borderRadius={3} maxWidth={800}>
          <Grid container className={popupclass.header}>
            <Grid item xs={11} pl={2}>
              <Typography variant="h6" className={popupclass.title}>
                {`Image Preview`}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                onClick={() => setShowAttachmentModal(false)}
                sx={{ position: "absolute", top: 0, right: 0 }}
              >
                <CloseRoundedIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Box p={2} pt={0}>
            <Box mt={2}>
              <Grid container rowSpacing={2}>
                {showImageModal ? (
                  <img
                    src={attachmentPath}
                    alt="Preview"
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                ) : (
                  <FilePresentIcon
                    onClick={() => setShowImageModal(true)}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
export default GRNIndex;
