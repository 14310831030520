import { useState, useEffect } from "react";
import { Grid, Button, CircularProgress, Box } from "@mui/material";
import CustomTextField from "../../../components/Inputs/CustomTextField";
import axios from "../../../services/Api";
import { useNavigate, useParams } from "react-router-dom";
import CustomAutocomplete from "../../../components/Inputs/CustomAutocomplete";
import useAlert from "../../../hooks/useAlert";
import useBreadcrumbs from "../../../hooks/useBreadcrumbs";
import FormWrapper from "../../../components/FormWrapper";
import CustomFileInput from "../../../components/Inputs/CustomFileInput";

const initialValues = {
  acYearId: "",
  schoolId: null,
  programId: "",
  specializationId: "",
  categoryId: "",
  nationality: "",
  remarks: "",
  fileName: null,
};

const requiredFields = ["fileName"];

function ChangeOfCourse() {
  const [values, setValues] = useState(initialValues);

  const [academicYearOptions, setAcademicYearOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [schoolData, setSchoolData] = useState([]);
  const [programData, setProgramData] = useState([]);
  const [data, setData] = useState([]);
  const [programId, setProgramId] = useState(null);
  const [programAssigmentId, setProgramAssignmentId] = useState(null);
  const [programSpeOptions, setProgramSpeOptions] = useState([]);
  const [admCategoryOptions, setAdmCategoryOptions] = useState([]);
  const [nationality, setNationality] = useState([]);
  const [reportingData, setReportingData] = useState([]);

  const { studentId } = useParams();
  const navigate = useNavigate();
  const { setAlertMessage, setAlertOpen } = useAlert();
  const setCrumbs = useBreadcrumbs();

  const checks = {
    fileName: [
      values.fileName,
      values.fileName && values.fileName.size < 2000000,
    ],
  };

  const errorMessages = {
    fileName: ["This field is required", "Maximum size 2 MB"],
  };

  useEffect(() => {
    getStudentsDetails();
    setCrumbs([{ name: "Change of course", link: "/StudentDetailsMaster" }]);
  }, []);

  useEffect(() => {
    getSchool();
    getAcademicYearData();
    getFeeAdmissionCategory();
    getNationality();
    getProgramData();
    getProgramSpeData();
    getReportingData();
  }, [values.schoolId]);

  const getAcademicYearData = async () => {
    await axios
      .get(`/api/academic/academic_year`)
      .then((res) => {
        setAcademicYearOptions(
          res.data.data.map((obj) => ({
            value: obj.ac_year_id,
            label: obj.ac_year,
          }))
        );
      })
      .catch((err) => console.error(err));
  };

  const getProgramSpeData = async () => {
    if (values.schoolId)
      await axios
        .get(
          `/api/academic/fetchAllProgramsWithSpecialization/${values.schoolId}`
        )
        .then((res) => {
          const temp = res.data.data.filter(
            (obj) => obj.program_specialization_id !== values.specializationId
          );

          setProgramSpeOptions(
            temp.map((obj) => ({
              value: obj.program_specialization_id,
              label: obj.specialization_with_program,
            }))
          );
        })
        .catch((err) => console.error(err));
  };

  const getFeeAdmissionCategory = async () => {
    await axios
      .get(`/api/student/FeeAdmissionCategory`)
      .then((res) => {
        setAdmCategoryOptions(
          res.data.data.map((obj) => ({
            value: obj.fee_admission_category_id,
            label: obj.fee_admission_category_type,
          }))
        );
      })
      .catch((err) => console.error(err));
  };

  const getSchool = async () => {
    await axios
      .get(`/api/institute/school`)
      .then((res) => {
        setSchoolData(
          res.data.data.map((obj) => ({
            value: obj.school_id,
            label: obj.school_name,
          }))
        );
      })
      .catch((error) => console.error(error));
  };

  const getStudentsDetails = async () => {
    await axios
      .get(`/api/student/studentDetailsByStudentIds/${studentId}`)
      .then((res) => {
        setData(res.data.data);
        setValues({
          acYearId: res.data.data[0].ac_year_id,
          schoolId: res.data.data[0].school_id,
          programId: res.data.data[0].program_name,
          specializationId: res.data.data[0].program_specialization_id,
          nationality: Number(res.data.data[0].nationality),
          categoryId: res.data.data[0].fee_admission_category_id,
        });
      })
      .catch((error) => console.error(error));
  };

  const getReportingData = async () => {
    await axios
      .get(`/api/student/reportingStudentByStudentId/${studentId}`)
      .then((res) => {
        setReportingData(res.data.data);
      })
      .catch((err) => console.error(err));
  };

  const getNationality = async () => {
    await axios(`/api/nationality`)
      .then((res) => {
        setNationality(
          res.data.map((obj) => ({
            value: obj.nationality_id,
            label: obj.nationality,
          }))
        );
      })
      .catch((err) => console.error(err));
  };

  const getProgramData = async () => {
    if (values.schoolId)
      await axios
        .get(`/api/academic/fetchAllProgramsWithProgramType/${values.schoolId}`)
        .then((res) => {
          setProgramData(
            res.data.data.map((obj) => ({
              value: obj.program_assignment_id,
              label: obj.program_name,
            }))
          );
        })
        .catch((error) => console.error(error));
  };

  const handleChange = async (e) => {
    setValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeAdvance = async (name, newValue) => {
    if (name === "specializationId") {
      await axios
        .get(
          `/api/academic/fetchAllProgramsWithSpecialization/${values.schoolId}`
        )
        .then((res) => {
          res.data.data.filter((obj) => {
            if (obj.program_specialization_id === newValue) {
              setProgramAssignmentId(obj.program_assignment_id);
              setProgramId(obj.program_id);
            }
          });
        })
        .catch((err) => console.error(err));
      setValues((prev) => ({
        ...prev,
        [name]: newValue,
      }));
    }
    setValues((prev) => ({
      ...prev,
      [name]: newValue,
    }));
  };

  const handleFileDrop = (name, newFile) => {
    if (newFile)
      setValues((prev) => ({
        ...prev,
        [name]: newFile,
      }));
  };

  const handleFileRemove = (name) => {
    setValues((prev) => ({
      ...prev,
      [name]: null,
    }));
  };

  const requiredFieldsValid = () => {
    for (let i = 0; i < requiredFields.length; i++) {
      const field = requiredFields[i];
      if (Object.keys(checks).includes(field)) {
        const ch = checks[field];
        for (let j = 0; j < ch.length; j++) if (!ch[j]) return false;
      } else if (!values[field]) return false;
    }
    return true;
  };

  const handleCreate = async (e) => {
    if (!requiredFieldsValid()) {
      setAlertMessage({
        severity: "error",
        message: "Please fill required fields",
      });
      setAlertOpen(true);
    } else {
      setLoading(true);
      const temp = {};
      const ap = [{}];
      const pgapp = {};
      const see = {};
      const srsh = {};
      const streq = {};
      const sts = {};

      const result = data.map(
        ({
          program_id,
          program_assignment_id,
          program_specialization_id,
          fee_admission_category_id,
          auid,
          acharya_email,
          fee_template_id,
          student_id,
          nationality,
          ...rest
        }) => ({
          ...rest,
        })
      );

      const obj = result[0];
      const obj1 = {
        ...obj,
        course_approver_status: 0,
        program_id: programId ? programId : data[0].program_id,
        program_assignment_id: programAssigmentId
          ? programAssigmentId
          : data[0].program_assignment_id,
        program_specialization_id: values.specializationId,
        fee_admission_category_id: values.categoryId,
        old_student_id: data[0].student_id,
        nationality: values.nationality,
      };

      temp.ap = ap;
      temp.pgapp = pgapp;
      temp.rs = reportingData;
      temp.sd = obj1;
      temp.see = see;
      temp.srsh = srsh;
      temp.streq = streq;
      temp.sts = sts;

      await axios
        .post(`/api/student/changeOfCourseProgram/${studentId}`, temp)
        .then(async (res) => {
          const dataArray = new FormData();
          dataArray.append("file", values.fileName);
          dataArray.append("newStudentId", res.data.data.student_id);
          await axios
            .post(`/api/student/changeOfCourseProgramUploadFile`, dataArray)
            .then((res) => {
              setLoading(false);
              setAlertMessage({
                severity: "success",
                message: "Change of course is Initiated",
              });
              setAlertOpen(true);
              navigate("/StudentDetailsMaster", { replace: true });
            })
            .catch((err) => {
              setLoading(false);
              setAlertMessage({
                severity: "error",
                message: err.response ? err.response.data.message : "Error",
              });
              setAlertOpen(true);
            });
        })
        .catch((error) => {
          setLoading(false);
          setAlertMessage({
            severity: "error",
            message: error.response ? error.response.data.message : "Error",
          });
          setAlertOpen(true);
        });
    }
  };

  return (
    <Box component="form" overflow="hidden" p={1}>
      <FormWrapper>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-start"
          rowSpacing={4}
          columnSpacing={{ xs: 2, md: 4 }}
        >
          <Grid item xs={12} md={4}>
            <CustomAutocomplete
              name="acYearId"
              value={values.acYearId}
              label="Ac Year"
              options={academicYearOptions}
              handleChangeAdvance={handleChangeAdvance}
              required
              disabled
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomAutocomplete
              name="schoolId"
              value={values.schoolId}
              label="School"
              options={schoolData}
              handleChangeAdvance={handleChangeAdvance}
              required
              disabled
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomAutocomplete
              name="specializationId"
              value={values.specializationId}
              label="Specialization"
              options={programSpeOptions}
              handleChangeAdvance={handleChangeAdvance}
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomAutocomplete
              name="categoryId"
              value={values.categoryId}
              label="Admission Category"
              options={admCategoryOptions}
              handleChangeAdvance={handleChangeAdvance}
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomAutocomplete
              name="nationality"
              value={values.nationality}
              label="Nationality"
              options={nationality}
              handleChangeAdvance={handleChangeAdvance}
              disabled
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomTextField
              name="remarks"
              label="Remarks"
              value={values.remarks}
              handleChange={handleChange}
              fullWidth
              errors={errorMessages.programSpeName}
              checks={checks.programSpeName}
              required
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomFileInput
              name="fileName"
              label="File"
              helperText="PDF - smaller than 2 MB"
              file={values.fileName}
              handleFileDrop={handleFileDrop}
              handleFileRemove={handleFileRemove}
              checks={checks.fileName}
              errors={errorMessages.fileName}
            />
          </Grid>

          <Grid item xs={12} textAlign="right">
            <Button
              style={{ borderRadius: 7 }}
              variant="contained"
              color="primary"
              disabled={values.fileName == null}
              onClick={handleCreate}
            >
              {loading ? (
                <CircularProgress
                  size={25}
                  color="blue"
                  style={{ margin: "2px 13px" }}
                />
              ) : (
                <strong>{"Create"}</strong>
              )}
            </Button>
          </Grid>
        </Grid>
      </FormWrapper>
    </Box>
  );
}

export default ChangeOfCourse;
