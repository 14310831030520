import { useEffect, useState } from "react";
import axios from "../services/Api";
import GridIndex from "./GridIndex";
import {
  Button,
  Box,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  Paper,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import useBreadcrumbs from "../hooks/useBreadcrumbs";
import moment from "moment";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ModalWrapper from "./ModalWrapper";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  table: {
    "& .MuiTableCell-root": {
      borderLeft: "1px solid rgba(224, 224, 224, 1)",
      textAlign: "center",
    },
  },
  bg: {
    backgroundColor: theme.palette.auzColor.main,
    color: theme.palette.headerWhite.main,
    textAlign: "center",
  },
}));

function StockIssueIndex() {
  const [Data, setData] = useState([]);
  const classes = useStyles();
  const navigate = useNavigate();
  const setCrumbs = useBreadcrumbs();
  const [StockData, setStockData] = useState([]);

  const columns = [
    {
      field: "stockNo",
      headerName: "Issue No.",
      flex: 1,
      hideable: false,
    },
    {
      field: "createdDate",
      headerName: "Issue Date",
      flex: 1,
      hideable: false,
      valueGetter: (params) =>
        moment(params.row.createdDate).format("DD-MM-YYYY"),
    },
    {
      field: "endUserName",
      headerName: "Issue To",
      flex: 1,
    },
    // {
    //   field: "storeName",
    //   headerName: "Store",
    //   flex: 1,
    // },

    {
      field: "createdByUserName",
      headerName: "Issued By",
      flex: 1,
    },
    {
      field: "purpose",
      headerName: "Remarks",
      flex: 1,
    },
    {
      field: "attachment",
      headerName: "View",
      flex: 1,
      hideable: false,
      renderCell: (params) => (
        <>
          <IconButton color="primary" onClick={() => handleView(params.row)}>
            <RemoveRedEyeIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);

  const handleView = async (row) => {
    await axios
      .get(`/api/purchase/getStockIssueByStockNumber?stock_no=${row.stockNo}`)
      .then((res) => {
        setModalOpen(true);
        setStockData(res.data.data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    setCrumbs([{ name: "Stock Issues" }]);
    getData();
  }, []);

  const getData = async () => {
    const requestData = {
      pageNo: 0,
      pageSize: 10000,
    };

    try {
      const res = await axios.post(
        "/api/purchase/getListOfStockIssue",
        requestData
      );

      const rowsWithId = res.data.data.content.map((row, index) => ({
        ...row,
        id: index + 1,
      }));

      setData(rowsWithId);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Box sx={{ position: "relative", mt: 4 }}>
        <Button
          onClick={() => navigate("/StockIssueNew")}
          variant="contained"
          disableElevation
          sx={{ position: "absolute", right: 0, top: -57, borderRadius: 2 }}
          startIcon={<AddIcon />}
        >
          Create
        </Button>
        <GridIndex rows={Data} columns={columns} />
      </Box>

      <ModalWrapper
        open={modalOpen}
        setOpen={setModalOpen}
        maxWidth={600}
        title={"Stock Issue"}
      >
        <Box mt={2} p={3}>
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small">
              <TableHead className={classes.bg}>
                <TableRow>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    Sl. No.
                  </TableCell>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    Item Name
                  </TableCell>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    Available Quantity
                  </TableCell>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    UOM
                  </TableCell>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    Issued Quantity
                  </TableCell>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    Issued Date
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.table}>
                {StockData.map((dataItem, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{dataItem.itemName}</TableCell>
                    <TableCell>{dataItem.availableQuantity}</TableCell>
                    <TableCell>{dataItem.uom}</TableCell>
                    <TableCell>{dataItem.issueQuantity}</TableCell>
                    <TableCell>
                      {dataItem.createdDate
                        ? moment(dataItem.createdDate).format("DD-MM-YYYY")
                        : ""}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </ModalWrapper>
    </>
  );
}

export default StockIssueIndex;
