import React, { useEffect, useState } from "react";
import useBreadcrumbs from "../../../hooks/useBreadcrumbs";
import axios from "../../../services/Api";
import { Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select } from "@mui/material";
import { useParams } from "react-router-dom";
import GridIndex from "../../../components/GridIndex";

const StudentDueByProgramme = () => {
    const setCrumbs = useBreadcrumbs();
    const params = useParams()
    const [loading, setLoading] = useState(true)
    const [tableRows, setTableRows] = useState([])
    const [tableColumns, setTableColumns] = useState([])
    const [academicYearList, setAcademicYearList] = useState([])
    const [academicYear, setAcademicYear] = useState("")
    const [programmeList, setProgrammeList] = useState([])
    const [selectedProgrammeList, setSelectedProgrammeList] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [category, setCategory] = useState("All")

    useEffect(() => {
        setCrumbs([
            {
                name: "Student Due Report",
                link: "/stdduereport",
            },
            {
                name: "By Programme"
            }
        ])

        getAllProgrammes()
        getAcademicYearList()
        getAllAdmissionCategory()
    }, [])

    useEffect(() => {
        getStudentDueList()
    }, [selectedProgrammeList, category])

    const getAllProgrammes = async () => {
        const res = await axios.get("/api/academic/getProgramList")

        const promisesArray = []
        for (const obj of res.data.data) {
            promisesArray.push(await getBasedOnProgramme(obj.programId))
        }

        Promise.all(promisesArray)
            .then(res => {
                const allData = res.flat()
                const names = allData.map(obj => {
                    return { id: obj.programId, label: obj.programName, value: obj.programId }
                })
                setProgrammeList(names)
                if (params.id === "all") setSelectedProgrammeList(names)
                else {
                    const pg = allData.filter(obj => obj.programId === parseInt(params.id))
                    setSelectedProgrammeList(pg.map(obj => {
                        return { id: obj.programId, label: obj.programName, value: obj.programId }
                    }))
                }
            })
    }

    const getBasedOnProgramme = (programmeId) => {
        return new Promise(async resolve => {
            const res = await axios.get(`/api/student/getTotalFeeDetails?program_id=${programmeId}`)
            resolve(res.data.data)
        })
    }

    const getAcademicYearList = () => {
        axios.get("/api/academic/academic_year")
            .then(res => {
                if (res.data.data.length <= 0) return

                const yearsObj = res.data.data.map(obj => {
                    const { ac_year, ac_year_code, ac_year_id, current_year } = obj
                    return { ac_year, ac_year_code, ac_year_id, current_year }
                })
                setAcademicYearList(yearsObj)
                setAcademicYear(yearsObj[0].ac_year)
            })
    }

    const getStudentDueList = async () => {
        const columnsToShow = [
            {
                field: "academicYear",
                headerName: "Academic Year",
                flex: 1,
                hide: true
            },
            {
                field: "auid",
                headerName: "AUID",
                flex: 1,
            },
            {
                field: "currentSem",
                headerName: "Current Sem",
                flex: 1
            },
            {
                field: "studentName",
                headerName: "Student Name",
                flex: 1
            },
            {
                field: "categoryShortName",
                headerName: "Category",
                flex: 1
            },
            {
                field: "totalFix",
                headerName: "Fixed",
                flex: 1,
                type: "number"
            },
            {
                field: "totalGrant",
                headerName: "Grant",
                flex: 1,
                type: "number"
            },
            {
                field: "totalPaid",
                headerName: "Paid",
                flex: 1,
                type: "number"
            },
            {
                field: "totalDue",
                headerName: "Due",
                flex: 1,
                type: "number"
            }
        ]
        setLoading(true)

        const promisesArray = []
        for (const programme of selectedProgrammeList) {
            promisesArray.push(await getStudentDueListForProgrammeId(programme.id))
        }

        Promise.all(promisesArray)
            .then(res => {
                const allData = res.flat()
                const rowsToShow = []

                for (const obj of allData) {
                    const { studentId, studentName, totalDue, totalPaid, totalFix, totalGrant, academicYear, auid, currentSem, categoryShortName } = obj
                    if (category !== "All") {
                        if (category === categoryShortName) {
                            rowsToShow.push({ studentId, studentName, totalDue, totalPaid, totalFix, totalGrant, academicYear, auid, currentSem, categoryShortName })
                        }
                    } else rowsToShow.push({ studentId, studentName, totalDue, totalPaid, totalFix, totalGrant, academicYear, auid, currentSem, categoryShortName })
                }

                setTableColumns(columnsToShow)
                setTableRows(rowsToShow)
                setLoading(false)
            })
    }

    const getStudentDueListForProgrammeId = async (programmeId) => {
        return new Promise(async resolve => {
            const res = await axios.get(`/api/student/getStudentDueListDetailsForProgramId?program_id=${programmeId}`)
            resolve(res.data.data)
        })
    }

    const getAllAdmissionCategory = async () => {
        const res = await axios.get("/api/student/FeeAdmissionCategory")

        const list = res.data.data.map(obj => {
            const { fee_admission_category_type, fee_admission_category_short_name } = obj
            return { label: fee_admission_category_type, value: fee_admission_category_short_name }
        })
        setCategoryList([{ label: "All", value: "All" }, ...list])
    }

    const handleProgrammeChange = (value) => {
        let uniqueValues = []
        if(value.length > 0)
            uniqueValues = [...new Map(value.map(item => [item["id"], item])).values()]

        let duplicateRemoved = [];
        uniqueValues.forEach((item) => {
            if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
            } else {
                duplicateRemoved.push(item);
            }
        });

        setSelectedProgrammeList(duplicateRemoved);
    }

    return (<>
        <Grid container alignItems="center" justifyContent="space-between" pt={3} rowGap={4}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ zIndex: 3 }}>
                <Grid container columnGap={1} rowGap={2} >
                    <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                        <FormControl size="medium" fullWidth>
                            <InputLabel>Academic Year</InputLabel>
                            <Select size="medium" name="AcademicYear" value={academicYear} label="Academic Year"
                                onChange={(e) => setAcademicYear(e.target.value)}>
                                {academicYearList.map((obj, index) => (
                                    <MenuItem key={index} value={obj.ac_year}>
                                        {obj.ac_year}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                        <FormControl size="medium" fullWidth>
                            <InputLabel>Programme</InputLabel>
                            <Select size="medium" multiple name="Programme"
                                value={selectedProgrammeList} label="Programme"
                                renderValue={(selected) => selected.map((x) => x.label).join(', ')}
                                onChange={e => handleProgrammeChange(e.target.value)}>
                                {programmeList.map((obj, index) => (
                                    <MenuItem key={index} value={obj}>
                                        <Checkbox
                                            checked={
                                                selectedProgrammeList.findIndex((item) => item.id === obj.id) >= 0
                                            }
                                        />
                                        <ListItemText primary={obj.label} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                        <FormControl size="medium" fullWidth>
                            <InputLabel>Category</InputLabel>
                            <Select size="medium" name="Category" value={category} label="Category"
                                onChange={(e) => setCategory(e.target.value)}>
                                {categoryList.map((obj, index) => (
                                    <MenuItem key={index} value={obj.value}>
                                        {obj.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <GridIndex loading={loading} rows={tableRows} columns={tableColumns} getRowId={row => row.studentId} />
                </Grid>
            </Grid>
        </Grid>
    </>)
}

export default StudentDueByProgramme