import { useState, useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
  Font,
} from "@react-pdf/renderer";
import axios from "../../../services/Api";
import { useParams } from "react-router-dom";
import WMlogo from "../../../assets/wmLogo.jpg";
import moment from "moment";
import useBreadcrumbs from "../../../hooks/useBreadcrumbs";

// Register the Arial font
Font.register({
  family: "Times New Roman",
  src: "/fonts/arial/ARIAL.woff",

  fonts: [
    {
      src: "/fonts/arial/ARIAL.woff",
    },
    {
      src: "/fonts/arial/ARIALBD.woff",
      fontWeight: "bold",
    },
    { src: "/fonts/arial/ARIALI.woff", fontStyle: "italic" },
  ],
});

const styles = StyleSheet.create({
  viewer: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  pageLayout: { margin: 25 },

  container: {
    width: "100%",
    height: "365px",
    border: "1px solid black",
    display: "flex",
    justifyContent: "center",
  },

  bodyContainer: {
    width: "100%",
    height: "230px",
    border: "0.5px solid #000",
    display: "flex",
    justifyContent: "center",
  },

  table: {
    border: "1px solid black",
    width: "540px",
    height: "auto",
    justifyContent: "center",
    alignItems: "center",
  },

  title: {
    fontSize: 14,
    fontFamily: "Times-Roman",
    marginTop: "-20px",
  },

  paymentVoucher: {
    fontSize: 12,
    fontFamily: "Times-Roman",
    marginLeft: "225px",
    marginBottom: "14px",
    fontWeight: 600,
  },

  address: {
    fontSize: 12,
    fontFamily: "Times-Roman",
    textAlign: "center",
  },

  tableRowStyle: {
    flexDirection: "row",
  },
  tableRowStyle1: {
    flexDirection: "row",
  },
  tableRowStyle2: {
    flexDirection: "column",
  },
  thStyle: {
    fontSize: "11px",
    fontWeight: "bold",
    width: "40%",
    fontFamily: "Times-Roman",
    color: "#000000",
  },
  thStyle1: {
    fontSize: "11px",
    fontWeight: "bold",
    width: "37%",
    fontFamily: "Times-Roman",
    color: "#000000",
  },

  tdStyle: {
    fontSize: "11px",
    fontWeight: "bold",
    fontFamily: "Times-Roman",
    color: "#000000",
    width: "170px",
    textTransform: "capitalize",
  },

  fcYearText: {
    textAlign: "center",
    alignItems: "center",
    marginTop: "-10px",
    marginBottom: "6px",
    width: "540px",
  },

  footer: {
    textAlign: "center",
    alignItems: "center",
    marginTop: "-40px",
    width: "540px",
  },

  footerData: {
    textAlign: "center",
    alignItems: "center",
    marginTop: "5px",
    width: "540px",
  },
  border: {
    border: "1px solid black",
    marginLeft: "100px",
    marginRight: "100px",
  },

  timetableStyle: {
    display: "table",
    width: "540px",
  },

  timeTableThHeaderStyleParticulars: {
    width: "80%",
    borderTop: "1px solid black",
    borderBottom: "1px solid black",
    borderRight: "1px solid black",
  },
  timeTableThHeaderStyle: {
    width: "20%",
    borderTop: "1px solid black",
    borderBottom: "1px solid black",
  },

  timeTableThStyle: {
    textAlign: "center",
    padding: "5px",
    fontFamily: "Times-Roman",
    fontSize: "12px",
  },

  timeTableTdHeaderStyle1Particulars: {
    width: "80%",
    borderRight: "0.4px solid #000",
  },

  timeTableTdHeaderStyle1: {
    width: "20%",
  },
  timeTableTdStyle: {
    textAlign: "left",
    padding: "5px",
    fontFamily: "Times-Roman",
    fontSize: "10px",
  },
  timeTableTdStyle1: {
    textAlign: "center",
    padding: "5px",
    fontFamily: "Times-Roman",
    fontSize: "10px",
  },
  studentLogo: {
    position: "sticky",
    width: "100px",
    height: "100px",
    marginTop: "70px",
    marginLeft: "250px",
    borderRadius: 5,
  },
});

function PaymentVoucherPdf() {
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [total, setTotal] = useState();
  const [userName, setUserName] = useState([]);

  const { voucherNo, schoolId, fcyearId } = useParams();
  const setCrumbs = useBreadcrumbs();

  useEffect(() => {
    getInternalTimetableData();
    setCrumbs([{ name: "Voucher Master", link: "/PaymentMaster" }]);
  }, []);

  const getInternalTimetableData = async () => {
    await axios
      .get(
        `/api/finance/getPaymentVoucherData/${voucherNo}/${schoolId}/${fcyearId}`
      )
      .then((res) => {
        setUserData(res.data.data[0]);
        setData(res.data.data);
        const sum = res.data.data.reduce((accumulator, object) => {
          return Number(accumulator) + Number(object.debit);
        }, 0);

        setTotal(sum);

        axios
          .get(
            `/api/fetchAllUserRoleDetails?page=${0}&page_size=${10000}&sort=created_date`
          )
          .then((Response) => {
            Response.data.data.Paginated_data.content.filter((obj) => {
              if (obj.user_id === Number(res.data.data[0].draft_approver_id)) {
                setUserName(obj.username);
              }
            });
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  };

  const timeTableTitle = () => {
    return (
      <>
        <View style={{ textAlign: "center" }}>
          <Text style={styles.title}>
            {userData.school_name ? userData.school_name : ""}
          </Text>
        </View>
        <View>
          <Text style={styles.address}>
            Khojalar neighborhood citizen council,bukhara street karakol
            district,Uzbekistan
          </Text>
        </View>
        <View>
          <Text style={styles.paymentVoucher}>Payment Voucher</Text>
        </View>
      </>
    );
  };

  const fcYearText = () => {
    return (
      <>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.thStyle}>Voucher No : {voucherNo}</Text>

          <Text style={styles.thStyle}>
            Financial Year :{" "}
            {userData.financial_year ? userData.financial_year : "NA"}
          </Text>
          <Text style={styles.thStyle}>
            Date :{" "}
            {userData.date ? moment(userData.date).format("DD-MM-YYYY") : "NA"}
          </Text>
        </View>
      </>
    );
  };

  const footer = () => {
    return (
      <>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.thStyle1}>Maker</Text>
          <Text style={styles.thStyle1}>Receiver </Text>
          <Text style={styles.thStyle1}>Checker</Text>
        </View>
      </>
    );
  };

  const footerData = () => {
    return (
      <>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.thStyle1}>
            {userData.draft_created_username
              ? userData.draft_created_username
              : "NA"}{" "}
            -{" "}
            {userData.created_date
              ? moment(userData.created_date).format("DD-MM-YYYY")
              : ""}
          </Text>
          <Text style={styles.thStyle1}> </Text>
          <Text style={styles.thStyle1}>
            {userName ? userName : "NA"} -{" "}
            {userData.draft_approved_date ? userData.draft_approved_date : ""}
          </Text>
        </View>
      </>
    );
  };

  const timeTableHeader = () => {
    return (
      <>
        <View style={styles.tableRowStyle} fixed>
          <View style={styles.timeTableThHeaderStyleParticulars}>
            <Text style={styles.timeTableThStyle}>Particulars</Text>
          </View>

          <View style={styles.timeTableThHeaderStyle}>
            <Text style={styles.timeTableThStyle}>Debit</Text>
          </View>
        </View>
      </>
    );
  };

  const timeTableBody = () => {
    return (
      <>
        <View style={{ flexDirection: "row" }}>
          <View
            style={{
              borderBottom: "1px solid black",
              width: "40%",
              height: "225px",
            }}
          >
            <View
              style={{
                height: "100px",
              }}
            >
              {data.length > 0 ? (
                data.map((obj, i) => {
                  return (
                    <View style={{ flexDirection: "column" }} key={i}>
                      <Text
                        style={{
                          textAlign: "left",
                          margin: 3.5,
                          fontFamily: "Times-Roman",
                          fontSize: "12px",
                        }}
                      >
                        {obj.voucher_head}
                      </Text>
                    </View>
                  );
                })
              ) : (
                <></>
              )}
            </View>

            <View>
              <Text
                style={{
                  textAlign: "left",
                  margin: 4,
                  fontFamily: "Times-Roman",
                  fontSize: "10px",
                }}
              >
                Paid To : {userData.pay_to ? userData.pay_to : "NA"}
              </Text>
              <Text
                style={{
                  textAlign: "left",

                  margin: 4,
                  fontFamily: "Times-Roman",
                  fontSize: "10px",
                }}
              >
                Paid Through : {userData.bank_name ? userData.bank_name : "NA"}
              </Text>
              <Text
                style={{
                  textAlign: "left",

                  margin: 4,
                  fontFamily: "Times-Roman",
                  fontSize: "10px",
                }}
              >
                PO NO : {userData.po_no ? userData.po_no : "NA"}
              </Text>
              <Text
                style={{
                  textAlign: "left",

                  margin: 4,
                  fontFamily: "Times-Roman",
                  fontSize: "10px",
                }}
              >
                Narration : {userData.remarks ? userData.remarks : "NA"}
              </Text>
              <Text
                style={{
                  textAlign: "left",
                  margin: 4,
                  fontFamily: "Times-Roman",
                  fontSize: "10px",
                  marginTop: 12,
                }}
              >
                {"        "}
                <br />
              </Text>
              <Text
                style={{
                  textAlign: "left",

                  margin: 4,
                  fontFamily: "Times-Roman",
                  fontSize: "12px",
                }}
              >
                Total
              </Text>
            </View>
          </View>
          <View
            style={{
              borderRight: "1px solid black",
              borderBottom: "1px solid black",
              width: "40%",
              height: "225px",
            }}
          >
            <View
              style={{
                width: "120px",
                color: "grey",
                marginLeft: "50px",
                marginTop: "20px",
                height: "300px",
              }}
            >
              <Image src={WMlogo}></Image>
            </View>
          </View>
          <View
            style={{
              borderBottom: "1px solid black",
              width: "20%",
              height: "225px",
            }}
          >
            <View
              style={{
                height: "100px",
              }}
            >
              {data.length > 0 ? (
                data.map((obj, i) => {
                  return (
                    <View style={{ flexDirection: "column" }} key={i}>
                      <Text
                        style={{
                          textAlign: "right",
                          margin: 3.5,
                          fontFamily: "Times-Roman",
                          fontSize: "10px",
                        }}
                      >
                        {Number(obj.debit).toFixed(2)}
                      </Text>
                    </View>
                  );
                })
              ) : (
                <></>
              )}
            </View>

            <View style={{ marginTop: "40px" }}>
              <Text
                style={{
                  textAlign: "right",
                  margin: 4,
                  fontFamily: "Times-Roman",
                  fontSize: "10px",
                }}
              >
                {" "}
              </Text>
              <Text
                style={{
                  textAlign: "right",
                  margin: 4,
                  fontFamily: "Times-Roman",
                  fontSize: "10px",
                }}
              >
                {" "}
              </Text>
              <Text
                style={{
                  textAlign: "right",
                  margin: 4,
                  fontFamily: "Times-Roman",
                  fontSize: "10px",
                }}
              >
                {" "}
              </Text>
              <Text
                style={{
                  textAlign: "right",
                  margin: 4,
                  fontFamily: "Times-Roman",
                  fontSize: "10px",
                }}
              >
                {" "}
              </Text>
              <Text
                style={{
                  textAlign: "right",
                  margin: 4,
                  fontFamily: "Times-Roman",
                  fontSize: "10px",
                }}
              >
                {" "}
              </Text>
              <Text
                style={{
                  textAlign: "right",
                  margin: 4,
                  fontFamily: "Times-Roman",
                  fontSize: "10px",
                }}
              >
                {Number(total).toFixed(2)}
              </Text>
            </View>
          </View>
        </View>
      </>
    );
  };

  return (
    <>
      <PDFViewer style={styles.viewer}>
        <Document title="Payment Voucher">
          <Page size="A4">
            <View style={styles.pageLayout}>
              <View style={styles.container}>
                <View style={styles.title}>{timeTableTitle()}</View>
                <View style={styles.fcYearText}>{fcYearText()}</View>
                <View>{timeTableHeader()}</View>
                <View>{timeTableBody()}</View>
              </View>
              <View style={styles.footer}>{footer()}</View>
              <View style={styles.footerData}>{footerData()}</View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </>
  );
}

export default PaymentVoucherPdf;
