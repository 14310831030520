import { useState, useEffect } from "react";
import { Tabs, Tab } from "@mui/material";
import GRNIndex from "../../components/GRNIndex";
import StockRegister from "../../components/StockRegister";
import StockIssueIndex from "../../components/StockIssueIndex";
import useBreadcrumbs from "../../hooks/useBreadcrumbs";
import { useNavigate, useLocation } from "react-router-dom";
import LibraryBookIssueIndex from "../../containers/indeces/inventoryMaster/LibraryBookIssueIndex";

function ItemMaster() {
  const [tab, setTab] = useState("GRN");
  const setCrumbs = useBreadcrumbs();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => setCrumbs([{ name: "Item Master" }]));

  useEffect(() => {
    if (pathname.toLowerCase().includes("/grn")) setTab("GRN");
    else if (pathname.toLowerCase().includes("/stockissue"))
      setTab("StockIssue");
    else if (pathname.toLowerCase().includes("/stockregister"))
      setTab("StockRegister");
    else if (pathname.toLowerCase().includes("/library")) setTab("Library");
  }, [pathname]);

  const handleChange = (e, newValue) => {
    navigate("/ItemMaster/" + newValue);
  };

  return (
    <>
      <Tabs value={tab} onChange={handleChange}>
        <Tab value="GRN" label="GRN" />
        <Tab value="Library" label="Library Book" />
        <Tab value="StockIssue" label="Stock Issue" />
        <Tab value="StockRegister" label="Stock Register" />
      </Tabs>
      {tab === "GRN" && <GRNIndex />}
      {tab === "Library" && <LibraryBookIssueIndex />}
      {tab === "StockIssue" && <StockIssueIndex />}
      {tab === "StockRegister" && <StockRegister />}
    </>
  );
}

export default ItemMaster;
