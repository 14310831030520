import Axios from "axios";

// Local
// const apiUrl = `http://192.168.0.182:8081`;

// Staging backend
// const apiUrl = `https://www.stageapi-acharyainstitutes.in/AcharyaInstituteUZB`;

// Live
const apiUrl = `https://api-prod-acharyainstitutes.in/AcharyaInstituteUZB`;

const axios = Axios.create({
  baseURL: apiUrl,
});

export default axios;
