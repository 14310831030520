import { useState, useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";
import axios from "../../../services/Api";
import { useParams } from "react-router-dom";
import moment from "moment";
import useBreadcrumbs from "../../../hooks/useBreadcrumbs";

// Register the Arial font
Font.register({
  family: "Times New Roman",
  src: "/fonts/arial/ARIAL.woff",

  fonts: [
    {
      src: "/fonts/arial/ARIAL.woff",
    },
    {
      src: "/fonts/arial/ARIALBD.woff",
      fontWeight: "bold",
    },
    { src: "/fonts/arial/ARIALI.woff", fontStyle: "italic" },
  ],
});

const styles = StyleSheet.create({
  viewer: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  pageLayout: { margin: 25 },

  container: {
    width: "100%",
    border: "1px solid black",
    display: "flex",
    justifyContent: "center",
  },

  title: {
    fontSize: 14,
    fontFamily: "Times-Roman",
    textAlign: "center",
  },

  address: {
    fontSize: 12,
    fontFamily: "Times-Roman",
    textAlign: "center",
  },

  //   thStyle: {
  //     marginLeft: "25px",
  //     fontSize: "11px",
  //     fontWeight: "bold",
  //     width: "100%",
  //     fontFamily: "Times-Roman",
  //     color: "#000000",
  //   },

  containerOne: {
    width: "100%",
    height: "auto",
    border: "1px solid black",
    display: "flex",
    justifyContent: "center",
  },

  vendor: {
    fontSize: "10px",
    fontWeight: "bold",
    padding: "1px",
    fontFamily: "Times-Roman",
    color: "#000000",
    marginLeft: "10px",
  },

  tableRowStyle: {
    flexDirection: "row",
  },

  Total: {
    fontSize: 12,
    fontFamily: "Times-Roman",
    width: "82.7%",
    borderRight: "1px solid black",
  },

  Amount: {
    fontSize: "10px",
    fontFamily: "Times-Roman",
    width: "16%",
    textAlign: "right",
  },

  timeTableThHeaderStyle: {
    width: "10%",
    borderTop: "1px solid black",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
    color: "black",
  },

  iatHeader: {
    width: "14.5%",
    borderTop: "1px solid black",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
    color: "black",
  },

  percentagemarksHeader: {
    width: "7.3%",
    borderTop: "1px solid black",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
    color: "black",
  },

  seriolNoHeader: {
    width: "7%",
    borderTop: "1px solid black",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
    color: "black",
  },

  auid: {
    width: "20%",
    borderTop: "1px solid black",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
    color: "black",
  },

  itemNameHeader: {
    width: "50%",
    borderTop: "1px solid black",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
    color: "black",
  },

  yearsem: {
    width: "12%",
    borderTop: "1px solid black",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
    color: "black",
  },

  sectionHeader: {
    width: "18%",
    borderTop: "1px solid black",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
    color: "black",
  },

  quantityHeader: {
    width: "15%",
    borderTop: "1px solid black",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
    color: "black",
  },

  courseHeader: {
    width: "34%",
    borderTop: "1px solid black",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
    color: "black",
  },

  timeTableThStyle: {
    textAlign: "center",
    padding: "5px",
    fontFamily: "Times-Roman",
    fontSize: "10px",
    fontWeight: "bold",
  },

  timeTableThStyleAmount: {
    textAlign: "center",
    padding: "5px",
    fontFamily: "Times-Roman",
    fontSize: "10px",
    fontWeight: "bold",
    textAlign: "right",
  },

  timeTableTdHeaderStyle1: {
    width: "20%",
    borderRight: "0.5px solid black",
    borderBottom: "1px solid black",
    height: "25px",
  },

  seriolNo: {
    width: "7%",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
    height: "25px",
  },

  quantity: {
    width: "15%",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
    height: "25px",
  },

  itemName: {
    width: "50%",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
    height: "25px",
  },

  timeTableTdStyle: {
    textAlign: "center",
    padding: "5px",
    fontFamily: "Times-Roman",
    fontSize: "10px",
  },

  timeTableTdStyleAmount: {
    textAlign: "center",
    padding: "5px",
    fontFamily: "Times-Roman",
    fontSize: "10px",
    textAlign: "right",
  },

  amount: {
    width: "20%",
    borderRight: "0.2px solid black",
    borderBottom: "1px solid black",
    height: "25px",
  },
  amountHeader: {
    width: "20%",
    borderTop: "1px solid black",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
    color: "black",
  },
});

function StudentAssessmentPdf() {
  const [mainData, setMainData] = useState([]);
  const [iat, setIat] = useState([]);

  const {
    courseName,
    acYearId,
    programAssignmentId,
    programId,
    specializationId,
    sectionId,
    yearsemId,
    courseId,
    courseAssignmentId,
  } = useParams();
  const setCrumbs = useBreadcrumbs();

  useEffect(() => {
    setCrumbs([{ name: "Student Assesment", link: "/StudentAssessment" }]);
    getPdfData();
  }, []);

  const getPdfData = async () => {
    await axios
      .get(
        `/api/student/getStudentAttendanceReportBasedOnCommencement/${acYearId}/${programAssignmentId}/${programId}/${specializationId}/${sectionId}/${yearsemId}/${courseId}/${courseAssignmentId}`
      )
      .then((res) => {
        setIat(Object.keys(res.data.data[0].studentAttendanceDetail));
        setMainData(res.data.data);
      })
      .catch((err) => console.error(err));
  };

  const timeTableTitle = () => {
    return (
      <>
        <View>
          <Text style={styles.title}>ACHARYA UNIVERSITY</Text>
        </View>
        <View>
          <Text style={styles.address}>
            Khojalar neighborhood citizen council,bukhara street karakol
            district,Uzbekistan
          </Text>
        </View>
      </>
    );
  };

  const timeTableHeader = () => {
    return (
      <>
        <View style={styles.tableRowStyle} fixed>
          <View style={styles.seriolNoHeader}>
            <Text style={styles.timeTableThStyle}></Text>
          </View>

          <View style={styles.sectionHeader}>
            <Text style={styles.timeTableThStyle}>Section - A4</Text>
          </View>

          <View style={styles.courseHeader}>
            <Text style={styles.timeTableThStyle}>
              Course Name - {courseName}
            </Text>
          </View>

          <View style={styles.yearsem}>
            <Text style={styles.timeTableThStyle}>Year/Sem - 1</Text>
          </View>

          {iat.map((obj) => {
            return (
              <View style={styles.iatHeader} colSpan={2}>
                <Text style={styles.timeTableThStyle}>{obj}</Text>
              </View>
            );
          })}
        </View>
        <View style={styles.tableRowStyle} fixed>
          <View style={styles.seriolNoHeader}>
            <Text style={styles.timeTableThStyle}>Sl No.</Text>
          </View>
          <View style={styles.sectionHeader}>
            <Text style={styles.timeTableThStyle}>AUID</Text>
          </View>
          <View style={styles.courseHeader}>
            <Text style={styles.timeTableThStyle}>Student Name</Text>
          </View>
          <View style={styles.yearsem}>
            <Text style={styles.timeTableThStyle}>DOR</Text>
          </View>
          {iat.map((obj) => {
            return ["%", "Marks"].map((obj1) => {
              return (
                <View style={styles.percentagemarksHeader}>
                  <Text style={styles.timeTableThStyle}>{obj1}</Text>
                </View>
              );
            });
          })}
        </View>
      </>
    );
  };

  const timeTableBody = () => {
    return (
      <>
        {mainData?.map((row, i) => {
          return (
            <View style={styles.tableRowStyle}>
              <View style={styles.seriolNo}>
                <Text style={styles.timeTableTdStyle}>{i + 1}</Text>
              </View>
              <View style={styles.sectionHeader}>
                <Text style={styles.timeTableTdStyle}>{row.auid}</Text>
              </View>
              <View style={styles.courseHeader}>
                <Text style={styles.timeTableTdStyle}>{row.studentName}</Text>
              </View>
              {row.dateOfReporting ? (
                <View style={styles.yearsem}>
                  <Text style={styles.timeTableTdStyle}>
                    {moment(row.dateOfReporting).format("DD-MM-YYYY")}
                  </Text>
                </View>
              ) : (
                <View style={styles.yearsem}>
                  <Text style={styles.timeTableTdStyle}></Text>
                </View>
              )}

              {iat.map((obj1) => {
                return (
                  <>
                    <View style={styles.percentagemarksHeader}>
                      <Text style={styles.timeTableTdStyleAmount}>
                        {
                          row.studentAttendanceDetail[obj1]
                            ?.studentAttendanceDetailOfCourse[0]?.percentage
                        }
                      </Text>
                    </View>
                    <View style={styles.percentagemarksHeader}>
                      <Text style={styles.timeTableTdStyleAmount}>
                        {
                          row.studentAttendanceDetail[obj1]?.studentMarks[0]
                            ?.marks_obtained_internal
                        }
                      </Text>
                    </View>
                  </>
                );
              })}
            </View>
          );
        })}
      </>
    );
  };

  return (
    <>
      <PDFViewer style={styles.viewer}>
        <Document title="Student Assessment">
          <Page size="A4" orientation="landscape">
            <View style={styles.pageLayout}>
              <View style={styles.container}>
                <View style={styles.title}>{timeTableTitle()}</View>

                <View style={{ marginTop: "10px" }}>{timeTableHeader()}</View>
                <View>{timeTableBody()}</View>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </>
  );
}

export default StudentAssessmentPdf;
