import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  styled,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import logo from "../../../assets/blankprofile.png";
import GridIndex from "../../../components/GridIndex";
import CustomAutocomplete from "../../../components/Inputs/CustomAutocomplete";
import axios from "../../../services/Api";
import moment from "moment";
import useBreadcrumbs from "../../../hooks/useBreadcrumbs";
import useAlert from "../../../hooks/useAlert";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import CustomModal from "../../../components/CustomModal";

const CustomTabsHorizontal = styled(Tabs)({
  "& .MuiTabs-flexContainer": {
    flexDirection: "row",
    marginLeft: "10px",
    marginTop: "18px",
  },
});

const userId = JSON.parse(localStorage.getItem("empId"));

const CustomTabHorizontal = styled(Tab)(({ theme }) => ({
  height: "40px",
  fontSize: "14px",
  width: "220px",
  borderRadius: 4,
  transition: "background-color 0.3s",
  backgroundColor: "rgba(74, 87, 169, 0.1)",
  color: "#46464E",
  "&.Mui-selected": {
    backgroundColor: theme.palette.auzColor.main,
    color: theme.palette.headerWhite.main,
  },
  "&:hover": {
    backgroundColor: "rgba(74, 87, 169, 0.2)",
  },
}));

const initialValues = {
  auid: "",
  libraryId: "",
};

function StudentLibraryDetails() {
  const [values, setValues] = useState(initialValues);
  const [tab, setTab] = useState("");
  const [rows, setRows] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [open, setOpen] = useState(false);
  const [libraryOptions, setLibraryOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [studentAuid, setStudentAuid] = useState();
  const [userType, setUserType] = useState();
  const [libraryBookName, setLibrayBookName] = useState();
  const [libraryAssignmentId, setLibraryAssignmentId] = useState(null);
  const [modalContent, setModalContent] = useState({
    title: "",
    message: "",
    buttons: [],
  });
  const [modalOpen, setModalOpen] = useState(false);

  const setCrumbs = useBreadcrumbs();
  const { setAlertMessage, setAlertOpen } = useAlert();

  useEffect(() => {
    getLibraryBooks();
    getUsersLists();
    setCrumbs([{ name: "Library Book Issue" }]);
  }, []);

  useEffect(() => {
    getData();
  }, [studentAuid]);

  useEffect(() => {
    getIndexData();
  }, [values.auid]);

  const columns = [
    { headerName: "Book", flex: 1, field: "bookName" },
    { headerName: "Accession No.", flex: 1, field: "accessionNumber" },
    {
      headerName: "Check In",
      flex: 1,
      field: "checkIn",
      renderCell: (params) => [
        <IconButton color="primary" onClick={() => handleCheckIn(params)}>
          <ControlPointIcon fontSize="small" />
        </IconButton>,
      ],
    },
    {
      headerName: "Check Out",
      flex: 1,
      field: "checkOutTime",
      valueGetter: (params) =>
        moment(params.row.checkOutTime).format("DD-MM-YYYY HH:MM:SS"),
    },
    {
      headerName: "Return Date",
      flex: 1,
      field: "checkInTime",
      valueGetter: (params) =>
        moment(params.row.checkInTime).format("DD-MM-YYYY HH:MM:SS"),
    },
    {
      headerName: "Issued Date",
      flex: 1,
      field: "Issued Date",
      valueGetter: (params) =>
        moment(params.row.checkOutTime).format("DD-MM-YYYY"),
    },
    { headerName: "Issued By", flex: 1, field: "issuedBy" },
  ];

  const handleCheckIn = (params) => {
    setModalOpen(true);
    const handleToggle = async () => {
      const temp = {};
      temp.issuerId = params.row.issuerId;
      temp.accessionNumber = params.row.accessionNumber;
      temp.libraryAssignmentId = params.row.id;

      await axios
        .post(`/api/libraryInv/libraryBookIssueCheckIn`, temp)
        .then((res) => {
          if (res.status === 200) {
            setAlertMessage({ severity: "success", message: "Successfull" });
            setAlertOpen(true);
            setModalOpen(false);
          }
        })
        .catch((err) => {
          setAlertMessage({
            severity: "error",
            message: err.response.data.message,
          });
          setAlertOpen(true);
        });
    };

    setModalContent({
      title: "",
      message: "Are you sure you want to check-in this book ?",
      buttons: [
        { name: "Yes", color: "primary", func: handleToggle },
        { name: "No", color: "primary", func: () => {} },
      ],
    });
  };

  const getLibraryBooks = async () => {
    await axios
      .get(`/api/libraryInv/getAllLibraryBooksWithAccessionNumber`)
      .then((res) => {
        setLibraryOptions(
          res.data.data.map((obj) => ({
            label: obj.bookName + "-" + obj.accessionNumber,
            value: obj.accessionNumber,
            libraryAssigmentId: obj.libraryAssigmentId,
          }))
        );
      })
      .catch((err) => console.error(err));
  };

  const getUsersLists = async () => {
    await axios
      .get(`/api/getAllUserListForLibrary`)
      .then((res) => {
        setUserOptions(
          res.data.data.map((obj) => ({
            label: obj.userName,
            value: obj.userId,
          }))
        );
      })
      .catch((err) => console.error(err));
  };

  const getData = async () => {
    await axios
      .get(
        `/api/getUserDetailsForLibrary?userCode=${studentAuid}&userType=${userType}`
      )
      .then((res) => {
        if (res.data.data !== null) {
          setStudentData(res.data.data);
          setOpen(true);
        } else {
          setOpen(false);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const getIndexData = async () => {
    await axios
      .get(`/api/libraryInv/getAllLibraryBooksIssue/?issuerId=${values.auid}`)
      .then((res) => {
        const rowId = res.data.data.map((obj, index) => ({
          ...obj,
          id: index + 1,
        }));
        setRows(rowId);
      })
      .catch((err) => console.error(err));
  };

  const handleChangeAdvance = async (name, newValue) => {
    if (name === "auid") {
      await axios
        .get(`/api/getAllUserListForLibrary`)
        .then((res) => {
          res.data.data.forEach((obj) => {
            if (obj.userId === newValue) {
              setStudentAuid(obj.userCode);
              setUserType(obj.userType);
            }
          });
        })
        .catch((err) => console.error(err));
    } else {
      await axios
        .get(`/api/libraryInv/getAllLibraryBooksWithAccessionNumber`)
        .then((res) => {
          res.data.data.filter((obj) => {
            if (obj.accessionNumber === newValue) {
              setLibrayBookName(obj.bookName);
              setLibraryAssignmentId(obj.libraryAssigmentId);
            }
          });
        })
        .catch((err) => console.error(err));
    }

    setValues((prev) => ({
      ...prev,
      [name]: newValue,
    }));
  };

  const handleIssue = async () => {
    const temp = [];

    const tempOne = {};

    tempOne.libraryItemName = libraryBookName;
    tempOne.createdBy = userId;
    tempOne.issuerId = values.auid;
    tempOne.accessionNumber = values.libraryId;
    tempOne.bookName = libraryBookName;
    tempOne.libraryAssignmentId = libraryAssignmentId;
    tempOne.userCode = studentAuid;

    temp.push(tempOne);

    await axios
      .post(`/api/libraryInv/libraryBookIssue`, temp)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setAlertOpen(true);
          setAlertMessage({
            severity: "success",
            message: "Book Issued Successfully",
          });
          setValues((prev) => ({ ...prev, "libraryId": "" }));
          getIndexData();
        }
      })
      .catch((err) => {
        setAlertMessage({
          severity: "error",
          message: err.response.data
            ? err.response.data.message
            : "Error submitting",
        });
        setAlertOpen(true);
        console.error(err);
      });
  };

  return (
    <>
      <Box component="form" m={2}>
        <CustomModal
          open={modalOpen}
          setOpen={setModalOpen}
          title={modalContent.title}
          message={modalContent.message}
          buttons={modalContent.buttons}
        />

        <Grid container>
          <Grid item xs={12} md={3}>
            <CustomAutocomplete
              name="auid"
              value={values.auid}
              handleChangeAdvance={handleChangeAdvance}
              options={userOptions}
              label="Users"
            />
          </Grid>
        </Grid>

        <Grid container justifyContent="left">
          {open && userType === "Student" ? (
            <Grid
              item
              xs={12}
              md={3}
              component={Paper}
              elevation={4}
              p={2}
              sx={{
                borderRadius: 2,
                backgroundColor: "rgba(74, 87, 169, 0.1)",
              }}
              mt={2}
            >
              <Grid container rowSpacing={1}>
                <Grid item xs={12} align="center">
                  <img src={logo} width={100} height={100} />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography variant="subtitle2">Name </Typography>
                </Grid>
                <Grid item xs={12} md={1}>
                  <Typography variant="subtitle2">:</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography variant="subtitle2">
                    {studentData?.name}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Typography variant="subtitle2">AUID </Typography>
                </Grid>
                <Grid item xs={12} md={1}>
                  <Typography variant="subtitle2">:</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography variant="subtitle2">
                    {studentData?.auid}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Typography variant="subtitle2">Email </Typography>
                </Grid>
                <Grid item xs={12} md={1}>
                  <Typography variant="subtitle2">:</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography
                    variant="subtitle2"
                    sx={{ wordBreak: "break-all" }}
                  >
                    {studentData?.email}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Typography variant="subtitle2">DOR</Typography>
                </Grid>
                <Grid item xs={12} md={1}>
                  <Typography variant="subtitle2">:</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography variant="subtitle2">
                    {moment(studentData?.reportingDate).format("DD-MM-YYYY")}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Typography variant="subtitle2">Phone No. </Typography>
                </Grid>
                <Grid item xs={12} md={1}>
                  <Typography variant="subtitle2">:</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography variant="subtitle2">
                    {studentData?.mobile}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Typography variant="subtitle2">Year/Sem</Typography>
                </Grid>
                <Grid item xs={12} md={1}>
                  <Typography variant="subtitle2">:</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography variant="subtitle2">
                    {studentData?.currentYear}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Typography variant="subtitle2">Program</Typography>
                </Grid>
                <Grid item xs={12} md={1}>
                  <Typography variant="subtitle2">:</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography variant="subtitle2">
                    {studentData?.program}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Typography variant="subtitle2">Address</Typography>
                </Grid>
                <Grid item xs={12} md={1}>
                  <Typography variant="subtitle2">:</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography variant="subtitle2">
                    {studentData?.address}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <>
              {open && userType.toLowerCase() === "staff" ? (
                <Grid
                  item
                  xs={12}
                  md={3}
                  component={Paper}
                  elevation={4}
                  p={2}
                  sx={{
                    borderRadius: 2,
                    backgroundColor: "rgba(74, 87, 169, 0.1)",
                    height: "50%",
                  }}
                  mt={2}
                >
                  <Grid container rowSpacing={1}>
                    <Grid item xs={12} align="center">
                      <img src={logo} width={100} height={100} />
                    </Grid>
                    <Grid item xs={12} md={3.5}>
                      <Typography variant="subtitle2">Name</Typography>
                    </Grid>
                    <Grid item xs={12} md={1}>
                      <Typography variant="subtitle2">:</Typography>
                    </Grid>
                    <Grid item xs={12} md={7.5}>
                      <Typography variant="subtitle2">
                        {studentData?.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={3.5}>
                      <Typography variant="subtitle2">Designation</Typography>
                    </Grid>
                    <Grid item xs={12} md={1}>
                      <Typography variant="subtitle2">:</Typography>
                    </Grid>
                    <Grid item xs={12} md={7.5}>
                      <Typography variant="subtitle2">
                        {studentData?.designation}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={3.5}>
                      <Typography variant="subtitle2">Department</Typography>
                    </Grid>
                    <Grid item xs={12} md={1}>
                      <Typography variant="subtitle2">:</Typography>
                    </Grid>
                    <Grid item xs={12} md={7.5}>
                      <Typography variant="subtitle2">
                        {studentData?.department}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={3.5}>
                      <Typography variant="subtitle2">Email</Typography>
                    </Grid>
                    <Grid item xs={12} md={1}>
                      <Typography variant="subtitle2">:</Typography>
                    </Grid>
                    <Grid item xs={12} md={7.5}>
                      <Typography
                        variant="subtitle2"
                        sx={{ wordBreak: "break-all" }}
                      >
                        {studentData?.email}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={3.5}>
                      <Typography variant="subtitle2">DOJ</Typography>
                    </Grid>
                    <Grid item xs={12} md={1}>
                      <Typography variant="subtitle2">:</Typography>
                    </Grid>
                    <Grid item xs={12} md={7.5}>
                      <Typography variant="subtitle2">
                        {moment(studentData?.doj).format("DD-MM-YYYY")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={3.5}>
                      <Typography variant="subtitle2">Mobile</Typography>
                    </Grid>
                    <Grid item xs={12} md={1}>
                      <Typography variant="subtitle2">:</Typography>
                    </Grid>
                    <Grid item xs={12} md={7.5}>
                      <Typography variant="subtitle2">
                        {studentData?.mobile}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
            </>
          )}

          <Grid item xs={12} md={9}>
            <Grid
              container
              rowSpacing={1}
              justifyContent="flex-start"
              alignItems="center"
            >
              {open ? (
                <Grid item xs={12}>
                  <CustomTabsHorizontal
                    value={tab}
                    onChange={handleTabChange}
                    orientation="horizontal"
                    variant="scrollable"
                    className="CustomTabsHorizontal"
                  >
                    <CustomTabHorizontal value="Checkout" label="Check Outs" />
                    <CustomTabHorizontal value="Admissions" label="Holds" />
                    <CustomTabHorizontal value="Bookissue" label="Book Issue" />
                    <CustomTabHorizontal
                      value="Proctorial"
                      label="Restrictions"
                    />
                  </CustomTabsHorizontal>
                </Grid>
              ) : (
                <></>
              )}

              {tab === "Checkout" ? (
                <>
                  <Grid item xs={11.5} ml={2}>
                    <GridIndex rows={rows} columns={columns} />
                  </Grid>
                </>
              ) : (
                ""
              )}

              {tab === "Bookissue" ? (
                <>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    rowSpacing={2}
                    columnSpacing={2}
                    mt={2}
                    ml={2}
                  >
                    <Grid item xs={12} md={4}>
                      <CustomAutocomplete
                        name="libraryId"
                        label="Book"
                        value={values.libraryId}
                        options={libraryOptions}
                        handleChangeAdvance={handleChangeAdvance}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Button
                        variant="contained"
                        sx={{ borderRadius: 2 }}
                        onClick={handleIssue}
                      >
                        Issue
                      </Button>
                    </Grid>
                  </Grid>
                </>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
export default StudentLibraryDetails;
