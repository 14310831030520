import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Grid,
  Button,
  Collapse,
  CircularProgress,
  Typography,
  Checkbox,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  styled,
  tableCellClasses,
} from "@mui/material";
import CustomTextField from "../../../components/Inputs/CustomTextField";
import axios from "../../../services/Api";
import useAlert from "../../../hooks/useAlert";
import { makeStyles } from "@mui/styles";
import FormPaperWrapper from "../../../components/FormPaperWrapper";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";

const label = { inputprops: { "aria-label": "Checkbox demo" } };

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.auzColor.main,
    color: theme.palette.headerWhite.main,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const initialValues = {
  auid: "",
  receivedIn: "",
  transactionType: "",
  receivedAmount: "",
  transactionAmount: "",
  narration: "",
  ddChequeNo: "",
  ddAmount: "",
  bankName: "",
  ddDate: null,
  bankImportedId: "",
  transactionDate: null,
  transactionNo: "",
  bankId: null,
  payingAmount: "",
};

const requiredFields = [];

const useStyles = makeStyles((theme) => ({
  bg: {
    backgroundColor: theme.palette.auzColor.main,
    color: theme.palette.headerWhite.main,
    textAlign: "center",
    padding: "5px",
    borderRadius: "2px",
  },
  yearSem: {
    color: theme.palette.error.main,
    border: "1px solid rgba(0, 0, 0, 1)",
    padding: "2px",
    borderRadius: "2px",
  },

  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      border: "1px solid rgba(224, 224, 224, 1)",
    },
  },
}));

function FeeTransfer() {
  const [values, setValues] = useState(initialValues);

  const [studentData, setStudentData] = useState([]);
  const [open, setOpen] = useState(false);
  const [noOfYears, setNoOfYears] = useState([]);
  const [loading, setLoading] = useState(false);
  const [feetemplateId, setFeetemplateId] = useState();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [studentId, setStudentId] = useState();

  const [receiptDetails, setReceiptDetails] = useState([]);

  const [oldStudentId, setOldStudentId] = useState(null);

  const [display, setDisplay] = useState([]);
  const [voucherHeadIds, setVoucherHeadIds] = useState([]);
  const [showData, setShowData] = useState();
  const [receiptData, setReceiptData] = useState([]);

  const { setAlertMessage, setAlertOpen } = useAlert();
  const classes = useStyles();
  const { r, auid } = useParams();

  useEffect(() => {
    if (data.postData !== undefined) {
      let temp = 0;
      Object.values(data.postData).forEach((obj) => {
        const val = Object.values(obj).reduce((a, b) => {
          const x = Number(a) > 0 ? Number(a) : 0;
          const y = Number(b) > 0 ? Number(b) : 0;
          return x + y;
        });
        temp += Number(val);
      });
      setTotal(temp);
    }
  }, [data.postData]);

  useEffect(() => {
    const temp = [];
    receiptData.forEach((obj) => {
      if (obj.isChecked === true) {
        temp.push(obj);
      }
    });

    const sumone = temp.reduce((a, b) => {
      return a + b.paid_amount;
    }, 0);
    setValues((prev) => ({ ...prev, "receivedAmount": sumone }));
  }, [receiptData]);

  useEffect(() => {
    getStudentData();
  }, [auid]);

  const getStudentData = async () => {
    await axios
      .get(`/api/student/studentDetailsByAuid/${auid}`)
      .then(async (res) => {
        if (res.data.data.length > 0) {
          setOldStudentId(res.data.data[0].old_student_id);
          setFeetemplateId(res.data.data[0].fee_template_id);
          setStudentId(res.data.data[0].student_id);
          const years = [];
          const yearsValue = {};
          const showTable = {};
          setStudentData(res.data.data[0]);
          if (res.data.data[0].program_type_name.toLowerCase() === "yearly") {
            for (let i = 1; i <= 2; i++) {
              years.push({ key: i, label: "Year" + i });
              yearsValue["year" + i] = 0;
              showTable[i] = true;
            }
          } else if (
            res.data.data[0].program_type_name.toLowerCase() === "semester"
          ) {
            for (let i = 1; i <= 2; i++) {
              years.push({ key: i, label: "Sem" + i });
              yearsValue["sem" + i] = 0;
              showTable[i] = true;
            }
          }
          setNoOfYears(years);
          setOpen(true);
          setShowData(showTable);

          await axios
            .get(
              `/api/finance/feeReceiptByStudenIdForReceiptTransfer/${res.data.data[0].old_student_id}`
            )
            .then((res) => {
              const status = res.data.data.map((obj) => ({
                ...obj,
                submittedStatus: false,
              }));
              setReceiptData(status);
            })
            .catch((err) => console.error(err));

          await axios
            .get(
              `/api/finance/dueAmountCalculationOnVocherHeadWiseAndYearWiseForFeeReceipt/${res.data.data[0].student_id}`
            )
            .then((res) => {
              const Ids = [];
              res.data.data.fee_template_sub_amount_info.forEach((obj) => {
                Ids.push({
                  id: obj.voucher_head_new_id,
                  label: obj.voucher_head,
                });
              });

              const temp = {};
              const mainData = {};
              setVoucherHeadIds(Ids);
              setDisplay(res.data.data);
              years.forEach((val) => {
                Ids.forEach((obj) => {
                  temp[obj.id] = 0;
                  mainData[val.key] = temp;
                });

                setData((prev) => ({
                  ...prev,
                  "postData": mainData,
                }));
              });
            })
            .catch((err) => console.error(err));
        } else {
          setOpen(false);
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (values.receivedAmount && total > values.receivedAmount) {
      setAlertMessage({
        severity: "error",
        message: "Total amount cannot be greater than received amount",
      });
      setAlertOpen(true);
    } else {
      setAlertOpen(false);
    }
  }, [total, values.receivedAmount]);

  useEffect(() => {
    noOfYears.forEach((obj) => {
      voucherHeadIds.forEach((obj1) => {
        if (
          data.postData[obj.key][obj1.id] >
          display.fee_template_sub_amount_format[obj.key][obj1.id]
        ) {
          setAlertMessage({ severity: "error", message: "test" });
          setAlertOpen(true);
        }
      });
    });
  }, [noOfYears, voucherHeadIds, data.postData, display]);

  useEffect(() => {
    getDetailsofReceipt();
  }, [values.bankImportedId]);

  const checks = {};

  const getDetailsofReceipt = async () => {
    if (values.bankImportedId)
      await axios
        .get(`/api/finance/allRTGSFeeHistoryDetails/${values.bankImportedId}`)
        .then((res) => {
          setReceiptDetails(res.data.data);
        })
        .catch((err) => console.error(err));
  };

  const handleChangeCheckbox = (e) => {
    const { name, checked } = e.target;

    if (name === "selectAll" && checked === true) {
      let tempUser = receiptData.map((test) => {
        return { ...test, isChecked: checked };
      });
      setReceiptData(tempUser);

      setValues({
        ...values,
        feeReceipt: receiptData.map((obj) => obj.fee_receipt).toString(),
      });
    } else if (name === "selectAll" && checked === false) {
      let tempUser = receiptData.map((test) => {
        return { ...test, isChecked: checked };
      });
      setReceiptData(tempUser);

      setValues({
        ...values,
        transferAmount: [],
      });
    } else if (name !== "selectAll" && checked === true) {
      const tempData = receiptData.map((obj) => {
        if (obj.fee_receipt === e.target.name) {
          return { ...obj, isChecked: true };
        }

        if (obj.fee_receipt !== e.target.name) {
          return { ...obj, submittedStatus: true };
        }
        return obj;
      });

      setReceiptData(tempData);
      const newTemp = [];
      tempData.forEach((obj) => {
        if (obj.isChecked === true) {
          newTemp.push(obj.fee_receipt);
        }
      });
      setValues({
        ...values,
        feeReceipt: newTemp.toString(),
      });
    } else if (name !== "selectAll" && checked === false) {
      const tempData = receiptData.map((obj) => {
        if (obj.fee_receipt === e.target.name) {
          return { ...obj, isChecked: false };
        }

        if (obj.fee_receipt !== e.target.name) {
          return { ...obj, submittedStatus: false };
        }
        return obj;
      });

      setReceiptData(tempData);

      const existData = [];

      values.feeReceipt.split(",").forEach((obj) => {
        existData.push(obj);
      });

      const index = existData.indexOf(e.target.value);

      if (index > -1) {
        existData.splice(index, 1);
      }

      setValues({
        ...values,
        feeReceipt: existData.toString(),
      });
    }
  };

  const handleChange = async (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleClick = (name) => {
    setShowData((prev) => ({
      ...prev,
      [name]: showData[name] === true ? false : true,
    }));
  };

  const handleChangeOne = (e) => {
    const splitName = e.target.name.split("-");
    setData((prev) => ({
      ...prev,
      "postData": {
        ...prev.postData,
        [splitName[1]]: {
          ...prev.postData[splitName[1]],
          [splitName[0]]:
            Number(e.target.value) >
            display.dueAmount[splitName[1]][splitName[0]]
              ? display.dueAmount[splitName[1]][splitName[0]]
              : e.target.value,
        },
      },
    }));
  };

  const requiredFieldsValid = () => {
    for (let i = 0; i < requiredFields.length; i++) {
      const field = requiredFields[i];
      if (Object.keys(checks).includes(field)) {
        const ch = checks[field];
        for (let j = 0; j < ch.length; j++) if (!ch[j]) return false;
      } else if (!values[field]) return false;
    }
    return true;
  };

  const handleCreate = async () => {
    if (!requiredFieldsValid()) {
      setAlertMessage({
        severity: "error",
        message: "Please fill all required fields",
      });
      setAlertOpen(true);
    } else if (Number(values.receivedAmount) === total) {
      setLoading(true);

      const newTemp = [];

      receiptData.forEach((obj) => {
        noOfYears.forEach((obj1) => {
          voucherHeadIds.forEach((obj2) => {
            if (
              obj.isChecked === true &&
              data.postData[obj1.key][obj2.id] > 0
            ) {
              newTemp.push({
                isChecked: obj.isChecked,
                active: true,
                fee_receipt: obj.fee_receipt,
                fee_receipt_id: obj.id,
                financial_year_id: obj.financial_year_id,
                bank_id: obj.bank_id,
                transcation_type: obj.transaction_type,
                year_back_status: obj.year_back_status,
                type: obj.type,
                balance_amount: (
                  display.dueAmount[obj1.key][obj2.id] -
                  data.postData[obj1.key][obj2.id]
                ).toFixed(2),
                paid_year: obj1.key,
                remarks: values.remarks,
                total_amount: values.receivedAmount,
                total_amount_som: values.receivedAmount,
                total: total,
                total_som: total,
                paid_amount: Number(data.postData[obj1.key][obj2.id]),
                paid_amount_som: data.postData[obj1.key][obj2.id],
                to_pay: Number(
                  display.fee_template_sub_amount_format[obj1.key][obj2.id]
                ),
                to_pay_som:
                  display.fee_template_sub_amount_format[obj1.key][obj2.id],
                voucher_head_new_id: obj2.id,
                student_id: studentId,
                fee_template_id: feetemplateId,
              });
            }
          });
        });
      });

      await axios
        .put(
          `/api/finance/feeReceiptTransfer/${oldStudentId}/${studentId}`,
          newTemp
        )
        .then((res) => {
          setLoading(false);
          if (res.status === 200 || res.status === 201) {
            setAlertMessage({
              severity: "success",
              message: "Fee Transferred Successfully",
            });
            getStudentData();
          } else {
            setAlertMessage({
              severity: "error",
              message: res.data ? res.data.message : "Error Occured",
            });
          }
          setAlertOpen(true);
          setLoading(false);
        });
    } else {
      setAlertMessage({
        severity: "error",
        message: "Total amount is not matching to received amount",
      });
      setAlertOpen(true);
      setLoading(false);
    }
  };

  return (
    <Box component="form" overflow="hidden" p={1}>
      <FormPaperWrapper>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-start"
          rowSpacing={0}
          columnSpacing={{ xs: 2, md: 4 }}
        >
          <Grid item xs={12}>
            {open ? (
              <Grid
                container
                alignItems="center"
                justifyContent="flex-start"
                rowSpacing={1}
                columnSpacing={{ xs: 2, md: 4 }}
              >
                <Grid item xs={12} md={12} mt={2}>
                  <Typography className={classes.bg}>
                    Student Details
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Paper elevation={2}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="center"
                      rowSpacing={1}
                      pl={2}
                      pr={2}
                      pb={1}
                      pt={1}
                    >
                      <Grid item xs={12} md={2}>
                        <Typography variant="subtitle2">AUID</Typography>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Typography variant="body2" color="textSecondary">
                          {studentData.auid}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Typography variant="subtitle2">School</Typography>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography variant="body2" color="textSecondary">
                          {studentData.school_name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Typography variant="subtitle2">Name</Typography>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Typography variant="body2" color="textSecondary">
                          {studentData.student_name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Typography variant="subtitle2">Program</Typography>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography variant="body2" color="textSecondary">
                          {studentData.program_name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Typography variant="subtitle2">DOA</Typography>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Typography variant="body2" color="textSecondary">
                          {moment(studentData.date_of_admission).format(
                            "DD-MM-YYYY"
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Typography variant="subtitle2">
                          Admission Category
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography variant="body2" color="textSecondary">
                          {studentData.fee_admission_category_type}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Typography variant="subtitle2">Year/Sem</Typography>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Typography variant="body2" color="textSecondary">
                          {studentData.current_sem}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Typography variant="subtitle2">Template</Typography>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography variant="body2" color="textSecondary">
                          {studentData.fee_template_name}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={2}>
                        <Typography variant="subtitle2">Email</Typography>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Typography variant="body2" color="textSecondary">
                          {studentData.acharya_email}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Typography variant="subtitle2">Mobile</Typography>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography variant="body2" color="textSecondary">
                          {studentData.mobile}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    rowSpacing={2}
                    justifyContent="flex-start"
                    columnSpacing={2}
                  >
                    {receiptData.length > 0 ? (
                      <>
                        <Grid item xs={12} md={12}>
                          <TableContainer
                            component={Paper}
                            sx={{ position: "relative" }}
                          >
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell sx={{ width: "5%" }}>
                                    Select
                                  </StyledTableCell>
                                  <StyledTableCell sx={{ width: "5%" }}>
                                    Receipt No.
                                  </StyledTableCell>
                                  <StyledTableCell sx={{ width: "5%" }}>
                                    Date
                                  </StyledTableCell>
                                  <StyledTableCell sx={{ width: "5%" }}>
                                    Old auid
                                  </StyledTableCell>
                                  <StyledTableCell sx={{ width: "5%" }}>
                                    Amount
                                  </StyledTableCell>
                                  <StyledTableCell sx={{ width: "5%" }}>
                                    User
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {receiptData.length > 0 ? (
                                  receiptData.map((obj, i) => {
                                    return (
                                      <TableRow key={i}>
                                        <StyledTableCell>
                                          <Checkbox
                                            {...label}
                                            sx={{
                                              "& .MuiSvgIcon-root": {
                                                fontSize: 15,
                                              },
                                            }}
                                            name={obj.fee_receipt}
                                            value={obj.fee_receipt}
                                            onChange={handleChangeCheckbox}
                                            checked={obj?.isChecked || false}
                                            disabled={obj.submittedStatus}
                                          />
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {obj.fee_receipt}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {moment(obj.modified_date).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {obj.auid}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {obj.paid_amount}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {obj.created_by}
                                        </StyledTableCell>
                                      </TableRow>
                                    );
                                  })
                                ) : (
                                  <></>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <CustomTextField
                            name="receivedAmount"
                            label="Received Amount"
                            value={values.receivedAmount}
                            handleChange={handleChange}
                          />
                        </Grid>
                      </>
                    ) : (
                      <Grid item xs={12} align="center">
                        <Typography variant="h6" color="error">
                          No receipts to Transfer Fee
                        </Typography>
                      </Grid>
                    )}
                  </Grid>

                  {noOfYears.length > 0
                    ? noOfYears.map((obj, i) => {
                        return (
                          <>
                            <Grid item xs={12} md={12} mt={2} key={i}>
                              <Typography className={classes.yearSem}>
                                {obj.label}
                                <VisibilityIcon
                                  fontSize="small"
                                  sx={{
                                    marginLeft: 138,
                                    color: "#4A57A9 !important",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleClick(obj.key)}
                                />
                              </Typography>
                            </Grid>

                            <Collapse
                              in={showData[obj.key]}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box sx={{ margin: 1 }}>
                                <TableContainer>
                                  <Table size="small">
                                    <TableHead className={classes.bg}>
                                      <TableRow>
                                        <TableCell
                                          sx={{ color: "white", width: "10%" }}
                                        >
                                          Heads
                                        </TableCell>
                                        <TableCell
                                          sx={{ color: "white", width: "10%" }}
                                        >
                                          Fixed
                                        </TableCell>
                                        <TableCell
                                          sx={{ color: "white", width: "10%" }}
                                        >
                                          Grant
                                        </TableCell>
                                        <TableCell
                                          sx={{ color: "white", width: "10%" }}
                                        >
                                          Due
                                        </TableCell>
                                        <TableCell
                                          sx={{ color: "white", width: "10%" }}
                                        >
                                          Payment
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {voucherHeadIds.length > 0 ? (
                                        voucherHeadIds.map((obj1, j) => {
                                          return (
                                            <TableRow key={j}>
                                              {display.fee_template_sub_amount_format !==
                                                undefined &&
                                              display
                                                .fee_template_sub_amount_format[
                                                obj.key
                                              ][obj1.id] > 0 ? (
                                                <>
                                                  <TableCell>
                                                    {obj1.label}
                                                  </TableCell>
                                                  <TableCell>
                                                    {display
                                                      .fee_template_sub_amount_format[
                                                      obj.key
                                                    ] !== undefined ? (
                                                      display
                                                        .fee_template_sub_amount_format[
                                                        obj.key
                                                      ][obj1.id]
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </TableCell>

                                                  <TableCell>
                                                    {display
                                                      .scholarship_approval_amount[
                                                      obj.key
                                                    ] !== undefined
                                                      ? display
                                                          .scholarship_approval_amount[
                                                          obj.key
                                                        ][obj1.id]
                                                      : 0}
                                                  </TableCell>

                                                  <TableCell>
                                                    {display.dueAmount[
                                                      obj.key
                                                    ] !== undefined
                                                      ? display.dueAmount[
                                                          obj.key
                                                        ][obj1.id]
                                                      : 0}
                                                  </TableCell>

                                                  <TableCell>
                                                    <CustomTextField
                                                      name={
                                                        obj1.id + "-" + obj.key
                                                      }
                                                      value={
                                                        data.postData[
                                                          obj.key
                                                        ] !== undefined
                                                          ? data.postData[
                                                              obj.key
                                                            ][obj1.id]
                                                          : ""
                                                      }
                                                      handleChange={
                                                        handleChangeOne
                                                      }
                                                      inputProps={{
                                                        style: {
                                                          height: 10,
                                                        },
                                                      }}
                                                    />
                                                  </TableCell>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </TableRow>
                                          );
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Box>
                            </Collapse>
                          </>
                        );
                      })
                    : ""}

                  <Grid item xs={12} md={12}>
                    <TableContainer component={Paper} elevation={2}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={3} sx={{ width: "10%" }}>
                              Total
                            </TableCell>
                            <TableCell sx={{ width: "1%" }}>{total}</TableCell>
                          </TableRow>
                        </TableHead>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid item xs={12} md={12} align="right" mt={2}>
                    <Button
                      style={{ borderRadius: 7 }}
                      variant="contained"
                      color="primary"
                      disabled={loading}
                      onClick={handleCreate}
                    >
                      {loading ? (
                        <CircularProgress
                          size={25}
                          color="blue"
                          style={{ margin: "2px 13px" }}
                        />
                      ) : (
                        <strong>{"Transfer"}</strong>
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </FormPaperWrapper>
    </Box>
  );
}

export default FeeTransfer;
