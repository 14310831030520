export const Chapter1DataQualified = [
    {
        en: "1.1. The University undertakes to provide an educational service with the aim of obtaining higher education by a Student, in the area of specialization in accordance with the curriculum and programs of the University, and the Student shall be obliged to (study) get knowledge for educational services under the terms of this Contract.",
        uz: "1.1. Universitet Talabaga Universitet o’quv dasturlari va ixtisosliklari asosida oliy ta’lim xizmatlarini ko’rsatish majburiyatini o’z bo’yniga oladi. Talaba esa mazkur shartnoma shartlari asosida ko’rsatiluvchi ta’lim xizmatlariga bo’ysunadi (ta’lim oladi)."
    },
    {
        en: "1.2. The duration of the Educational program is 4 years, and the validity of the contract at the time of signing is Four (4)year. Each academic year consists of 2 (two) semesters. Education at the University is carried only in English.",
        uz: "1.2. Ta’lim dasturi davomiyligi to’rt (4) yil bo’lib, shartnoma imzolangan vaqtdan boshlab to’rt (4) yil mobaynida amal qiladi. Har bir o’quv yili ikki (2) semesterdan iborat bo’ladi. Universitetda darslar faqatgina Ingliz tilida olib boriladi."
    },
    {
        en: "1.3. A student is enrolled in the University, in accordance with the Admission Rules of the University.",
        uz: "1.3. Talaba universitetga Qabul qilish tartib-qoidalari asosida talabalikka qabul qilinadi."
    },
    {
        en: "1.4. After the Student has completed the educational program and successfully passed the final examination, he/she shall be awarded a standard Diploma of the Acharya the University, recognized as a document of education in the Republic of Uzbekistan.",
        uz: "1.4. Ta’lim dasturini tamomlagan va bitiruv imtixonlaridan muvaffaqiyatli o’tgan talabalarga O’zbekiston Respublikasida tan olingan Acharya Universiteti Diplomi beriladi."
    }
]

export const Chapter2_2_1Qualified = [
    {
        en: "2.1.1 To enroll the student who has fulfilled the conditions of admission established in accordance with the Admission Rules,  requirements, and terms of the University on the basis of the decision of the University admission committee or/and by the order of the General Director",
        uz: "2.1.1 Universitet qabul komissiyasi qarori va Bosh Ijrochi Direktor buyrug’i asosida talabalikka qabul qilish tartib-qoidalari asosida qabul shartlarini bajargan talabalarni talabalikka qabul qilish."
    },
    {
        en: "2.1.2 To organize and ensure the proper education of the Student in the direction of study/program in accordance with the current academic curriculum during the whole term of this Contract.",
        uz: "2.1.2 Mazkur shartnomaning butun amal qilish muddati mobaynida Talabaga tegishli o’quv reja asosidagi ta’lim/ixtisoslik yo’nalishi bo’yicha ta’lim berish jarayonini tashkil etish va amalga oshirish."
    },
    {
        en: "2.1.3 To acquaint the Student during the period of conclusion of the present Contract with normative documents of the University like the Rules of internal regulations of the University.",
        uz: "2.1.3 Mazkur shartnomani tuzish asnosida Talabani universitet ichki tartib-qoidalari kabi Universitet faoliyatiga oid normativ-hujjatlar bilan tanishtirish."
    },
    {
        en: "2.1.4. To accept the payment as provided by this Contract.",
        uz: "2.1.4. Mazkur shartnomada nazarda tutilgan ta’lim uchun to’lovni qabul qilish."
    },
    {
        en: "2.1.5 To provide the Student information about the Student's progress, behavior, attitude towards the study in general and certain subjects of the curriculum and their attendance according to the study schedule.",
        uz: "2.1.5 Talabaga talabaning ta’lim olish jarayonidagi natijalari, xulq-atvori, o’quv jadvaliga muvofiq fanlarni o’zlashtirishi va belgilangan dars jadvali asosida darslarga qatnashi borasida ma’lumot berib turish."
    },
    {
        en: "2.1.6. To provide the Student the right to use laboratory equipment, library facilities, sport and cultural facilities, medical service in the Student's medical office on common basis in accordance with the procedure established in the University.",
        uz: "2.1.6. Talabaga Universitet tomonidan belgilangan umumiy tartib-qoidalar asosida laboratoriya jihozlari, kutubxona imkoniyatlari, sport va madaniy inshootlar, talabalar tibbiyot punktidagi tibbiy xizmatlardan foydalanish huquqini taqdim etish."
    },
    {
        en: "2.1.7. To issue a login and password to the student to enter the University Student ERP module",
        uz: "2.1.7. Talabalarning Universitet ERP tizimidan foydalanishi uchun login va parollarni taqdim etish."
    },
    {
        en: "2.1.8. On completion of the studies and on meeting the academic requirements as per the academic policy of the University, the student shall be awarded the Diploma on obtaining No Objection from the payer.",
        uz: "2.1.8. O’qishni tugatganidan keyin va Universitet akademik tartib-qoidalarida belgilangan barcha majburiyatlarni bajargan taqdirda talabaga Diplom berish."
    },
    {
        en: "2.1.9. The University assumes no obligation to employ the Student at the end of the term of study at the University.",
        uz: "2.1.9. Universitet Talabani universitetdagi o’qish davri tugaganidan keyin ish bilan ta’minlashni o’z bo’yniga olmaydi."
    },
    {
        en: "2.1.10. The University provides the student one Laptop for use in studies for the entire duration of the educational program of 4 years. The University provides the student a laptop in the second semester for use in studies.",
        uz: "2.1.10. Universitet talabaga 4 yillik ta’lim dasturi mobaynida foydalanishi uchun bitta noutbuk taqdim etadi. Universitet tomonidan mazkur noutbuk talabaga o’quv maqsadlarida foydalanishini ko’zlab ikkinchi semesterda taqdim etiladi."
    }
]

export const Chapter2_2_2Qualified = [
    {
        en: "2.2.1 To independently carry out the educational process, choose the grading systems, the order and frequency of the intermediate assessment of the Student.",
        uz: "2.2.1 Ta’lim jarayonini amalga oshirish, baholash tizimlarini tanlash, talabaning oraliq baholash jarayonlari borasidagi tartib va qoidalarini mustaqil ravishda amalga oshiradi."
    },
    {
        en: "2.2.2 To make changes to the approved schedule of classes without reducing the scope of educational services provided.",
        uz: "2.2.2 Tasdiqlangan dars jadvaliga ta’lim xizmatlarini qisqartirmay tegishli o’zgarishlar kiritish."
    },
    {
        en: "2.2.3. Apply to the Student the measures of encouragement and disciplinary sanctions in accordance with the legislation of the Republic of Uzbekistan, the Charter, the Internal Regulations of the University and this Contract.",
        uz: "2.2.3. Talabaga nisbatan O‘zbekiston Respublikasi qonunchiligi, Ustav, Universitet ichki tartib qoidalari va mazkur Shartnomaga muvofiq rag‘batlantirishlar va intizomiy jazo choralarini qo‘llash."
    },
    {
        en: "2.2.4. To carry out continuous monitoring on the accomplishment by the student of his/her obligations.",
        uz: "2.2.4. Talabaning majburiyatlarini amalga oshirishini muntazam ravishda nazorat qilib borish."
    },
    {
        en: "2.2.5. To restrict the access of the Student to the University building, to educational resources and examinations in case of violation of obligations and/or missing the required number of academic hours. The number of academic hours required for the admission of the Student to examinations is regulated by the internal rules of the University.",
        uz: "2.2.5. Majburiyatlarni bajarmagan yoki talab qilingan o’quv soatlari borasida tegishli davomat talablarini bajarmagan talabalarning Universitet binosiga, ta’lim resurslari va sinovlariga kirishiga cheklovlar qo’yish. Talabaning imtixonlarda ishtirok etishi uchun talab qilinadigan o’quv soatlari miqdori Universitet ichki tartib-qoidalari asosida belgilanadi."
    },
    {
        en: "2.2.6. The University has the right to dismiss the Student on the following reasons:",
        uz: "2.2.6. Universitet quyidagi sabalarga ko’ra Talabani Universitet talabalari safidan chetlatish huquqiga ega:"
    },
    {
        en: "1. on the Student's own initiative (in writing);",
        uz: "1. Talabaning shaxsiy tashabbusiga ko’ra (yozma shaklda);"
    },
    {
        en: "2. for violation of academic discipline and internal regulations of the University.",
        uz: "2. O’quv intizomi va Universitet ichki tartib-qoidalarini buzganlik uchun;"
    },
    {
        en: "3. for academic failure (credit debt);",
        uz: "3. Akademik qarzdorlik uchun (kredit qarzdorligi);"
    },
    {
        en: "4. in connection with a transfer to another University.  ",
        uz: "4. O’qishini boshqa universitetga ko’chirishi munosabati bilan;"
    },
    {
        en: "5. for health reasons (on the basis of a certificate from the medical board);",
        uz: "5. Tibbiy sabablarga ko’ra (tegishli tibbiyot muassasasining tibbiy xulosasiga ko’ra)"
    },
    {
        en: "6. in connection with deprivation of liberty by court order.",
        uz: "6. Sud qaroriga asosan ozodlikdan mahrum etilganda;"
    },
    {
        en: "7. in connection with death;",
        uz: "7. Vafot etgani sababli."
    },
    {
        en: "8. more than 1(one) month of absence without a proper reason during one semester.",
        uz: "8. Bir semester mobaynida sababsiz bir oydan ortiq muddatda darslarda sababsiz ishtirok etmagan taqdirda"
    },
    {
        en: "The student is considered to have been expelled from the University from the date determined by the relevant order.",
        uz: "Talabalar safidan chetlatish borasidagi tegishli buyruq chiqqan sanadan boshlab Universitet talabaligidan chetlashtiriladi."
    },
    {
        en: "2.2.7. To perform other rights stipulated by the current legislation of the Republic of Uzbekistan, normative-legal and local documents of the University.",
        uz: "2.2.7. O’zbekiston Respublikasi qonunchiligi, normativ-huquqiy va universitet ichki hujjatlari asosida boshqa huquqlarni amalga oshirish."
    }
]

export const Chapter2_2_3Qualified = [
    {
        en: "2.3.1. To study the educational program in accordance with the curriculum and the schedule of the educational process, to meet the requirements of the educational program, the established scope of study load and self-study.",
        uz: "2.3.1. O'quv jarayonining belgilangan o'quv rejasi va jadvaliga muvofiq o'quv dasturini o'zlashtirish, o'quv rejasi talablarini, belgilangan o'quv yuklamasi hajmlarini va mustaqil ishlarni bajarish."
    },
    {
        en: "2.3.2. To comply with the requirements of laws, regulations in the field of education, the Charter and local regulations of the University, as well as the internal regulations of the University.",
        uz: "2.3.2. Qonunlar, ta'limga oid me'yoriy hujjatlar, Universitet nizomi va mahalliy normativ hujjatlar, shuningdek Universitetning ichki tartib-qoidalari talablariga rioya qilish."
    },
    {
        en: "2.3.3. To attend classes according to the study schedule. To wear the uniform of the University established standard, to have a neat appearance.",
        uz: "2.3.3.Dars jadvaliga asosan darslarga qatnashish. Universitet tomonidan o’rnatilgan kiyinish tartib-qoidalariga amal qilish, chiroyli ko’rinishda kiyinish."
    },
    {
        en: "2.3.4. To receive a login and password to enter the personal mailbox of the University e-mail. /Student ERP Portal.",
        uz: "2.3.4. Universitet tomonidan berilgan shaxsiy elektron pochta/Talabalar ERP portali uchun login va parollarni olish."
    },
    {
        en: "2.3.5. Notify the University in writing of proper reasons for your absence from classes.",
        uz: "2.3.5. Qoldirilgan darslari uchun universitetga yozma sabablarni taqdim qilish."
    },
    {
        en: "2.3.6. Take care of the University property. To compensate for damage caused to the property in accordance with the legislation and local regulations of the University.",
        uz: "2.3.6. Universitet mulkidan ehtiyotkorlik bilan foydalanish. Qonun doirasida va universitet ichki tartib-qoidalari asosida mulkka yetkazilgan zararni qoplash."
    },
    {
        en: "2.3.7. Upon termination of educational relations on its own initiative, to notify the University in writing in advance.",
        uz: "2.3.7. O’z xoxishiga ko’ra universitetdagi ta’limni to’xtatish borasida yozma ravishda universitetni ogohlantirish."
    },
    {
        en: "2.3.8 When entering the University and during the course of study to provide all necessary documents in time. Within 3 (three) days to inform about changes in his/her data to the University academic department in writing.",
        uz: "2.3.8 Universitetga kirishda va o’qish davrida barcha zarur hujjatlarni taqdim qilish. Shaxsiy ma’lumotlaridagi o’zgarish borasida 3 (uch) kun ichida universitet ma’muriyatiga ma’lum qilish."
    },
    {
        en: "2.3.9. In case of academic failure during study to pay for additional services to retake the exams, repeat studies on the basis of separate contracts in accordance with tariffs approved by the University.",
        uz: "2.3.9. O’quv jaronida fanlarni o’zlashtira olmagan taqdirda qo’shimcha imtixonlarda ishtirok etish yoki qayta o’qish uchun Universitet tomonidan belgilab berilgan narxlar asosida qo’shimcha haq to’lash."
    },
    {
        en: "2.3.10. To fulfill other conditions and guarantees by Contract of the Parties.",
        uz: "2.3.10. Tomonlar o’rtasidagi boshqa kelishuv va shartlarga amal qilish."
    },
    {
        en: "2.3.11. In the case of making damages to the University property, the student, as per the rules of the University, undertakes to compensate the damage in full at his/her own expense within 10 days. In case of refusal of the student from compensation, due to whose fault the damage was caused, the University has the right to initiate Legal proceedings against the student as per the internal rules of the University and as per the Laws of the Republic of Uzbekistan.",
        uz: "2.3.11. Universitet mulkiga zarar yetkazilgan taqdirda talaba Universitet qoidalariga muvofiq yetkazilgan zararni 10 kun ichida o‘z mablag‘lari hisobidan to‘liq qoplash majburiyatini oladi Universitet Talaba yetkazilgan zararni qoplashdan  zarardan bosh tortgan taqdirda Universitet ichki tartib-qoidalari va O‘zbekiston Respublikasi qonunchiligiga muvofiq talabaga nisbatan sudga murojaat qilishga haqli."
    },
    {
        en: "2.3.12. The student undertakes not to disseminate inaccurate, false information, criticism and other illegal actions in the media, in the Internet publications and social networks, defaming the image and reputation of the University.",
        uz: "2.3.12. Talaba OAV, internet nashrlari va ijtimoiy tarmoqlarda ishonchsiz, yolg‘on ma’lumotlarni tarqatmaslik, tanqid qilish va Universitet obro‘siga putur yetkazuvchi boshqa noqonuniy hatti-harakatlarga yo‘l qo‘ymaslik majburiyatini oladi."
    },
    {
        en: "2.3.13 The Student shall maintain/repair the laptop issued by the University at his/her own expense in case of damage. If the student losses the Laptop issued by the University he/she shall be re-issued a new laptop after reimbursing (making payment) of 6250000 (six million two hundred fifty thousand) sum for the lost Laptop.",
        uz: "2.3.13 Talaba universitet tomonidan berilgan noutbukga shikast yetgan taqdirda texnik xizmat ko‘rsatish/ta’mirlash ishlarini o‘z hisobidan amalga oshiradi. Agar talaba Universitet tomonidan berilgan noutbukni yo‘qotib qo‘ysa, talaba tomonidan 6 250 000(olti million ikki yuz ellik ming) so’m to‘langanidan keyin unga yangi noutbuk beriladi."
    },
    {
        en: "If the Student discontinues his education program of 4 years with the University for any reason or is rusticated/dismissed from the University for any disciplinary reasons, the Student shall reimburse (pay) 6250000 (six million two hundred fifty thousand) sum within 3 working days for laptop and keep the laptop for himself/herself.",
        uz: "Agar talaba universitetdagi 4 yillik o‘qish dasturini biron-bir sababga ko‘ra to‘xtatsa yoki intizomiy sabablarga ko‘ra universitetdan talabalari safidan chetlatilgan taqdirda, talaba 3 ish kuni doirasida 6 250 000(olti million ikki yuz ellik ming) so’m miqdoridagi noutbuk summasini qaytarishi (to‘lashi) shart va shu orqali noutbukni o’ziga qoldirishi mumkin. shart."
    },
    {
        en: "If the Student successfully completes the four-year educational program in accordance with the procedure established by the University, the laptop shall become the property of the Student and be at his/her disposal.",
        uz: "Agar talaba Universitet tomonidan belgilangan tartibda to‘rt yillik ta’lim dasturini muvaffaqiyatli tamomlasa, noutbuk talabaning shaxsiy mulkiga aylanadi va uning ixtiyorida bo‘ladi."
    }
]

export const Chapter2_2_4Qualified = [
    {
        en: "2.4.1. To receive complete and reliable information on assessment of his knowledge, skills and abilities, as well as the criteria for this assessment on issues related to the process of study at the University.",
        uz: "2.4.1. O’qishi davrida o’zlashtirishi, bilim-ko’nikmalari va iqtidorining baholanishi, shu bilan birgalikda Universitet o’quv jarayonidagi baholash mezonlari masalasida batafsil va ishonchli ma’lumotlarni olish."
    },
    {
        en: "2.4.2. To use the property of the University, necessary to learn the educational program during the classes provided by the academic schedule.",
        uz: "2.4.2. Dars jadvali asosida belgilangan sinf xonalarida zarur bo’lgan ta’limni olish mobaynida Universitet mulkidan foydalanish."
    },
    {
        en: "2.4.3. To use additional educational services that are not included in the educational program based on a separately concluded Contract.",
        uz: "2.4.3. Ta’lim dasturiga kiritilmagan qo’shimcha xizmatlar uchun alohida shartnoma tuzish."
    },
    {
        en: "2.4.4. Address employees of the University on issues related to the study process at the University.",
        uz: "2.4.4. Universitetdagi o'quv jarayoniga oid savollar bilan universitet xodimlariga murojaat qilish"
    },
    {
        en: "2.4.5. To take part in social-cultural and other events organized for the University students.",
        uz: "2.4.5. Universitet talabalari uchun tashkil etilgan ijtimoiy-madaniy v.h.k tadbirlarda ishtirok etish. "
    },
    {
        en: "2.4.6. To terminate educational relations before the due date on its own initiative on the condition and on the terms established by this agreement and rules, orders of the University.",
        uz: "2.4.6. O'z tashabbusi bilan ushbu shartnomada hamda Universitet qoidalari va buyruqlarida belgilangan tartibda va shartlar asosida ta'lim munosabatlarini muddatidan oldin tugatish."
    },
    {
        en: "2.4.7. In case of failure in the process of study to provide payment for additional services to retake the exams, repeat education in accordance with tariffs approved by the University.",
        uz: "2.4.7. O'quv jarayonida dasturni o’zlashtira olmagan taqdirda, Universitet tomonidan tasdiqlangan tariflarga muvofiq imtihonlarni qayta topshirish, qayta tayyorlash bo'yicha qo'shimcha xizmatlar uchun haq to'lashni ta'minlash."
    },
    {
        en: "2.4.8. Exercise other rights stipulated by the current legislation of the Republic of Uzbekistan, regulations, and local documents of the University.",
        uz: "2.4.8. O‘zbekiston Respublikasining amaldagi qonunchiligida, Universitetning me’yoriy va mahalliy hujjatlarida nazarda tutilgan boshqa huquqlarni amalga oshirish."
    },
]

export const Chapter3DataQualified = [
    {
        en: "3.2. The Student is obliged to pay the amount annually for the respective academic year by transferring funds to the current account of the University in the following order:",
        uz: "3.2. Talaba har yili tegishli o'quv yili uchun pul mablag'larini Universitet hisob raqamiga quyidagi tartibda o'tkazish orqali to'lashi shart:"
    },
    {
        en: "50% of the cost (education fee) of the annual contract is paid before the start of the first academic semester.",
        uz: "To’lov-shartnoma miqdorining 50% qismi o’quv yilining birinchi semester boshlanguniga qadar to’lanadi."
    },
    {
        en: "The remaining 50% of the cost of the annual contract is paid before the start of the second academic semester.",
        uz: "Qolgan 50% qismi esa ikkinchi semester boshlanguniga qadar to’lanadi."
    },
    {
        en: "3.3 The student has the right to inform the intention to withdraw and terminate this contract before the commencement of classes and demand refund payment. In this case, the University within 10 working days returns the money with the right of retention (deduction) 10%. If the student informs about his decision to stop education and withdraw, after the commencement of classes, the University is not liable to make any refund.",
        uz: "3.3 Talaba o'qish (darslar) boshlanishidan oldin ushbu shartnomadan chiqish va uni bekor qilish niyati to'g'risida xabar berishga va to'langan pul mablag'larini qaytarishni talab qilishga haqlidir. Bunday holda, Universitet 10 ish kuni ichida mablag'ni 10% ushlab qolish huquqi bilan qaytaradi. Agar talaba mashg'ulotlar (treyning) boshlanganidan keyin o'qishni tark etish va keyingi o'qishni davom ettirmaslik to'g'risida qaror qabul qilganligi haqida xabar bersa, to'langan summa qaytarilmaydi."
    },
    {
        en: "3.4. The university, in accordance with internal rules and the decision of the director, has the right provide some discounts and grants.",
        uz: "3.4. Universitet ichki tartib-qoidalarga va direktor qaroriga muvofiq talabalarga ma’lum chegirmalar va grantlar ham berish huquqiga ega."
    },
    {
        en: "3.5. All tuition fees are to be paid by the Student.",
        uz: "3.5. Barcha o'quv to'lovlari Talabaning zimmasida."
    },
    {
        en: "3.6. The Parties understand and accept that Tuition fees may be increased by up to 10% each academic year. When changing the amount of tuition fees, the University notifies the Parties 10 (ten) business days before the proposed change by issuing a relevant order and posting it on the relevant websites of the University. If necessary, the Student  undertakes to conclude additional agreement within the period specified by the university.",
        uz: "3.6. Tomonlar o’quv shartnoma pullari har o’quv yilida  10%gacha oshirilishi mumkinligini tushunib, inobatga oladilar. To’lov-shartnoma miqdori oshirilgan taqdirda Universitet ma’muriyati tomonidan bu haqida tegishli buyruq chiqarilib, universitetning ijtimoiy sahifalarida e’lon qilinadi. Zarur holatda Talaba Universitet ko’rsatib o’tgan  muddatda qo’shimcha shartnoma tuzish majburiyatini oladilar."
    },
    {
        en: "3.7. Payment for Services (transfer of funds) is made in accordance with present contract and the current legislation of the Republic of Uzbekistan on the details specified in this Contract.",
        uz: "3.7. Xizmatlar uchun haq to’lash (pul mablag’larini o’tkazish) mazkur shartnomaga asosan va O’zbekiston Respublikasi amaldagi qonunchiligiga muvofiq ushbu shartnomada ko‘rsatilgan rekvizitlardan foydalangan holda amalga oshiriladi."
    }
]

export const Chapter4DataQualified = [
    {
        en: "4.1. For non-fulfillment and improper fulfillment of their obligations under the Contract, the Parties shall bear responsibility stipulated by the Legislation of the Republic of Uzbekistan.",
        uz: "4.1. Shartnoma bo‘yicha o‘z majburiyatlarini bajarmaganliklari va lozim darajada bajarmaganliklari uchun Tomonlar O‘zbekiston Respublikasi qonunchiligiga muvofiq javobgar bo‘ladilar."
    },
    {
        en: "4.2. The student is responsible for violations of the rules established at the University and the terms of this agreement. If the Student will spread (send out) through the media, Internet publications and social networks, inaccurate, false information about the University, and will make illegal criticism and other illegal actions defaming the image and reputation of the University, by the decision of the Director of the University, the student may be expelled (dismiss) from studies without refund of the paid amount for studying.",
        uz: "4.2. Talaba Universitet tomonidan o’rnatilgan tartib-qoidalarni va ushbu shartnoma shartlarini buzganlik uchun javobgar bo’ladi. Talaba OAVda, internet nashrlarida va ijtimoiy tarmoqlarda Universitet toʻgʻrisida notoʻgʻri, asoslanmagan maʼlumotlarni tarqatsa, universitet imidji va obroʻsiga putur yetkazuvchi tanqidiy va boshqa noqonuniy hatti-harakatlarni amalga oshirsa, universitet direktorining qarori bilan talaba o'qish uchun to'langan pulni qaytarmasdan o'qishdan chetlatish mumkin."
    },
    {
        en: "4.3. In case of late payment for tuition the Student shall pay to the University a penalty in the amount of 0,04% of the overdue payment amount for each day of delay, but not more than 20% of the overdue payment amount.",
        uz: "4.3. O’qish to’lovi kechikkan taqdirda Talaba tomonidan kechiktirilgan har bir kun uchun universitetga 0,04% miqdorida umumiy 20%dan oshmagan miqdorda penya to’laydi."
    },
    {
        en: "4.4. Measures of liability for violation of the obligations of the Parties not stipulated in this Contract shall be applied in accordance with the Legislation of the Republic of Uzbekistan.",
        uz: "4.4. Tomonlarning ushbu Shartnomada nazarda tutilmagan majburiyatlarini buzganlik holatlari uchun jarimalar O‘zbekiston Respublikasi qonunchiligiga muvofiq qo‘llaniladi."
    },
]

export const Chapter5DataQualified = [
    {
        en: "5.1. The Parties are released from responsibility for failure to perform or improper performance of their obligations under this Contract if such failure was caused by force majeure. Such circumstances and/or events are defined as the force-majeure circumstances if they are beyond the control of the Party and have arisen after signing this Contract as a result of inevitable circumstances, military actions or natural disasters.",
        uz: "5.1. Fors-major holatlari (fors-major) sabab Tomonlar ushbu Shartnoma bo'yicha o'z majburiyatlarini to’liq bajarmaganliklari yoki lozim darajada bajarmaganliklari uchun javobgarlikdan ozod qilinadilar. Ushbu shartnoma imzolanganidan keyin Tomonlar ixtiyoridan chetda bo’lgan harbiy harakatlar, tabiiy ofatlar kabi voqea-hodisalar natijasida yuzaga kelgan imkonsiz holatlar fors-major holati deyiladi."
    },
    {
        en: "5.2. The Party for which the inability to fulfill its obligations due to the circumstances and/or force-majeure events has arisen shall immediately inform the other Parties and submit the documents of the relevant authorities competent to confirm the fact of the circumstances for the entire period of the circumstances and/or force-majeure events.",
        uz: "5.2. Yuqorida aytib o’tilgan yoki fors-major holatlar sabab o’z majburiyatlarini bajara olmagan taqdirda yoki vaziyatning butun davri uchun tomonlar bu haqida tegishli faktlar bilan vakolatli organlarning hujjatlarini bir-biriga taqdim qilishlari lozim."
    },
]

export const Chapter6DataQualified = [
    {
        en: "6.1. Disputes and disagreements that may arise in the performance of this Contract will be resolved through negotiations between the Parties, if possible.",
        uz: "6.1. Ushbu Shartnomani bajarish jarayonida yuzaga kelishi mumkin bo'lgan nizolar va kelishmovchiliklar imkon qadar Tomonlar o'rtasidagi muzokaralar yo'li bilan hal qilinadi."
    },
    {
        en: "6.2. In case of impossibility to settle disputes through negotiations, the Parties, after implementation of the procedure of pre-trial settlement of disputes stipulated by the legislation, shall submit them to the Karakul regional Civil Court, for consideration in accordance with the procedure established by the legislation of the Republic of Uzbekistan.",
        uz: "6.2. Agar nizolarni muzokaralar yo‘li bilan hal etishning iloji bo‘lmasa, tomonlar qonun hujjatlarida nazarda tutilgan kelishmovchiliklarni sudgacha hal qilish tartibini amalga oshirgandan so‘ng ularni O’zbekiston Respublikasi qonun hujjatlarida belgilangan tartibda Fuqarolik ishlari bo‘yicha Qorako’l tumanlararo sudiga ko‘rib chiqish uchun taqdim etadilar."
    }
]

export const Chapter7DataQualified = [
    {
        en: "7.1. This Agreement shall enter into force on the date of its conclusion by the Parties and shall be valid until the Parties fulfill their obligations in full.",
        uz: "7.1. Ushbu Shartnoma Tomonlar tomonidan tuzilgan kundan boshlab kuchga kiradi va Tomonlar o'z majburiyatlarini to'liq bajarmaguncha amal qiladi."
    },
    {
        en: "7.2. The amendments and supplements to this Contract shall be executed only in written form and signed by the authorized representatives of the Parties.",
        uz: "7.2. Ushbu Shartnomaga o'zgartirish va qo'shimchalar faqat yozma shaklda kiritiladi va Tomonlarning vakolatli vakillari tomonidan imzolanadi."
    },
    {
        en: "7.3  The present Contract is drawn up and signed in three (3) copies, in Uzbek and English. In case of discrepancies between the Uzbek and English versions, the English version of the Contract shall prevail.",
        uz: "7.3. Ushbu Shartnoma o’zbek va ingliz tillarida uch (3) nusxada tuzilgan va imzolangan. O’z va ingliz tilidagi shakllari o'rtasida ziddiyatlar yuzaga kelgan taqdirda, Shartnomaning ingliz tilidagi qismi ustunlik qiladi."
    }
]