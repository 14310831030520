import { CardContent, Grid, Typography } from "@mui/material";
import axios from "../services/Api";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";

const BoxShadow = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  ...theme.typography.body2,
  backgroundColor: "#f3f6f9",
}));

const StockRegister = () => {
  const [StoreOptions, setStoreOptions] = useState([]);

  useEffect(() => {
    getStoreData();
  }, []);

  const getStoreData = async () => {
    await axios
      .get(
        `/api/inventory/allStoresStockDetails?page=${0}&page_size=${10000}&sort=created_date`
      )
      .then((res) => {
        setStoreOptions(
          res.data.data.Paginated_data.content.map((obj) => ({
            value: obj.stock_type_name,
            label: obj.stock_type_name,
            id: obj.id,
            storeid: obj.id,
          }))
        );
      })
      .catch((err) => console.error(err));
  };

  return (
    <Grid container alignItems="flex-start" spacing={3} mt={1}>
      {StoreOptions?.map((obj) => {
        return (
          <Grid item sm={12} md={6} lg={4}>
            <Card path={`/StockSubRegister/${obj.id}`} title={obj.label} />
            <Paper elevation={3} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default StockRegister;

const Card = ({ path, title, description }) => {
  const navigate = useNavigate();
  return (
    <BoxShadow elevation={3}>
      <CardContent>
        <Typography
          sx={{
            fontSize: 14,
            color: "auzColor.main",
            cursor: "pointer",
            textAlign: "center",
          }}
          gutterBottom
          variant="h6"
          onClick={() => navigate(path)}
        >
          {title}
        </Typography>
      </CardContent>
    </BoxShadow>
  );
};
