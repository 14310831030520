import { useState, useEffect } from "react";
import axios from "../../../services/Api";
import {
  Box,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Button,
} from "@mui/material";
import CustomDatePicker from "../../../components/Inputs/CustomDatePicker";
import CustomTextField from "../../../components/Inputs/CustomTextField";
import DueFollowUpNote from "../../indeces/DueFollowUpNote";
import useBreadcrumbs from "../../../hooks/useBreadcrumbs";
import { useParams } from "react-router-dom";
import useAlert from "../../../hooks/useAlert";

const initialValues = { followRemarks: "", followDate: null };

const requiredFields = [];

function DueFollowHistory() {
  const [values, setValues] = useState(initialValues);
  const [studentId, setStudentId] = useState(null);
  const [followUpData, setFollowUpData] = useState([]);

  const checks = {};
  const setCrumbs = useBreadcrumbs();
  const { auid, due } = useParams();
  const { setAlertMessage, setAlertOpen } = useAlert();

  useEffect(() => {
    getStudents();
    setCrumbs([
      { name: "Follow Up Note", link: "/stdduereport" },
      { name: auid },
    ]);
  }, []);

  useEffect(() => {
    getFollowUpData();
  }, [studentId]);

  const getStudents = async () => {
    await axios
      .get(`/api/student/Student_Details`)
      .then((res) => {
        res.data.data.forEach((obj) => {
          if (obj.auid === auid) {
            setStudentId(obj.student_id);
          }
        });
      })
      .catch((err) => console.error(err));
  };

  const getFollowUpData = async () => {
    if (studentId)
      await axios
        .get(`/api/student/getStudentFollowUpByStudentId/${studentId}`)
        .then((res) => {
          const temp = res.data.data.sort((a, b) => {
            if (a.student_follow_up_id > b.student_follow_up_id) return -1;
            if (a.student_follow_up_id < b.student_follow_up_id) return 1;
            return 0;
          });

          setFollowUpData(temp);
        })
        .catch((err) => console.error(err));
  };

  const handleChange = (e) => {
    setValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeAdvance = (name, newValue) => {
    setValues((prev) => ({
      ...prev,
      [name]: newValue,
    }));
  };

  const requiredFieldsValid = () => {
    for (let i = 0; i < requiredFields.length; i++) {
      const field = requiredFields[i];
      if (Object.keys(checks).includes(field)) {
        const ch = checks[field];
        for (let j = 0; j < ch.length; j++) if (!ch[j]) return false;
      } else if (!values[field]) return false;
    }
    return true;
  };

  const handleCreate = async () => {
    const temp = {};
    temp.active = true;
    temp.follow_up_remarks = values.followRemarks;
    temp.follow_up_date = values.followDate.substr(0, 19) + "Z";
    temp.student_id = studentId;
    temp.due_amount = due;
    await axios
      .post(`/api/student/saveStudentFollowUp`, temp)
      .then((res) => {
        if (res.data.status === 201) {
          setAlertMessage({
            severity: "success",
            message: "Data updated successfully !!",
          });
          setAlertOpen(true);
          setValues(initialValues);
          getFollowUpData();
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <>
      <Card>
        <CardHeader
          title="Follow Up Note"
          titleTypographyProps={{ variant: "subtitle2" }}
          sx={{
            backgroundColor: "rgba(74, 87, 169, 0.1)",
            color: "#46464E",
            padding: 1,
          }}
        />
        <CardContent>
          <Box mt={2}>
            <Grid
              container
              justifyContent="flex-start"
              columnSpacing={4}
              rowSpacing={2}
            >
              <Grid item xs={12}>
                <CustomTextField
                  name="followRemarks"
                  label="Follow Up Note"
                  value={values.followRemarks}
                  handleChange={handleChange}
                  multiline
                  rows={4}
                  checks={checks.followRemarks}
                  required
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <CustomDatePicker
                  name="followDate"
                  label="Follow Up Date"
                  value={values.followDate}
                  handleChangeAdvance={handleChangeAdvance}
                  disablePast
                />
              </Grid>
              <Grid item xs={12} align="right">
                <Button
                  variant="contained"
                  onClick={handleCreate}
                  disabled={!requiredFieldsValid()}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              rowSpacing={1}
              mt={2}
            >
              <Grid item xs={12} md={3.4} ml={24}>
                <Typography color="primary" variant="h6">
                  Follow Up Notes
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <DueFollowUpNote followUpData={followUpData} />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

export default DueFollowHistory;
