import stamp from "../../../assets/stamp.png";
import sign from "../../../assets/sign.png";
import moment from "moment";

const QualifiedContractNCT = ({ data }) => {
  return (
    <>
      <table
        style={{
          width: "100%",
          border: "none",
          borderCollapse: "collapse",
        }}
      >
        <tbody>
          <tr>
            <td
              style={{
                width: "262.25pt",
                padding: "0cm 5.4pt",
                verticalAlign: "top",
              }}
            >
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>Contract No.&nbsp;</span>
                </strong>
                <strong>
                  <span style={{ fontSize: 16 }}>{data.application_no_npf}</span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>
                    on the provision educational services&nbsp;
                  </span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <strong>
                  <span style={{ fontSize: 15 }}>&nbsp;</span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <strong>
                  <span style={{ fontSize: 11 }}>&nbsp;</span>
                </strong>
              </p>
            </td>
            <td
              style={{
                width: "262.25pt",
                padding: "0cm 5.4pt",
                verticalAlign: "top",
              }}
            >
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>
                  Shartnoma № &nbsp; {data.application_no_npf}
                  </span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>
                  Ta’lim xizmatlarini ko’rsatish borasida
                  </span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <strong>
                  <span style={{ fontSize: 11 }}>&nbsp;</span>
                </strong>
              </p>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "262.25pt",
                padding: "0cm 5.4pt",
                verticalAlign: "top",
              }}
            >
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <em>
                  <span style={{ fontSize: 12 }}>
                    This Contract was concluded in &nbsp;{" "}
                    {moment().format("Do MMMM YYYY")}
                    in Bukhara region, Republic of Uzbekistan.
                  </span>
                </em>
              </p>
            </td>
            <td
              style={{
                width: "262.25pt",
                padding: "0cm 5.4pt",
                verticalAlign: "top",
              }}
            >
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <em>
                  <span style={{ fontSize: 12 }}>
                  Mazkur shartnoma &nbsp;{" "}
                    {moment().format("Do MMMM YYYY")} &nbsp;
                    sanasida O’zbekiston Respublikasi, Buxoro viloyatida tuzildi 
                  </span>
                </em>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "262.25pt",
                padding: "0cm 5.4pt",
                verticalAlign: "top",
              }}
            >
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>FE&nbsp;</span>
                </strong>
                <span style={{ fontSize: 16 }}>
                  “<strong>Acharya University</strong> <strong>LLC”</strong>
                  ,&nbsp;a legal entity, registered on December 22, 2022, and acting in accordance with the legislation of the Republic of Uzbekistan (TIN: 310106691), located in Bukhara region, Karakul district, Khuzhalar MFY, Bukhara street, 60, represented by Bukhara region, Karakul district, Khujalar MFY, Bukhoro street., General Director Chandrashekar  Vishesh, acting on the basis of the Charter as the first party hereinafter referred to as University and first year student
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 8 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 8 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 13,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      textAlign: "center",
                    }}
                  >
                    {data.candidate_name}
                  </div>
                  <div
                    style={{
                      width: "50%",
                      textAlign: "center",
                    }}
                  >
                    {data?.date_of_birth
                      ?.slice(0, 10)
                      ?.split("-")
                      ?.reverse()
                      ?.join("-")}
                  </div>
                </div>
              </p>

              <p
                style={{
                  margin: "0cm",
                  fontSize: 13,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      textAlign: "center",
                    }}
                  >
                    (Student’s Name)
                  </div>
                  <div
                    style={{
                      width: "50%",
                      textAlign: "center",
                    }}
                  >
                    (Date of birth)
                  </div>
                </div>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                hereinafter referred to as the Student,  jointly referred to as the Parties, have concluded this Contract as follows:
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <em>
                  <span style={{ fontSize: 12 }}>&nbsp;</span>
                </em>
              </p>
            </td>
            <td
              style={{
                width: "262.25pt",
                padding: "0cm 5.4pt",
                verticalAlign: "top",
              }}
            >
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                22.12.2022 sanasida ro’yxatga olingan, Buxoro viloyati, Qorako’l tumani, Xo’jalar MFY, Buxoro ko’chasi 60-uyda joylashgan bo’lib, Ustav asosida faoliyat yurituvchi hamda O’zbekiston Respublikasi qonunchiligiga muvofiq faoliyat olib boradigan (INN:310106691) «Acharya University» MCHJ (keyingi o’rinlarda “Universitet”)  nomidan Bosh ijrochi direktor Vishesh Chandrashekar va 1-bosqich talabasi
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 13,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      textAlign: "center",
                    }}
                  >
                    {data.candidate_name}
                  </div>
                  <div
                    style={{
                      width: "50%",
                      textAlign: "center",
                    }}
                  >
                    {data?.date_of_birth
                      ?.slice(0, 10)
                      ?.split("-")
                      ?.reverse()
                      ?.join("-")}
                  </div>
                </div>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 13,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      textAlign: "center",
                    }}
                  >
                    (Talabaning F.I.SH)
                  </div>
                  <div
                    style={{
                      width: "50%",
                      textAlign: "center",
                    }}
                  >
                    (Tug’ilgan sanasi))
                  </div>
                </div>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                Keying o’rinlarda Talaba deb nomlanuvchi ikkinchi tomon birgalikda quyidagi mazmunda shartnomaga imzo chekishdi: 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <em>
                  <span style={{ fontSize: 12 }}>&nbsp;</span>
                </em>
              </p>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "262.25pt",
                padding: "0cm 5.4pt",
                verticalAlign: "top",
              }}
            >
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                  verticalAlign: "baseline",
                }}
              >
                <strong>&nbsp;</strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                  verticalAlign: "baseline",
                }}
              >
                <strong>
                  <span style={{ fontSize: 15 }}>1.</span>
                </strong>
                <span style={{ fontSize: 15 }}>&nbsp;</span>
                <strong>
                  <span style={{ fontSize: 15 }}>SUBJECT OF THE&nbsp;</span>
                </strong>
                <strong>
                  <span style={{ fontSize: 15 }}>CONTRACT</span>
                </strong>
                <span style={{ fontSize: 15 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <div
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <ol style={{ marginBottom: "0cm", listStyleType: "decimal" }}>
                  <li
                    style={{
                      margin: "0cm",
                      fontSize: 20,
                      fontFamily: '"Times New Roman",serif',
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                      1.1. The University undertakes to provide an educational service with the aim of obtaining higher education by a Student, in the area of specialization in accordance with the curriculum and programs of the University, and the Student shall be obliged to (study) get knowledge for educational services under the terms of this Contract.</span>
                  </li>
                </ol>
              </div>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>1.2. The duration of the Educational program is 4 years, and the validity of the contract at the time of signing is Four (4)year. Each academic year consists of 2 (two) semesters. Education at the University is carried only in English.</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  1.3. A student is enrolled in the University, in accordance with the Admission Rules of the University.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  1.4. After the Student has completed the educational program and successfully passed the final examination, he/she shall be awarded a standard Diploma of the Acharya the University, recognized as a document of education in the Republic of Uzbekistan.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <em>
                  <span style={{ fontSize: 7 }}>&nbsp;</span>
                </em>
              </p>
            </td>
            <td
              style={{
                width: "262.25pt",
                padding: "0cm 5.4pt",
                verticalAlign: "top",
              }}
            >
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  marginLeft: "36.0pt",
                  verticalAlign: "baseline",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>&nbsp;</span>
                </strong>
              </p>
              <p style={{ fontSize: 15, textAlign: "center" }}>
                <strong>1.SHARTNOMA MAVZUSI</strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  marginLeft: "36.0pt",
                  verticalAlign: "baseline",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>&nbsp;</span>
                </strong>
              </p>
              <div
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 16 }}>
                  1.1. Universitet Talabaga Universitet o’quv dasturlari va ixtisosliklari asosida oliy ta’lim xizmatlarini ko’rsatish majburiyatini o’z bo’yniga oladi. Talaba esa mazkur shartnoma shartlari asosida ko’rsatiluvchi ta’lim xizmatlariga bo’ysunadi (ta’lim oladi). 
                </span>
              </div>
              <p
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <div
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 16 }}>
                  1.2. Ta’lim dasturi davomiyligi to’rt (4) yil bo’lib, shartnoma imzolangan vaqtdan boshlab to’rt (4) yil mobaynida amal qiladi. Har bir o’quv yili ikki (2) semesterdan iborat bo’ladi. Universitetda darslar faqatgina Ingliz tilida olib boriladi. 
                </span>
              </div>
              <p
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                  background: "white",
                }}
              >
                <span style={{ fontSize: 16, color: "black" }}>
                  1.3. Talaba universitetga Qabul qilish tartib-qoidalari asosida talabalikka qabul qilinadi. 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                  background: "white",
                }}
              >
                <span style={{ fontSize: 16, color: "black" }}>
                  1.4. Ta’lim dasturini tamomlagan va bitiruv imtixonlaridan muvaffaqiyatli o’tgan talabalarga O’zbekiston Respublikasida tan olingan Acharya Universiteti Diplomi beriladi. 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "262.25pt",
                padding: "0cm 5.4pt",
                verticalAlign: "top",
              }}
            >
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>&nbsp;</span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>
                    2. Rights and obligations of the Parties
                  </span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>&nbsp;</span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>
                    2.1. The University is committed:
                  </span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.1.1 To enroll the student who has fulfilled the conditions of admission established in accordance with the Admission Rules,  requirements, and terms of the University on the basis of the decision of the University admission committee or/and by the order of the General Director
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.1.2 To organize and ensure the proper education of the Student in the direction of study/program in accordance with the current academic curriculum during the whole term of this Contract.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.1.3 To acquaint the Student during the period of conclusion of the present Contract with normative documents of the University like the Rules of internal regulations of the University.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.1.4. To accept the payment as provided by this Contract.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.1.5 To provide the Student information about the Student's progress, behavior, attitude towards the study in general and certain subjects of the curriculum and their attendance according to the study schedule.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.1.6. To provide the Student the right to use laboratory equipment, library facilities, sport and cultural facilities, medical service in the Student's medical office on common basis in accordance with the procedure established in the University.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <em>
                  <span style={{ fontSize: 12 }}>&nbsp;</span>
                </em>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.1.7. To issue a login and password to the student to enter the University Student ERP module
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 5 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 5 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.1.8. On completion of the studies and on meeting the academic requirements as per the academic policy of the University, the student shall be awarded the Diploma
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 8 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 8 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.1.9. The University assumes no obligation to employ the Student at the end of the term of study at the University.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.1.10. The University provides the student one Laptop for use in studies for the entire duration of the educational program of 4 years. The University provides the student a laptop in the second semester for use in studies.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>&nbsp;</span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>
                    2.2. The University has the right:
                  </span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.2.1 To independently carry out the educational process, choose the grading systems, the order and frequency of the intermediate assessment of the Student.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.2.2 To make changes to the approved schedule of classes without reducing the scope of educational services provided.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 8 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 8 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.2.3. Apply to the Student the measures of encouragement and disciplinary sanctions in accordance with the legislation of the Republic of Uzbekistan, the Charter, the Internal Regulations of the University and this Contract.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.2.4. To carry out continuous monitoring on the accomplishment by the student of his/her obligations.
                </span>
              </p>
            </td>
            <td
              style={{
                width: "262.25pt",
                padding: "0cm 5.4pt",
                verticalAlign: "top",
              }}
            >
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>&nbsp;</span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>
                    2. omonlarning Huquq va Majburiyatlari
                  </span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>&nbsp;</span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>
                    2.1 Universitet quyidagi majburiyatlarni o’z zimmasiga oladi:
                  </span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.1.1. Universitet qabul komissiyasi qarori va Bosh Ijrochi Direktor buyrug’i asosida talabalikka qabul qilish tartib-qoidalari asosida qabul shartlarini bajargan talabalarni talabalikka qabul qilish.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.1.2. Mazkur shartnomaning butun amal qilish muddati mobaynida Talabaga tegishli o’quv reja asosidagi ta’lim/ixtisoslik yo’nalishi bo’yicha ta’lim berish jarayonini tashkil etish va amalga oshirish. 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.1.3. Mazkur shartnomani tuzish asnosida Talabani universitet ichki tartib-qoidalari kabi Universitet faoliyatiga oid normativ-hujjatlar bilan tanishtirish. 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.1.4. Mazkur shartnomada nazarda tutilgan ta’lim uchun to’lovni qabul qilish. 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.1.5. Talabaga talabaning ta’lim olish jarayonidagi natijalari, xulq-atvori, o’quv jadvaliga muvofiq fanlarni o’zlashtirishi va belgilangan dars jadvali asosida darslarga qatnashi borasida ma’lumot berib turish. 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.1.6. Talabaga Universitet tomonidan belgilangan umumiy tartib-qoidalar asosida laboratoriya jihozlari, kutubxona imkoniyatlari, sport va madaniy inshootlar, talabalar tibbiyot punktidagi tibbiy xizmatlardan foydalanish huquqini taqdim etish. 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.1.7. Talabalarning Universitet ERP tizimidan foydalanishi uchun login va parollarni taqdim etish.  
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 8 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>2.1.8. O’qishni tugatganidan keyin va Universitet akademik tartib-qoidalarida belgilangan barcha majburiyatlarni bajargan taqdirda talabaga Diplom berish. 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 8 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  marginTop: "2cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.1.9.  Universitet Talabani universitetdagi o’qish davri tugaganidan keyin ish bilan ta’minlashni o’z bo’yniga olmaydi.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.1.10. Universitet talabaga 4 yillik ta’lim dasturi mobaynida foydalanishi uchun bitta noutbuk taqdim etadi. Universitet tomonidan mazkur noutbuk talabaga o’quv maqsadlarida foydalanishini ko’zlab ikkinchi semesterda taqdim etiladi.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>&nbsp;</span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>
                    2.2.Universitetning huqulari:
                  </span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.2.1. Ta’lim jarayonini amalga oshirish, baholash tizimlarini tanlash, talabaning oraliq baholash jarayonlari borasidagi tartib va qoidalarini mustaqil ravishda amalga oshiradi. 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.2.2. Tasdiqlangan dars jadvaliga ta’lim xizmatlarini qisqartirmay tegishli o’zgarishlar kiritish. 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.2.3. Talabaga nisbatan O‘zbekiston Respublikasi qonunchiligi, Ustav, Universitet ichki tartib qoidalari va mazkur Shartnomaga muvofiq rag‘batlantirishlar va intizomiy jazo choralarini qo‘llash.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.2.4. Talabaning majburiyatlarini amalga oshirishini muntazam ravishda nazorat qilib borish. 
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "262.25pt",
                padding: "0cm 5.4pt",
                verticalAlign: "top",
              }}
            >
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.2.5. To restrict the access of the Student to the University building, to educational resources and examinations in case of violation of obligations and/or missing the required number of academic hours. The number of academic hours required for the admission of the Student to examinations is regulated by the internal rules of the University.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.2.6. The University has the right to dismiss the Student on the following reasons:
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 11 }}>&nbsp;</span>
              </p>
              <div
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <ul
                  style={{
                    marginBottom: "0cm",
                    listStyleType: "undefined",
                    marginLeft: "12.850000000000001px",
                  }}
                >
                  <li
                    style={{
                      margin: "0cm",
                      fontSize: 20,
                      fontFamily: '"Times New Roman",serif',
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                    on the Student's own initiative (in writing);
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      fontSize: 20,
                      fontFamily: '"Times New Roman",serif',
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                    for violation of academic discipline and internal regulations of the University.
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      fontSize: 20,
                      fontFamily: '"Times New Roman",serif',
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                    for academic failure (credit debt);
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      fontSize: 20,
                      fontFamily: '"Times New Roman",serif',
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                      in connection with a transfer to another University.  
                      &nbsp;
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      fontSize: 20,
                      fontFamily: '"Times New Roman",serif',
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                    for health reasons (on the basis of a certificate from the medical board);
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      fontSize: 20,
                      fontFamily: '"Times New Roman",serif',
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                    in connection with deprivation of liberty by court order.
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      fontSize: 20,
                      fontFamily: '"Times New Roman",serif',
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                    in connection with death;
                    </span>
                    <span style={{ fontSize: 16 }}>;</span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      fontSize: 20,
                      fontFamily: '"Times New Roman",serif',
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                    more than 1(one) month of absence without a proper reason during one semester.
                    </span>
                  </li>
                </ul>
              </div>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                The student is considered to have been expelled from the University from the date determined by the relevant order.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.2.7. To perform other rights stipulated by the current legislation of the Republic of Uzbekistan, normative-legal and local documents of the University.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 7 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>&nbsp;</span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>
                    2.3. The student is obliged:
                  </span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.3.1. To study the educational program in accordance with the curriculum and the schedule of the educational process, to meet the requirements of the educational program, the established scope of study load and self-study.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.3.2. To comply with the requirements of laws, regulations in the field of education, the Charter and local regulations of the University, as well as the internal regulations of the University.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 7 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 7 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.3.3. To attend classes according to the study schedule. To wear the uniform of the University established standard, to have a neat appearance.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.3.4. To receive a login and password to enter the personal mailbox of the University e-mail. /Student ERP Portal.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.3.5. Notify the University in writing of proper reasons for your absence from classes.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.3.6. Take care of the University property. To compensate for damage caused to the property in accordance with the legislation and local regulations of the University.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 4 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.5.7. Upon termination of educational relations on its own initiative, to notify the University in writing in advance.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 8 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.5.8 When entering the University and during the course of study to provide all necessary documents in time. Within 3 (three) days to inform about changes in his/her data to the University academic department in writing.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>

              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 9 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.3.9. In case of academic failure during study to pay for additional services to retake the exams, repeat studies on the basis of separate contracts in accordance with tariffs approved by the University.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.3.10. To fulfill other conditions and guarantees by Contract of the Parties.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.3.11. In the case of making damages to the University property, the student, as per the rules of the University, undertakes to compensate the damage in full at his/her own expense within 10 days. In case of refusal of the student from compensation, due to whose fault the damage was caused, the University has the right to initiate Legal proceedings against the student as per the internal rules of the University and as per the Laws of the Republic of Uzbekistan.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.3.12. The student undertakes not to disseminate inaccurate, false information, criticism and other illegal actions in the media, in the Internet publications and social networks, defaming the image and reputation of the University.
                </span>
              </p>
              <p
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>2.5.13 The Student shall maintain/repair the laptop issued by the University at his/her own expense in case of damage. If the student losses the Laptop issued by the University he/she shall be re-issued a new laptop after reimbursing (making payment) of 6250000 (six million two hundred fifty thousand) sum for the lost Laptop. 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                If the Student discontinues his education program of 4 years with the University for any reason or is rusticated/dismissed from the University for any disciplinary reasons, the Student shall reimburse (pay) 6250000 (six million two hundred fifty thousand) sum within 3 working days for laptop and keep the laptop for himself/herself.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                If the Student successfully completes the four-year educational program in accordance with the procedure established by the University, the laptop shall become the property of the Student and be at his/her disposal.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <strong>
                  <span style={{ fontSize: 7 }}>&nbsp;</span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>
                  2.4. The Student has the right:
                  </span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.4.1. To receive complete and reliable information on assessment of his knowledge, skills and abilities, as well as the criteria for this assessment on issues related to the process of study at the University.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.4.2. To use the property of the University, necessary to learn the educational program during the classes provided by the academic schedule.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.4.3. To use additional educational services that are not included in the educational program based on a separately concluded Contract.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.4.4. Address employees of the University on issues related to the study process at the University.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.4.5. To take part in social-cultural and other events organized for the University students.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.4.6. To terminate educational relations before the due date on its own initiative on the condition and on the terms established by this agreement and rules, orders of the University.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.4.7. In case of failure in the process of study to provide payment for additional services to retake the exams, repeat education in accordance with tariffs approved by the University.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 8 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.4.8. Exercise other rights stipulated by the current legislation of the Republic of Uzbekistan, regulations, and local documents of the University.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <em>
                  <span style={{ fontSize: 12 }}>&nbsp;</span>
                </em>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 12 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 12 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>
                    3. The cost of educational services, terms, and payment procedure.
                  </span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <strong>
                  <span style={{ fontSize: 7 }}>&nbsp;</span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>3.1. The cost (education fee) of one academic year under this Contract is 27.900.000 (twenty seven million nine hundred thousand) UZS. The Student will be given a grant of 6 (six) million UZS in the first year. The grant will be continued to the 2nd, 3rd and 4th year conditioned to the students meeting the academic requirement of 85% attendance and 8.5 GPA in average from the 2nd semester. The university holds the right to cancel the scholarship band on the student’s academic performance or discipline.  </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  3.2. The Student is obliged to pay the amount annually for the respective academic year by transferring funds to the current account of the University in the following order:
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16, color: "red" }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                50% of the cost (education fee) of the annual contract is paid before the start of the first academic semester.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  &nbsp;The remaining 50% of the cost of the annual contract is paid before the start of the second academic semester.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>3.3 The student has the right to inform the intention to withdraw and terminate this contract before the commencement of classes and demand refund payment. In this case, the University within 10 working days returns the money with the right of retention (deduction) 10%. If the student informs about his decision to stop education and withdraw, after the commencement of classes, the University is not liable to make any refund.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  3.4. The university, in accordance with internal rules and the decision of the director, has the right provide some discounts and grants.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ color: "red" }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 16 }}>
                  3.5. All tuition fees are to be paid by the Student.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                &nbsp;
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  3.6. The Parties understand and accept that Tuition fees may be increased by up to 10% each academic year. When changing the amount of tuition fees, the University notifies the Parties 10 (ten) business days before the proposed change by issuing a relevant order and posting it on the relevant websites of the University. If necessary, the Student  undertakes to conclude additional agreement within the period specified by the university.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  3.7. Payment for Services (transfer of funds) is made in accordance with present contract and the current legislation of the Republic of Uzbekistan on the details specified in this Contract.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 12 }}>&nbsp;</span>
              </p>
            </td>
            <td
              style={{
                width: "262.25pt",
                padding: "0cm 5.4pt",
                verticalAlign: "top",
              }}
            >
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.2.5. Majburiyatlarni bajarmagan yoki talab qilingan o’quv soatlari borasida tegishli davomat talablarini bajarmagan talabalarning Universitet binosiga, ta’lim resurslari va sinovlariga kirishiga cheklovlar qo’yish. Talabaning imtixonlarda ishtirok etishi uchun talab qilinadigan o’quv soatlari miqdori Universitet ichki tartib-qoidalari asosida belgilanadi.    
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.2.6. Universitet quyidagi sabalarga ko’ra Talabani Universitet talabalari safidan chetlatish huquqiga ega: 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <div
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <ul
                  style={{
                    marginBottom: "0cm",
                    listStyleType: "undefined",
                    marginLeft: "12.649999999999999px",
                  }}
                >
                  <li
                    style={{
                      margin: "0cm",
                      fontSize: 20,
                      fontFamily: '"Times New Roman",serif',
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                    Talabaning shaxsiy tashabbusiga ko’ra (yozma shaklda);
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      fontSize: 20,
                      fontFamily: '"Times New Roman",serif',
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                    O’quv intizomi va Universitet ichki tartib-qoidalarini buzganlik uchun;
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      fontSize: 20,
                      fontFamily: '"Times New Roman",serif',
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                    Akademik qarzdorlik uchun (kredit qarzdorligi);
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      fontSize: 20,
                      fontFamily: '"Times New Roman",serif',
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                    O’qishini boshqa universitetga ko’chirishi munosabati bilan
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      fontSize: 20,
                      fontFamily: '"Times New Roman",serif',
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                    Tibbiy sabablarga ko’ra (tegishli tibbiyot muassasasining tibbiy xulosasiga ko’ra)
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      fontSize: 20,
                      fontFamily: '"Times New Roman",serif',
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                    Sud qaroriga asosan ozodlikdan mahrum etilganda;
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      fontSize: 20,
                      fontFamily: '"Times New Roman",serif',
                    }}
                  >
                    <span style={{ fontSize: 16 }}>Vafot etgani sababli.</span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      fontSize: 20,
                      fontFamily: '"Times New Roman",serif',
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                    Bir semester mobaynida sababsiz bir oydan ortiq muddatda darslarda sababsiz ishtirok etmagan taqdirda 
                    </span>
                  </li>
                </ul>
              </div>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                Talabalar safidan chetlatish borasidagi tegishli buyruq chiqqan sanadan boshlab Universitet talabaligidan chetlashtiriladi. 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 11 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.2.7. O’zbekiston Respublikasi qonunchiligi, normativ-huquqiy va universitet ichki hujjatlari asosida boshqa huquqlarni amalga oshirish. 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>
                    2.3. Talabaning majburiyatlari:
                  </span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.3.1. O'quv jarayonining belgilangan o'quv rejasi va jadvaliga muvofiq o'quv dasturini o'zlashtirish, o'quv rejasi talablarini, belgilangan o'quv yuklamasi hajmlarini va mustaqil ishlarni bajarish.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 8 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.3.2. Qonunlar, ta'limga oid me'yoriy hujjatlar, Universitet nizomi va mahalliy normativ hujjatlar, shuningdek Universitetning ichki tartib-qoidalari talablariga rioya qilish.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 7 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.3.3.  Dars jadvaliga asosan darslarga qatnashish. Universitet tomonidan o’rnatilgan kiyinish tartib-qoidalariga amal qilish, chiroyli ko’rinishda kiyinish. 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16, color: "black" }}>
                  2.3.4. Universitet tomonidan berilgan shaxsiy elektron pochta/Talabalar ERP portali uchun login va parollarni olish. 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.3.5. Qoldirilgan darslari uchun universitetga yozma sabablarni taqdim qilish. 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.3.6. Universitet mulkidan ehtiyotkorlik bilan foydalanish. Qonun doirasida va universitet ichki tartib-qoidalari asosida mulkka yetkazilgan zararni qoplash.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16, color: "black" }}>
                  2.3.7. O’z xoxishiga ko’ra universitetdagi ta’limni to’xtatish borasida yozma ravishda universitetni ogohlantirish. 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.3.8. Universitetga kirishda va o’qish davrida barcha zarur hujjatlarni taqdim qilish. Shaxsiy ma’lumotlaridagi o’zgarish borasida 3 (uch) kun ichida universitet ma’muriyatiga ma’lum qilish. 
                </span>
              </p>

              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.3.9. O’quv jaronida fanlarni o’zlashtira olmagan taqdirda qo’shimcha imtixonlarda ishtirok etish yoki qayta o’qish uchun Universitet tomonidan belgilab berilgan narxlar asosida qo’shimcha haq to’lash.  
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.3.10. Tomonlar o’rtasidagi boshqa kelishuv va shartlarga amal qilish. 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.3.11. Universitet mulkiga zarar yetkazilgan taqdirda talaba Universitet qoidalariga muvofiq yetkazilgan zararni 10 kun ichida o‘z mablag‘lari hisobidan to‘liq qoplash majburiyatini oladi Universitet Talaba yetkazilgan zararni qoplashdan  zarardan bosh tortgan taqdirda Universitet ichki tartib-qoidalari va O‘zbekiston Respublikasi qonunchiligiga muvofiq talabaga nisbatan sudga murojaat qilishga haqli.
                </span>
              </p>
              <p
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 5 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.3.12. Talaba OAV, internet nashrlari va ijtimoiy tarmoqlarda ishonchsiz, yolg‘on ma’lumotlarni tarqatmaslik, tanqid qilish va Universitet obro‘siga putur yetkazuvchi boshqa noqonuniy hatti-harakatlarga yo‘l qo‘ymaslik majburiyatini oladi.
                </span>
              </p>
              <p
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16, color: "black" }}>&nbsp;</span>
              </p>
              <p
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16, color: "black" }}>&nbsp;</span>
              </p>
              <p
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.5.13 Talaba universitet tomonidan berilgan noutbukga shikast yetgan taqdirda texnik xizmat ko‘rsatish/ta’mirlash ishlarini o‘z hisobidan amalga oshiradi. Agar talaba Universitet tomonidan berilgan noutbukni yo‘qotib qo‘ysa, talaba tomonidan 6 250 000(olti million ikki yuz ellik ming) so’m to‘langanidan keyin unga yangi noutbuk beriladi.
                </span>
              </p>
              <p
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                Agar talaba universitetdagi 4 yillik o‘qish dasturini biron-bir sababga ko‘ra to‘xtatsa yoki intizomiy sabablarga ko‘ra universitetdan talabalari safidan chetlatilgan taqdirda, talaba 3 ish kuni doirasida 6 250 000(olti million ikki yuz ellik ming) so’m miqdoridagi noutbuk summasini qaytarishi (to‘lashi) shart va shu orqali noutbukni o’ziga qoldirishi mumkin. shart.
                </span>
              </p>
              <p
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                Agar talaba Universitet tomonidan belgilangan tartibda to‘rt yillik ta’lim dasturini muvaffaqiyatli tamomlasa, noutbuk talabaning shaxsiy mulkiga aylanadi va uning ixtiyorida bo‘ladi.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <strong>
                  <span style={{ fontSize: 7 }}>&nbsp;</span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>
                    2.4. Talaba quyidagi huquqlarga ega:
                  </span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.4.1. O’qishi davrida o’zlashtirishi, bilim-ko’nikmalari va iqtidorining baholanishi, shu bilan birgalikda Universitet o’quv jarayonidagi baholash mezonlari masalasida batafsil va ishonchli ma’lumotlarni olish. 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.4.2. Dars jadvali asosida belgilangan sinf xonalarida zarur bo’lgan ta’limni olish mobaynida Universitet mulkidan foydalanish. 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.4.3. Ta’lim dasturiga kiritilmagan qo’shimcha xizmatlar uchun alohida shartnoma tuzish. 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.4.4. Universitetdagi o'quv jarayoniga oid savollar bilan universitet xodimlariga murojaat qilish
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  2.4.5. Universitet talabalari uchun tashkil etilgan ijtimoiy-madaniy v.h.k tadbirlarda ishtirok etish. 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16, color: "black" }}>
                  2.4.6. O'z tashabbusi bilan ushbu shartnomada hamda Universitet qoidalari va buyruqlarida belgilangan tartibda va shartlar asosida ta'lim munosabatlarini muddatidan oldin tugatish.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16, color: "black" }}>
                  2.4.7. O'quv jarayonida dasturni o’zlashtira olmagan taqdirda, Universitet tomonidan tasdiqlangan tariflarga muvofiq imtihonlarni qayta topshirish, qayta tayyorlash bo'yicha qo'shimcha xizmatlar uchun haq to'lashni ta'minlash.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 7, color: "black" }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16, color: "black" }}>
                  2.4.8. O‘zbekiston Respublikasining amaldagi qonunchiligida, Universitetning me’yoriy va mahalliy hujjatlarida nazarda tutilgan boshqa huquqlarni amalga oshirish.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16, color: "black" }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16, color: "black" }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>
                    3. Ta’lim xizmatlari narxi, ularni to’lash shartlari va tartibi 
                  </span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <strong>
                  <span style={{ fontSize: 7 }}>&nbsp;</span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 16 }}>
                  3.1. Ushbu Shartnoma bo'yicha yillik o'qitish qiymati 27 900 000 (yigirma yetti million to'qqiz yuz ming) so'mni tashkil qiladi.  Talabaga birinchi o’quv yilida 6 (olti) million so’m miqdoridagi grant beriladi. Ushbu grant talabaga 2-3-4-kurslarda ham taqdim etilishi mumkin. Buning uchun talaba tomonidan 2-semester yakuniga ko’ra 85 foizlik davomat  va 8.5 ballik GPA ko’rsatkichlariga ega bo’lish talab qilinadi. Talaba tomonidan akademik o’zlashtirishi yoki intizomiy harakatlar sodir etilgan holatlarda universitet tomonidan mazkur grant to’xtatilishi mumkin.  
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16, color: "black" }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span
                  style={{ fontSize: 16, color: "black", background: "white" }}
                >
                  3.2. Talaba har yili tegishli o'quv yili uchun pul mablag'larini Universitet hisob raqamiga quyidagi tartibda o'tkazish orqali to'lashi shart:
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span
                  style={{ fontSize: 16, color: "black", background: "white" }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span
                  style={{ fontSize: 16, color: "black", background: "white" }}
                >
                  To’lov-shartnoma miqdorining 50% qismi o’quv yilining birinchi semester boshlanguniga qadar to’lanadi. 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <strong>
                  <em>
                    <span
                      style={{
                        fontSize: 16,
                        color: "black",
                        background: "white",
                      }}
                    >
                      &nbsp;
                    </span>
                  </em>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span
                  style={{ fontSize: 16, color: "black", background: "white" }}
                >
                  Qolgan 50% qismi esa ikkinchi semester boshlanguniga qadar to’lanadi. 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span
                  style={{ fontSize: 16, color: "black", background: "white" }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span
                  style={{ fontSize: 16, color: "black", background: "white" }}
                >
                  3.3 Talaba o'qish (darslar) boshlanishidan oldin ushbu shartnomadan chiqish va uni bekor qilish niyati to'g'risida xabar berishga va to'langan pul mablag'larini qaytarishni talab qilishga haqlidir. Bunday holda, Universitet 10 ish kuni ichida mablag'ni 10% ushlab qolish huquqi bilan qaytaradi. Agar talaba mashg'ulotlar (treyning) boshlanganidan keyin o'qishni tark etish va keyingi o'qishni davom ettirmaslik to'g'risida qaror qabul qilganligi haqida xabar bersa, to'langan summa qaytarilmaydi.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span
                  style={{ fontSize: 16, color: "black", background: "white" }}
                >
                  3.4. Universitet ichki tartib-qoidalarga va direktor qaroriga muvofiq talabalarga ma’lum chegirmalar va grantlar ham berish huquqiga ega.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span
                  style={{ fontSize: 16, color: "black", background: "white" }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span
                  style={{ fontSize: 16, color: "black", background: "white" }}
                >
                  3.5. Barcha o'quv to'lovlari Talabaning zimmasida.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 5 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  3.6. Tomonlar o’quv shartnoma pullari har o’quv yilida  10%gacha oshirilishi mumkinligini tushunib, inobatga oladilar. To’lov-shartnoma miqdori oshirilgan taqdirda Universitet ma’muriyati tomonidan bu haqida tegishli buyruq chiqarilib, universitetning ijtimoiy sahifalarida e’lon qilinadi. Zarur holatda Talaba Universitet ko’rsatib o’tgan  muddatda qo’shimcha shartnoma tuzish majburiyatini oladilar. 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16, color: "black" }}>
                  3.7. Xizmatlar uchun haq to’lash (pul mablag’larini o’tkazish) mazkur shartnomaga asosan va O’zbekiston Respublikasi amaldagi qonunchiligiga muvofiq ushbu shartnomada ko‘rsatilgan rekvizitlardan foydalangan holda amalga oshiriladi. 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "262.25pt",
                padding: "0cm 5.4pt",
                verticalAlign: "top",
              }}
            >
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>&nbsp;</span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>
                    4. Responsibility of the Parties
                  </span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  4.1. For non-fulfillment and improper fulfillment of their obligations under the Contract, the Parties shall bear responsibility stipulated by the Legislation of the Republic of Uzbekistan.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  4.2. The student is responsible for violations of the rules established at the University and the terms of this agreement. If the Student will spread (send out) through the media, Internet publications and social networks, inaccurate, false information about the University, and will make illegal criticism and other illegal actions defaming the image and reputation of the University, by the decision of the Director of the University, the student may be expelled (dismiss) from studies without refund of the paid amount for studying.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                className="page-break"
                style={{
                  marginTop: "5cm",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  4.3. In case of late payment for tuition the Student shall pay to the University a penalty in the amount of 0,04% of the overdue payment amount for each day of delay, but not more than 20% of the overdue payment amount.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 8 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 7 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  4.4. Measures of liability for violation of the obligations of the Parties not stipulated in this Contract shall be applied in accordance with the Legislation of the Republic of Uzbekistan.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <em>
                  <span style={{ fontSize: 12 }}>&nbsp;</span>
                </em>
              </p>
            </td>
            <td
              style={{
                width: "262.25pt",
                padding: "0cm 5.4pt",
                verticalAlign: "top",
              }}
            >
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  marginLeft: "54.0pt",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>&nbsp;</span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  marginLeft: "54.0pt",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>
                    4. Tomonlarning javobgarligi 
                  </span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  4.1. Shartnoma bo‘yicha o‘z majburiyatlarini bajarmaganliklari va lozim darajada bajarmaganliklari uchun Tomonlar O‘zbekiston Respublikasi qonunchiligiga muvofiq javobgar bo‘ladilar.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 8 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 8 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  4.2. Talaba Universitet tomonidan o’rnatilgan tartib-qoidalarni va ushbu shartnoma shartlarini buzganlik uchun javobgar bo’ladi. Talaba OAVda, internet nashrlarida va ijtimoiy tarmoqlarda Universitet toʻgʻrisida notoʻgʻri, asoslanmagan maʼlumotlarni tarqatsa, universitet imidji va obroʻsiga putur yetkazuvchi tanqidiy va boshqa noqonuniy hatti-harakatlarni amalga oshirsa, universitet direktorining qarori bilan talaba o'qish uchun to'langan pulni qaytarmasdan o'qishdan chetlatish mumkin.
                </span>
              </p>
              <p
                className="page-break"
                style={{
                  marginTop: "5cm",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16, color: "black" }}>
                  4.3. O’qish to’lovi kechikkan taqdirda Talaba tomonidan kechiktirilgan har bir kun uchun universitetga 0,04% miqdorida umumiy 20%dan oshmagan miqdorda penya to’laydi. 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  4.4. Tomonlarning ushbu Shartnomada nazarda tutilmagan majburiyatlarini buzganlik holatlari uchun jarimalar O‘zbekiston Respublikasi qonunchiligiga muvofiq qo‘llaniladi..
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "262.25pt",
                padding: "0cm 5.4pt",
                verticalAlign: "top",
              }}
            >
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>5. Force Majeure</span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  5.1. The Parties are released from responsibility for failure to perform or improper performance of their obligations under this Contract if such failure was caused by force majeure. Such circumstances and/or events are defined as the force-majeure circumstances if they are beyond the control of the Party and have arisen after signing this Contract as a result of inevitable circumstances, military actions or natural disasters.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 8 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 8 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 8 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 8 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 8 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  5.2. The Party for which the inability to fulfill its obligations due to the circumstances and/or force-majeure events has arisen shall immediately inform the other Parties and submit the documents of the relevant authorities competent to confirm the fact of the circumstances for the entire period of the circumstances and/or force-majeure events.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>
                    6. Dispute resolution procedure
                  </span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  6.1. Disputes and disagreements that may arise in the performance of this Contract will be resolved through negotiations between the Parties, if possible.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  6.2. In case of impossibility to settle disputes through negotiations, the Parties, after implementation of the procedure of pre-trial settlement of disputes stipulated by the legislation, shall submit them to the Karakul regional Civil Court, for consideration in accordance with the procedure established by the legislation of the Republic of Uzbekistan.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <strong>
                  <span style={{ fontSize: 7 }}>&nbsp;</span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <em>
                  <span style={{ fontSize: 12 }}>&nbsp;</span>
                </em>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <em>
                  <span style={{ fontSize: 12 }}>&nbsp;</span>
                </em>
              </p>
            </td>
            <td
              style={{
                width: "262.25pt",
                padding: "0cm 5.4pt",
                verticalAlign: "top",
              }}
            >
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>5. Fors-major</span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  5.1. Fors-major holatlari (fors-major) sabab Tomonlar ushbu Shartnoma bo'yicha o'z majburiyatlarini to’liq bajarmaganliklari yoki lozim darajada bajarmaganliklari uchun javobgarlikdan ozod qilinadilar. Ushbu shartnoma imzolanganidan keyin Tomonlar ixtiyoridan chetda bo’lgan harbiy harakatlar, tabiiy ofatlar kabi voqea-hodisalar natijasida yuzaga kelgan imkonsiz holatlar fors-major holati deyiladi. 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  5.2. Yuqorida aytib o’tilgan yoki fors-major holatlar sabab o’z majburiyatlarini bajara olmagan taqdirda yoki vaziyatning butun davri uchun tomonlar bu haqida tegishli faktlar bilan vakolatli organlarning hujjatlarini bir-biriga taqdim qilishlari lozim. 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>
                    6. Nizolarni hal qilish tartibi
                  </span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  6.1. Ushbu Shartnomani bajarish jarayonida yuzaga kelishi mumkin bo'lgan nizolar va kelishmovchiliklar imkon qadar Tomonlar o'rtasidagi muzokaralar yo'li bilan hal qilinadi.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  6.2. Agar nizolarni muzokaralar yo‘li bilan hal etishning iloji bo‘lmasa, tomonlar qonun hujjatlarida nazarda tutilgan kelishmovchiliklarni sudgacha hal qilish tartibini amalga oshirgandan so‘ng ularni O’zbekiston Respublikasi qonun hujjatlarida belgilangan tartibda Fuqarolik ishlari bo‘yicha Qorako’l tumanlararo sudiga ko‘rib chiqish uchun taqdim etadilar. 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "262.25pt",
                padding: "0cm 5.4pt",
                verticalAlign: "top",
              }}
            >
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>7. Final provisions</span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  7.1. This Agreement shall enter into force on the date of its conclusion by the Parties and shall be valid until the Parties fulfill their obligations in full.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  7.2. The amendments and supplements to this Contract shall be executed only in written form and signed by the authorized representatives of the Parties.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  7.3 The present Contract is drawn up and signed in three (3) copies, in Uzbek and English. In case of discrepancies between the Uzbek and English versions, the English version of the Contract shall prevail.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <em>
                  <span style={{ fontSize: 12 }}>&nbsp;</span>
                </em>
              </p>
            </td>
            <td
              style={{
                width: "262.25pt",
                padding: "0cm 5.4pt",
                verticalAlign: "top",
              }}
            >
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <strong>
                  <span
                    style={{
                      fontSize: 16,
                      color: "black",
                      background: "white",
                    }}
                  >
                    7. Yakuniy qoidalar
                  </span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  7.1. Ushbu Shartnoma Tomonlar tomonidan tuzilgan kundan boshlab kuchga kiradi va Tomonlar o'z majburiyatlarini to'liq bajarmaguncha amal qiladi.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  7.2. Ushbu Shartnomaga o'zgartirish va qo'shimchalar faqat yozma shaklda kiritiladi va Tomonlarning vakolatli vakillari tomonidan imzolanadi.
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                  7.3. Ushbu Shartnoma o’zbek va ingliz tillarida uch (3) nusxada tuzilgan va imzolangan. O’z va ingliz tilidagi shakllari o'rtasida ziddiyatlar yuzaga kelgan taqdirda, Shartnomaning ingliz tilidagi qismi ustunlik qiladi.
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "262.25pt",
                padding: "0cm 5.4pt",
                verticalAlign: "top",
              }}
            >
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <em>
                  <span style={{ fontSize: 12 }}>&nbsp;</span>
                </em>
              </p>
            </td>
            <td
              style={{
                width: "262.25pt",
                padding: "0cm 5.4pt",
                verticalAlign: "top",
              }}
            >
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              colSpan={2}
              style={{
                width: "524.5pt",
                padding: "0cm 5.4pt",
                height: "2.5pt",
                verticalAlign: "top",
              }}
            >
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p
        style={{
          margin: "0cm",
          fontSize: 20,
          fontFamily: '"Times New Roman",serif',
          marginLeft: "18.0pt",
          textAlign: "center",
        }}
      >
        <strong>
          <span style={{ fontSize: 15 }}>&nbsp;</span>
        </strong>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: 20,
          fontFamily: '"Times New Roman",serif',
          marginLeft: "18.0pt",
          textAlign: "center",
        }}
      >
        <strong>
          <span style={{ fontSize: 15 }}>&nbsp;</span>
        </strong>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: 20,
          fontFamily: '"Times New Roman",serif',
          marginLeft: "18.0pt",
          textAlign: "center",
        }}
      >
        <strong>
          <span style={{ fontSize: 15 }}>&nbsp;</span>
        </strong>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: 20,
          fontFamily: '"Times New Roman",serif',
          marginLeft: "18.0pt",
          textAlign: "center",
        }}
      >
        <strong>
          <span style={{ fontSize: 15 }}>&nbsp;</span>
        </strong>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: 20,
          fontFamily: '"Times New Roman",serif',
          marginLeft: "18.0pt",
          textAlign: "center",
        }}
      >
        <strong>
          <span style={{ fontSize: 15 }}>&nbsp;</span>
        </strong>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: 20,
          fontFamily: '"Times New Roman",serif',
          marginLeft: "18.0pt",
          textAlign: "center",
        }}
      >
        <strong>
          <span style={{ fontSize: 15 }}>&nbsp;</span>
        </strong>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: 20,
          fontFamily: '"Times New Roman",serif',
          marginLeft: "18.0pt",
          textAlign: "center",
        }}
      >
        <strong>
          <span style={{ fontSize: 15 }}>&nbsp;</span>
        </strong>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: 20,
          fontFamily: '"Times New Roman",serif',
          marginLeft: "18.0pt",
          textAlign: "center",
        }}
      >
        <strong>
          <span style={{ fontSize: 15 }}>&nbsp;</span>
        </strong>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: 20,
          fontFamily: '"Times New Roman",serif',
          marginLeft: "18.0pt",
          textAlign: "center",
        }}
      >
        <strong>
          <span style={{ fontSize: 15 }}>&nbsp;</span>
        </strong>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: 20,
          fontFamily: '"Times New Roman",serif',
          marginLeft: "18.0pt",
          textAlign: "center",
        }}
      >
        <strong>
          <span style={{ fontSize: 15 }}>&nbsp;</span>
        </strong>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: 20,
          fontFamily: '"Times New Roman",serif',
          marginLeft: "18.0pt",
          textAlign: "center",
        }}
      >
        <strong>
          <span style={{ fontSize: 15 }}>&nbsp;</span>
        </strong>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: 20,
          fontFamily: '"Times New Roman",serif',
          marginLeft: "18.0pt",
          textAlign: "center",
        }}
      >
        <strong>
          <span style={{ fontSize: 15 }}>&nbsp;</span>
        </strong>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: 20,
          fontFamily: '"Times New Roman",serif',
          marginLeft: "18.0pt",
          textAlign: "center",
          marginTop: "6cm",
        }}
      >
        <strong>
          <span style={{ fontSize: 15 }}>8.&nbsp;</span>
        </strong>
        <strong>
          <span style={{ fontSize: 15 }}>TOMONLARNING YURIDIK MANZILLARI</span>
        </strong>
        <strong>
          <span style={{ fontSize: 15 }}>
            : / 8. LEGAL ADRESSES OF THE PARTIES:
          </span>
        </strong>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: 20,
          fontFamily: '"Times New Roman",serif',
        }}
      >
        <span style={{ fontSize: 1 }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: 20,
          fontFamily: '"Times New Roman",serif',
        }}
      >
        <span style={{ fontSize: 1 }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: 20,
          fontFamily: '"Times New Roman",serif',
        }}
      >
        <span style={{ fontSize: 1 }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: 20,
          fontFamily: '"Times New Roman",serif',
        }}
      >
        <span style={{ fontSize: 1 }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: 20,
          fontFamily: '"Times New Roman",serif',
        }}
      >
        <span style={{ fontSize: 1 }}>&nbsp;</span>
      </p>
      <table
        style={{
          float: "left",
          width: "90vw",
          border: "none",
          borderCollapse: "collapse",
          marginLeft: "6.75pt",
          marginRight: "6.75pt",
          marginBottom: "10pt",
        }}
      >
        <tbody>
          <tr>
            <td
              style={{
                width: "191.15pt",
                border: "1pt solid windowtext",
                padding: "0cm 5.4pt",
                height: "11.8pt",
                verticalAlign: "top",
              }}
            >
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>Университет /&nbsp;</span>
                </strong>
                <strong>
                  <span style={{ fontSize: 16 }}>UNIVERSITY</span>
                </strong>
              </p>
            </td>
            <td
              style={{
                width: "162.95pt",
                borderTop: "1pt solid windowtext",
                borderRight: "1pt solid windowtext",
                borderBottom: "1pt solid windowtext",
                borderImage: "initial",
                borderLeft: "none",
                padding: "0cm 5.4pt",
                height: "11.8pt",
                verticalAlign: "top",
              }}
            >
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>Talaba /&nbsp;</span>
                </strong>
                <strong>
                  <span style={{ fontSize: 16 }}>STUDENT</span>
                </strong>
              </p>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "191.15pt",
                borderRight: "1pt solid windowtext",
                borderBottom: "1pt solid windowtext",
                borderLeft: "1pt solid windowtext",
                borderImage: "initial",
                borderTop: "none",
                padding: "0cm 5.4pt",
                height: "76.1pt",
                verticalAlign: "top",
              }}
            >
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>Acharya universiteti</span>
                </strong>
                <strong>
                  <span style={{ fontSize: 16 }}>
                    /<br />
                    &nbsp;
                  </span>
                </strong>
                <strong>
                  <span style={{ fontSize: 16 }}>Acharya</span>
                </strong>
                <strong>
                  <span style={{ fontSize: 16 }}>&nbsp;</span>
                </strong>
                <strong>
                  <span style={{ fontSize: 16 }}>University</span>
                </strong>
                <strong>
                  <span style={{ fontSize: 16 }}>&nbsp;</span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>Pochta manzili:: /&nbsp;</span>
                </strong>
                <strong>
                  <span style={{ fontSize: 16 }}>Legal</span>
                </strong>
                <strong>
                  <span style={{ fontSize: 16 }}>&nbsp;</span>
                </strong>
                <strong>
                  <span style={{ fontSize: 16 }}>Address</span>
                </strong>
                <strong>
                  <span style={{ fontSize: 16 }}>:</span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 16 }}>
                200800, Buxoro viloyati, Qorako’l tumani, Xo’jalar MFY, Buxoro ko’chasi 60-uy
                </span>
                <span style={{ fontSize: 16 }}>Uzbekistan</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>&nbsp;</span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>To’lov rekvizitlari::</span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>/</span>
                </strong>
                <strong>
                  <span style={{ fontSize: 16 }}>PaymentDetails</span>
                </strong>
                <strong>
                  <span style={{ fontSize: 16 }}>:</span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 16 }}>
                Р/с 20208000205598610001 
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 16 }}>В.с 2020884050559861 0001</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 16 }}>
                  &nbsp; Aloqabank ATB Buxoro filiali
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 16 }}>MFO 00401</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 16 }}>INN 310106691 </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 16 }}>IFUT 32407571 </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
            </td>
            <td
              style={{
                width: "162.95pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "1pt solid windowtext",
                borderRight: "1pt solid windowtext",
                padding: "0cm 5.4pt",
                height: "76.1pt",
                verticalAlign: "top",
              }}
            >
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 12 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  marginRight: "-.35pt",
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 12 }}>F.I.Sh/ Name</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  marginRight: "-.35pt",
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 12 }}></span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  marginRight: "-.35pt",
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 12 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  marginRight: "-.35pt",
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 12 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  marginRight: "-.35pt",
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 12 }}>Passport seriyasi</span>
                <span style={{ fontSize: 12 }}>:/ Passport details</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  marginRight: "-.35pt",
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 12 }}></span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  marginRight: "-.35pt",
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 12 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  marginRight: "-.35pt",
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 12 }}>Qachon, kim tomonidan berilgan:/</span>
                <span style={{ fontSize: 12 }}>Issuedby</span>
                <span style={{ fontSize: 12 }}>:</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  marginRight: "-.35pt",
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 12 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  marginRight: "-.35pt",
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 12 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  marginRight: "-.35pt",
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 12 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  marginRight: "-.35pt",
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 12 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  marginRight: "-.35pt",
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 12 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 12 }}>Amal qilish muddati :/&nbsp;</span>
                <span style={{ fontSize: 12 }}>Valid</span>
                <span style={{ fontSize: 12 }}>&nbsp;</span>
                <span style={{ fontSize: 12 }}>until</span>
                <span style={{ fontSize: 12 }}>:&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 12 }}>
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 12 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  marginRight: "-3.75pt",
                }}
              >
                <span style={{ fontSize: 12 }}>Manzili</span>
                <span style={{ fontSize: 12 }}>:/ Address:</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  marginRight: "3.6pt",
                }}
              >
                <span style={{ fontSize: 12 }}></span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 12 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 12 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 12 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "justify",
                }}
              >
                <span style={{ fontSize: 12 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 12 }}>Tel:</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 12 }}></span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p
        style={{
          margin: "0cm",
          fontSize: 20,
          fontFamily: '"Times New Roman",serif',
          textAlign: "center",
        }}
      >
        <strong>
          <span style={{ fontSize: 15 }}>&nbsp;TOMONLARNING IMZOLARI</span>
        </strong>
        <strong>
          <span style={{ fontSize: 15 }}>&nbsp;/ SIGNATURES</span>
        </strong>
      </p>

      <p
        style={{
          margin: "0cm",
          fontSize: 20,
          fontFamily: '"Times New Roman",serif',
        }}
      >
        <span style={{ fontSize: 1 }}>&nbsp;</span>
      </p>
      <table
        style={{
          width: "5.1e+2pt",
          border: "none",
          marginLeft: "17.25pt",
          borderCollapse: "collapse",
        }}
      >
        <tbody>
          <tr>
            <td
              style={{
                width: "172.2pt",
                border: "1pt solid windowtext",
                padding: "0cm 5.4pt",
                height: "11.3pt",
                verticalAlign: "top",
              }}
            >
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>Universitet</span>
                </strong>
                <strong>
                  <span style={{ fontSize: 16 }}>&nbsp;/ University</span>
                </strong>
              </p>
            </td>
            <td
              style={{
                width: "163.8pt",
                borderTop: "1pt solid windowtext",
                borderRight: "1pt solid windowtext",
                borderBottom: "1pt solid windowtext",
                borderImage: "initial",
                borderLeft: "none",
                padding: "0cm 5.4pt",
                height: "11.3pt",
                verticalAlign: "top",
              }}
            >
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>TALABA</span>
                </strong>
                <strong>
                  <span style={{ fontSize: 16 }}>&nbsp;/ STUDENT</span>
                </strong>
              </p>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "172.2pt",
                borderRight: "1pt solid windowtext",
                borderBottom: "1pt solid windowtext",
                borderLeft: "1pt solid windowtext",
                borderImage: "initial",
                borderTop: "none",
                padding: "0cm 5.4pt",
                height: "53.1pt",
                verticalAlign: "top",
              }}
            >
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>Direktor /&nbsp;</span>
                </strong>
                <strong>
                  <span style={{ fontSize: 16 }}>Director</span>
                </strong>
                <strong>
                  <span style={{ fontSize: 16 }}>&nbsp;</span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 16 }}>Chandrashekar Vishesh  /&nbsp;</span>
                <span style={{ fontSize: 16 }}>Chandrashekar</span>
                <span style={{ fontSize: 16 }}>&nbsp;&nbsp;</span>
                <span style={{ fontSize: 16 }}>Vishesh</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>&nbsp;</span>
                </strong>
              </p>
              <img src={sign} alt="sign" width="200" />

              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 9 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 16 }}>Imzo</span>
                <span style={{ fontSize: 16 }}>&nbsp;/Signature&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <img src={stamp} alt="stamp" width="150" height="150" />

              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 16 }}>М.O’./Stamp</span>
              </p>
            </td>
            <td
              style={{
                width: "163.8pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "1pt solid windowtext",
                borderRight: "1pt solid windowtext",
                padding: "0cm 5.4pt",
                height: "53.1pt",
                verticalAlign: "top",
              }}
            >
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 16 }}>
                  ______________________________
                </span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                  textAlign: "center",
                }}
              >
                <span style={{ fontSize: 9 }}>F.I.Sh./ Name</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>__________________</span>
                </strong>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 16 }}>Imzo</span>
                <span style={{ fontSize: 16 }}>/Signature&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 16 }}>_________________________</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <span style={{ fontSize: 16 }}>М.O’./Stamp</span>
              </p>
              <p
                style={{
                  margin: "0cm",
                  fontSize: 20,
                  fontFamily: '"Times New Roman",serif',
                }}
              >
                <strong>
                  <span style={{ fontSize: 16 }}>&nbsp;</span>
                </strong>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p
        style={{
          margin: "0cm",
          fontSize: 20,
          fontFamily: '"Times New Roman",serif',
        }}
      >
        <span style={{ fontSize: 1 }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: 20,
          fontFamily: '"Times New Roman",serif',
        }}
      >
        <span style={{ fontSize: 1 }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: 20,
          fontFamily: '"Times New Roman",serif',
        }}
      >
        &nbsp;
      </p>
      <div
        style={{
          margin: "0cm",
          fontSize: 20,
          fontFamily: '"Times New Roman",serif',
        }}
      >
        <br />
        <div
          style={{
            margin: "0cm",
            fontSize: 20,
            fontFamily: '"Times New Roman",serif',
          }}
          id="ftn4"
        >
          <p
            style={{
              margin: "0cm",
              fontSize: 16,
              fontFamily: '"Times New Roman",serif',
            }}
          >
            <span style={{ textDecoration: "line-through", color: "red" }}>
              <del cite="mailto:C%20Vishesh" dateTime="2023-09-02T16:16">
                &nbsp;
              </del>
            </span>
          </p>
        </div>
      </div>
    </>
  );
};
export default QualifiedContractNCT;
