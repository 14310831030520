import Axios from "axios";

// Local
// const apiUrl = `http://192.168.0.182:8081`;

// Staging backend
// const apiUrl = `https://www.stageapi-acharyainstitutes.in/AcharyaInstituteUZB`;

// Live
const apiUrl = `https://api-prod-acharyainstitutes.in/AcharyaInstituteUZB`;

const token = JSON.parse(localStorage.getItem("AcharyaErpUser"))?.token;

const axios = Axios.create({
  baseURL: apiUrl,
});

axios.interceptors.request.use((config) => {
  config.headers.authorization = `Bearer ${token}`;
  return config;
});

export default axios;
