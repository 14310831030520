import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
  Paper,
  CardHeader,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  styled,
  tableCellClasses,
  TableBody,
  Divider,
  Button,
} from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import EmailIcon from "@mui/icons-material/Email";
import moment from "moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.auzColor.main,
    color: theme.palette.headerWhite.main,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

function DueFollowUpNote({ followUpData }) {
  return (
    <>
      {/* <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ textAlign: "center" }}>
                Follow Up Date
              </StyledTableCell>
              <StyledTableCell sx={{ textAlign: "center" }}>
                Due Note
              </StyledTableCell>
              <StyledTableCell sx={{ textAlign: "center" }}>
                Fee Due
              </StyledTableCell>
              <StyledTableCell sx={{ textAlign: "center" }}>
                Note By
              </StyledTableCell>
              <StyledTableCell sx={{ textAlign: "center" }}>
                Created Date
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {followUpData?.map((obj, i) => {
              return (
                <StyledTableRow key={i}>
                  <TableCell sx={{ textAlign: "center" }}>
                    {moment(obj.follow_up_date).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {obj.follow_up_remarks}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {obj.due_amount}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {obj.created_username}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {moment(obj.created_date).format("DD-MM-YYYY")}
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer> */}

      <Timeline>
        {followUpData.length > 0 ? (
          followUpData.map((obj, i) => {
            return (
              <TimelineItem key={i}>
                <TimelineOppositeContent
                  sx={{ m: "auto 0" }}
                  variant="body2"
                  color="text.secondary"
                >
                  <Typography>
                    {obj.created_date
                      ? obj.created_date
                          .slice(0, 10)
                          .split("-")
                          .reverse()
                          .join("-")
                      : ""}
                  </Typography>
                  <Typography>
                    Note By - {obj.created_username ? obj.created_username : ""}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot style={{ background: "#623f8f" }}>
                    <EmailIcon />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Typography>
                    Fee Due - {obj.due_amount ? obj.due_amount : ""}
                  </Typography>
                  <Typography>
                    Follow Up Note -{" "}
                    {obj.follow_up_remarks ? obj.follow_up_remarks : ""}
                  </Typography>
                  <Typography>
                    Follow Up date - &nbsp;
                    {obj.follow_up_date
                      ? obj.follow_up_date
                          .slice(0, 10)
                          .split("-")
                          .reverse()
                          .join("-")
                      : "Not found"}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            );
          })
        ) : (
          <></>
        )}
      </Timeline>
    </>
  );
}

export default DueFollowUpNote;
