import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Grid,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Paper,
  tableCellClasses,
  styled,
  TableCell,
  TableBody,
} from "@mui/material";
import CustomTextField from "../../../components/Inputs/CustomTextField";
import axios from "../../../services/Api";
import CustomAutocomplete from "../../../components/Inputs/CustomAutocomplete";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useAlert from "../../../hooks/useAlert";
import useBreadcrumbs from "../../../hooks/useBreadcrumbs";
import FormWrapper from "../../../components/FormWrapper";
import dayjs from "dayjs";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.auzColor.main,
    color: theme.palette.headerWhite.main,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function JournalVoucherEdit() {
  const { id } = useParams();
  const loginUserId = JSON.parse(
    localStorage.getItem("AcharyaErpUser")
  )?.userId;
  const [verifyData, setVerifyData] = useState([]);
  const [values, setValues] = useState([
    { vendor_id: null, ledger_id: null, debit: "", credit: "" },
    { vendor_id: null, ledger_id: null, debit: "", credit: "" },
    { vendor_id: null, ledger_id: null, debit: "", credit: "" },
    { vendor_id: null, ledger_id: null, debit: "", credit: "" },
    { vendor_id: null, ledger_id: null, debit: "", credit: "" },
  ]);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [bankOptions, setBankOptions] = useState([]);
  const [deptData, setDeptData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [expData, setExpData] = useState([]);
  const [selectedFile, setSelectedFile] = useState("");

  const getTableData = () => {
    return values.map((item, index) => ({
      vendor_id: verifyData[index]?.vendor_id || null,
      ledger_id: verifyData[index]?.ledger_id || null,
      debit: verifyData[index]?.debit || null,
      credit: verifyData[index]?.credit || null,
    }));
  };

  useEffect(() => {
    if (verifyData?.length > 0) {
      const tableData = values.map((item, index) => ({
        vendor_id: verifyData[index]?.vendor_id || null,
        ledger_id: verifyData[index]?.ledger_id || null,
        debit: verifyData[index]?.debit || null,
        credit: verifyData[index]?.credit || null,
      }));
      setValues(tableData);
    }
  }, [verifyData]);
  const { pathname } = useLocation();
  const setCrumbs = useBreadcrumbs();
  const { setAlertMessage, setAlertOpen } = useAlert();
  const navigate = useNavigate();
  useEffect(() => {
    setCrumbs([
      {
        name: "Journal Index",
        link: "/Draftvouchers/journal",
      },
      { name: "Journal Voucher" },
    ]);
    getVoucherHeadByOutFlow();
    getVendorData();
  }, []);

  const getVoucherHeadByOutFlow = async () => {
    await axios
      .get(`/api/finance/getAllJournalType`)
      .then((res) => {
        setExpData(
          res.data.data.map((obj) => ({
            value: obj?.voucher_head_id,
            label: obj?.voucher_head,
          }))
        );
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getBankData();
    getDept();
  }, [pathname]);

  const getSchoolOptions = useCallback(async () => {
    if (data?.dept_id)
      await axios(`/api/allUnassignedSchoolToDepartment/${data?.dept_id}`)
        .then((res) => {
          setSchoolOptions(
            res.data.data.map((obj) => ({
              value: obj.school_id,
              label: obj.school_name_short,
            }))
          );
        })
        .catch((err) => console.error(err));
  }, [data?.dept_id]);

  const checks = {
    document: [
      selectedFile !== "",
      selectedFile && selectedFile.name.endsWith(".pdf"),
      selectedFile && selectedFile.size < 2000000,
    ],
    pay_to: [data?.pay_to !== ""],
  };

  useEffect(() => {
    getSchoolOptions();
  }, [getSchoolOptions]);

  const getBankData = async () => {
    await axios(`/api/finance/Bank`)
      .then((res) => {
        setBankOptions(
          res.data.data.map((obj) => ({
            value: obj.bank_id,
            label: obj.bank_name,
          }))
        );
      })
      .catch((err) => console.error(err));
  };

  const getVendorData = async () => {
    await axios
      .get(`/api/inventory/vendorActiveDetails`)
      .then((res) => {
        setVendorData(
          res.data.data.map((obj) => ({
            value: obj?.vendor_id,
            label: obj?.vendor_name,
          }))
        );
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getDept();
  }, [pathname]);

  const getDept = async () => {
    await axios
      .get(`/api/dept`)
      .then((res) => {
        setDeptData(
          res.data.data.map((obj) => ({
            value: obj.dept_id,
            label: obj.dept_name,
          }))
        );
      })
      .catch((error) => console.error(error));
  };

  const handleChange = (e, index) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const errorMessages = {
    document: [
      "This field is required",
      "Please upload a PDF",
      "Maximum size 2 MB",
    ],
    pay_to: ["this field is required"],
  };

  useEffect(() => {
    const debitTotal = values?.reduce((total, item) => {
      const debitValue = parseFloat(item?.debit);
      if (!isNaN(debitValue)) {
        total += debitValue;
      }
      return total;
    }, 0);
    const creditTotal = values?.reduce((total, item) => {
      const debitValue = parseFloat(item?.credit);
      if (!isNaN(debitValue)) {
        total += debitValue;
      }
      return total;
    }, 0);
    setData((prev) => ({
      ...prev,
      debit_total: debitTotal,
      credit_total: creditTotal,
    }));
  }, [values]);

  const handleChangeAdvance = (name, newValue) => {
    setData((prev) => ({
      ...prev,
      [name]: newValue,
    }));
  };
  const handleChangeValues = (index) => (name, value) => {
    const newValues = [...values];
    newValues[index] = { ...newValues[index], [name]: value };
    setValues(newValues);
  };
  useEffect(() => {
    getUpdateData();
  }, []);

  const getUpdateData = async () => {
    setLoading(true);
    await axios
      .get(`/api/finance/getDraftJournalVoucherData/${id}`)
      .then((res) => {
        setVerifyData(res.data.data);
        setData(res.data.data[0]);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  };

  const formattedPayload = () => {
    const journalVoucherData = values.map((item, index) => {
      if (item.debit || item.credit) {
        return {
          ...data,
          credit: item?.credit,
          debit: item?.debit,
          vendor_id: item?.vendor_id,
          ledger_id: item?.ledger_id,
          draft_journal_voucher_id: verifyData[index]?.id || "",
          id: verifyData[index]?.id || "",
        };
      }
    });
    const filteredJournalData = journalVoucherData.filter(
      (item) => item !== undefined
    );
    return filteredJournalData;
  };

  const addJournal = async () => {
    const JournalData = formattedPayload().map((item, index) => {
      return {
        debit: item?.debit ? item?.debit?.toString() : null,
        credit: item?.credit ? item?.credit?.toString() : null,
        vendor_id: item?.vendor_id,
        ledger_id: item?.ledger_id || 1,
        voucher_head_id: item.voucher_head_id || 1,
      };
    });
    const newPayload = { ...data, vendor_id: JournalData };
    delete newPayload.verified_status;
    delete newPayload.school_name_short;
    delete newPayload.vendor_email;
    delete newPayload.bank_short_name;
    delete newPayload.ledger_id;
    delete newPayload.id;
    delete newPayload.credit;
    delete newPayload.vendor_name;
    delete newPayload.vendor_bank_name;
    delete newPayload.modified_date;
    delete newPayload.created_by;
    delete newPayload.verified_date;
    delete newPayload.journal_voucher_id;
    delete newPayload.dept_name_short;
    delete newPayload.verifier_id;
    delete newPayload.vendor_tin_no;
    delete newPayload.modified_username;
    delete newPayload.financial_year;
    delete newPayload.bank_name;
    delete newPayload.debit;
    delete newPayload.school_name;
    delete newPayload.dept_name;
    delete newPayload.jv_journal_voucher_number;
    delete newPayload.created_username;
    delete newPayload.vendor_contact_no;
    delete newPayload.modified_by;
    delete newPayload.created_date;
    delete newPayload.voucher_head_id;
    newPayload.draft_journal_voucher_id = data?.journal_voucher_number;
    newPayload.purchase_order_id = "";
    newPayload.debit_total = data?.debit_total?.toString();
    newPayload.credit_total = data?.credit_total?.toString();
    newPayload.approved_status = 1;
    newPayload.approved_date = dayjs();
    newPayload.approver_id = loginUserId;

    await axios
      .post("api/finance/journalVoucher", newPayload)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          navigate("/JournalIndex");
        } else {
          setAlertMessage({
            severity: "error",
            message: res.data.message,
          });
        }
        setAlertOpen(true);
      })
      .catch((error) => {
        setAlertMessage({
          severity: "error",
          message: error.response ? error.response.data.message : "Error",
        });
        setAlertOpen(true);
        navigate("/JournalIndex");
      });
  };
  const handleCreate = async (e) => {
    if (data?.credit_total !== data?.debit_total) {
      setAlertMessage({
        severity: "error",
        message: "Credit and debit total should be equal",
      });
      setAlertOpen(true);
    } else if (data.pay_to === "") {
      setAlertMessage({
        severity: "error",
        message: "pay_to field required",
      });
      setAlertOpen(true);
    } else {
      setLoading(true);
      const updateVerifyData = formattedPayload().map((item, index) => {
        delete item.created_date;
        delete item.modified_date;
        delete item.created_by;
        delete item.modified_by;
        delete item.created_username;
        delete item.modified_username;
        return {
          ...item,
          vendor_active: 1,
          verified_status: 1,
          approved_status: 1,
          verified_date: dayjs(),
          approved_date: dayjs(),
          verifier_id: loginUserId,
          approver_id: loginUserId,
          payment_mode: 1,
        };
      });
      const voucherID = verifyData.map((item) => item.id).join(",");
      await axios
        .put(
          `api/finance/updateDraftJournalVoucher/${voucherID}`,
          updateVerifyData
        )
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            setData(res.data);
            setAlertMessage({
              severity: "success",
              message: "Draft Journal Voucher verify Succesfully",
            });
            // getData()
          } else {
            setAlertMessage({
              severity: "error",
              message: res.data.message,
            });
          }
          setAlertOpen(true);
        })
        .catch((error) => {
          setLoading(false);
          setAlertMessage({
            severity: "error",
            message: error.response ? error.response.data.message : "Error",
          });
          setAlertOpen(true);
        });
      addJournal();
    }
  };

  return (
    <Box component="form" overflow="hidden" p={1}>
      {data && !loading ? (
        <>
          <FormWrapper>
            <Grid
              container
              justifyContent="flex-start"
              rowSpacing={1}
              columnSpacing={{ xs: 2, md: 4 }}
            >
              <Grid item xs={12} md={4}>
                <CustomTextField
                  name="pay_to"
                  label="Pay To"
                  value={data?.pay_to}
                  handleChange={handleChange}
                  fullWidth
                  required
                  checks={checks.pay_to}
                  defaultValue={data?.pay_to}
                  errors={errorMessages.pay_to}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomTextField
                  name="contract_number"
                  label="Contract Number"
                  value={data?.contract_number}
                  handleChange={handleChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <CustomAutocomplete
                  name="dept_id"
                  label="Dept"
                  value={data?.dept_id}
                  handleChangeAdvance={handleChangeAdvance}
                  options={deptData}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              rowSpacing={2}
            >
              <Grid item xs={12} md={12} mt={2}>
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          sx={{ width: 100, textAlign: "center" }}
                        >
                          Heads
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ width: 100, textAlign: "center" }}
                        >
                          Ledger
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ width: 100, textAlign: "center" }}
                        >
                          Debit
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ width: 100, textAlign: "center" }}
                        >
                          Credit
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {[0, 1, 2, 3, 4].map((i) => {
                        const isVendorSelected = values[i]?.vendor_id === 1;
                        const isDebitEntered = !!values[i]?.debit;
                        const isCreditEntered = !!values[i]?.credit;
                        const isInputEnabled = true;
                        return (
                          <TableRow key={i}>
                            <StyledTableCell>
                              <CustomAutocomplete
                                name={"vendor_id"}
                                label="Group"
                                value={values[i]?.vendor_id}
                                handleChangeAdvance={handleChangeValues(i)}
                                options={[
                                  { label: "Expenditure", value: 2 },
                                  { label: "Vendor", value: 1 },
                                ]}
                                disabled={!isInputEnabled}
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              <CustomAutocomplete
                                name={"ledger_id"}
                                label="Ledger"
                                value={values[i]?.ledger_id}
                                handleChangeAdvance={handleChangeValues(i)}
                                options={
                                  isVendorSelected ? vendorData : expData
                                }
                                disabled={!isInputEnabled}
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              <CustomTextField
                                name="debit"
                                label=""
                                min="0"
                                type="number"
                                value={values[i]?.debit}
                                disabled={!isInputEnabled || isCreditEntered}
                                handleChange={(e) =>
                                  handleChangeValues(i)("debit", e.target.value)
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              <CustomTextField
                                name="credit"
                                label=""
                                value={values[i]?.credit}
                                disabled={!isInputEnabled || isDebitEntered}
                                handleChange={(e) =>
                                  handleChangeValues(i)(
                                    "credit",
                                    e.target.value
                                  )
                                }
                              />
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                      <TableRow>
                        <StyledTableCell sx={{ textAlign: "left" }} colSpan={2}>
                          Total
                        </StyledTableCell>
                        <StyledTableCell>
                          <CustomTextField
                            name="debit_total"
                            type="number"
                            disabled
                            value={data?.debit_total}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <CustomTextField
                            name="credit_total"
                            type="number"
                            disabled
                            value={data?.credit_total}
                          />
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Grid
              container
              rowSpacing={2}
              justifyContent="flex-start"
              alignItems="center"
              columnSpacing={4}
            >
              <Grid item xs={12} md={4} mt={2}>
                <CustomTextField
                  multiline
                  rows={2}
                  label="Remarks"
                  value={data.remarks}
                  name="remarks"
                  handleChange={handleChange}
                  inputProps={{
                    maxLength: 300,
                  }}
                />
              </Grid>
              <Grid item xs={12} align="right">
                <Grid></Grid>
                <Button variant="contained" onClick={handleCreate}>
                  Verify
                </Button>
              </Grid>
            </Grid>
          </FormWrapper>{" "}
        </>
      ) : (
        ""
      )}
    </Box>
  );
}

export default JournalVoucherEdit;
